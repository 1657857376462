import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";
import loader from "../../assets/animations/loader.json";

const Loader = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loader,
      }}
      height={isBigScreen ? 90 : 50}
      width={isBigScreen ? 90 : 50}
      style={{
        margin: isBigScreen ? "-37px -33px -33px 0" : "-18px -18px -14px 0",
      }}
      isClickToPauseDisabled={true}
    />
  );
};

export default Loader;
