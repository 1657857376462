import { BsStar } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import Table from "../../components/Table";
import { colors } from "../../utils/colors";
import LineGraph from "../../components/WorkSpace/LineGraph";
import PieChart from "../../components/WorkSpace/PieGraph";
import {
  HomeCardContainer,
  HomeCardContainerWrapper,
  HomeCardWrap,
  HomeCartIconWrapper,
} from "./styles";

const Home = () => {
  return (
    <>
      <HomeCardContainerWrapper>
        <HomeCardContainer style={{ backgroundColor: "transparent" }}>
          <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
            <HomeCardWrap props={{ width: 49, widthMobile: 49 }}>
              <HomeCartIconWrapper>
                <BsStar color={colors.primary} size={20} />
              </HomeCartIconWrapper>
              <span style={{ fontSize: 20, fontWeight: "600", marginTop: 10 }}>
                9.0
              </span>
              <span style={{ fontSize: 12, fontWeight: "500", color: "gray" }}>
                Usage NPS
              </span>
            </HomeCardWrap>
            <HomeCardWrap props={{ width: 49, widthMobile: 49 }}>
              <HomeCartIconWrapper>
                <IoTimeOutline color={colors.primary} size={20} />
              </HomeCartIconWrapper>
              <span style={{ fontSize: 20, fontWeight: "600", marginTop: 10 }}>
                1h 23m 7s
              </span>
              <span style={{ fontSize: 12, fontWeight: "500", color: "gray" }}>
                Average Time Spent
              </span>
            </HomeCardWrap>
          </div>
          <HomeCardWrap
            props={{ width: 100, widthMobile: 100 }}
            style={{ padding: 0 }}
          >
            <PieChart />
          </HomeCardWrap>
          {/* <div style={{}}>Great</div> */}
        </HomeCardContainer>
        <HomeCardContainer>
          <LineGraph />
        </HomeCardContainer>
        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{ marginTop: 20 }}
        >
          <Table
            title="History"
            header={{
              name: "History",
              total: 3,
              labels: ["Name", "Services", "Usage", "Date"],
            }}
            rows={[
              [
                "Denis Buwelo",
                "Delivery",
                "1h",
                new Date("2-03-2022").toDateString(),
              ],
              [
                "Isaac Kojo Bekoe",
                "Analytics",
                "1h 34m",
                new Date("3-19-2022").toDateString(),
              ],
              [
                "Isaac Kojo Bekoe",
                "E-Payment",
                "2m",
                new Date("3-23-2022").toDateString(),
              ],
              [
                "Chirstabel Asantewaa",
                "Eatery management",
                "5h 12m",
                new Date("4-01-2022").toDateString(),
              ],
            ]}
          />
        </HomeCardWrap>
        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 12,
          }}
        >
          <span style={{ fontWeight: 500 }}>
            CMS © {new Date().getFullYear()} All rights reserved.
          </span>
          <span>{new Date().toUTCString()}</span>
        </HomeCardWrap>
      </HomeCardContainerWrapper>
    </>
  );
};

export default Home;
