import Navigations from "../../components/Global/Navigations";
import Footer from "../../components/Global/Footer";
import NotFoundView from "../../components/NotFoundView";
import { GlobalContainer } from "../../styles/Global/container.style";

const Notfound = () => {

  return (
    <>
      <Navigations />
      <GlobalContainer>
        <NotFoundView />
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default Notfound;
