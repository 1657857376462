import CryptoJS from "crypto-js";
import { isJSON } from "./validators.service";

const key = process.env.REACT_APP_USER_DATA_KEY?.toString() || "";

function encrypt(word: object): string {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}

function decrypt(word: string): string | object {
  let decData = CryptoJS.enc.Base64.parse(word.replaceAll('"', "")).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return isJSON(bytes) ? JSON.parse(bytes) : bytes
}

export { encrypt, decrypt };
