import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { colors } from "../../utils/colors";
import { BodyText, Header1, Header2 } from "../../styles/Global/text";
import { hide } from "../../redux/modalReducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { ModalContainer, ModalWrapper } from "../../styles/Modal.style";
import Lottie from "react-lottie";
import loader from "../../assets/animations/loader.json";
import { useMediaQuery } from "react-responsive";
import { GlobalButton } from "../../styles/Global/button";

const Modal = () => {
  const [wrapper, setWrapper] = useState(false);
  const [container, setContainer] = useState(false);
  const visible = useSelector((state: RootState) => state.modal.visible);
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const { header, message, state, preventClose, loading } = useSelector(
    (state: RootState) => state.modal.dialog
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!visible) {
      setWrapper(visible);
      setTimeout(() => {
        setWrapper(visible);
        setContainer(visible);
      }, 100);
    } else {
      setContainer(visible);
      setTimeout(() => {
        setContainer(visible);
        setWrapper(visible);
      }, 100);
    }
  }, [visible]);

  return (
    <ModalContainer
      props={{ visible: container }}
      onClick={() => (preventClose ? null : dispatch(hide()))}
    >
      <ModalWrapper
        props={{ visible: container, wrapper }}
        onClick={(e) => e.stopPropagation()}
      >
        <Header2
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {header} <div style={{ flex: 1 }} />
          {loading ? (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loader,
              }}
              height={90}
              width={90}
              style={{
                margin: isBigScreen
                  ? "-37px -33px -33px 0"
                  : "-18px -18px -14px 0",
              }}
              isClickToPauseDisabled={true}
            />
          ) : null}
          {preventClose ? null : (
            <>
              <IoIosCloseCircleOutline
                color={colors.red_dark}
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => dispatch(hide())}
              />
            </>
          )}
        </Header2>
        <BodyText style={{ marginBottom: 30 }}>{message}</BodyText>
        {loading ? null : (
          <GlobalButton
            props={{
              background:
                state === 2
                  ? colors.secondary
                  : state === 0
                  ? colors.red_dark
                  : "green",
              color: "white",
              full: false,
              border:
                state === 2
                  ? colors.secondary
                  : state === 0
                  ? colors.red_dark
                  : "green",
              hover: colors.accent_light,
              hoverColor: colors.secondary,
              hoverBorder: colors.accent,
              square: true,
            }}
            title="Okay"
            onClick={() => dispatch(hide())}
          >
            Okay
          </GlobalButton>
        )}
      </ModalWrapper>
    </ModalContainer>
  );
};

export default Modal;
