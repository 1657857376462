import styled from "styled-components";
import { transition } from "..";

interface IGlobalButton {
    props?: {
      background: string;
      border?: string;
      color: string;
      full: Boolean;
      hover?: string;
      hoverColor?: string;
      hoverBorder?: string;
      square?: Boolean;
    };
  }

export const GlobalInputText = styled.input<IGlobalButton>`
  border: none;
  border: 1px solid
    ${({ props }) => (props?.background ? props?.border : props?.background)};
  background-color: ${(IGlobalButton) => IGlobalButton.props?.background};
  color: ${({ props }) => props?.color};
  padding: 10px 15px;
  width: ${({ props }) => (props?.full ? "100%" : "max-content")};
  font-size: medium;
  height: max-content;
  border-radius: ${({ props }) => (props?.square ? "5px" : "30px")};
  transition: 0.8s all ease-in-out;
  margin-bottom: 15px;
  margin-top: 8px;
  cursor: pointer;
  outline: none;

  :hover {
    border-color: ${({ props }) => props?.color};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const GlobalInputTextRaw = styled.input`
  width: 100%;
  outline: none;
  border: none;
  font-size: medium;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const GlobalInputTextBottom = styled.input<IGlobalButton>`
  border: none;
  border-bottom: 1px solid
    ${({ props }) => (props?.background ? props?.border : props?.background)};
  background-color: ${(IGlobalButton) => IGlobalButton.props?.background};
  color: ${({ props }) => props?.color};
  padding: 10px 8px;
  width: ${({ props }) => (props?.full ? "100%" : "max-content")};
  font-size: medium;
  height: max-content;
  transition: 0.8s all ease-in-out;
  margin-bottom: 15px;
  margin-top: 8px;
  cursor: pointer;
  outline: none;
  border-radius: 5px;

  :hover {
    border-color: ${({ props }) => props?.color};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const GlobalInputTextAreaBottom = styled.textarea<IGlobalButton>`
  border: none;
  border-bottom: 1px solid
    ${({ props }) => (props?.background ? props?.border : props?.background)};
  background-color: ${(IGlobalButton) => IGlobalButton.props?.background};
  color: ${({ props }) => props?.color};
  padding: 10px 8px;
  width: ${({ props }) => (props?.full ? "100%" : "max-content")};
  font-size: medium;
  height: max-content;
  /* transition: 0.8s all ease-in-out; */
  margin-bottom: 15px;
  margin-top: 8px;
  cursor: pointer;
  outline: none;
  resize: vertical;
  border-radius: 5px;
  font-family: "Montserrat";


  :hover {
    border-color: ${({ props }) => props?.color};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const GlobalInputSelectBottom = styled.select<IGlobalButton>`
  border: none;
  border-bottom: 1px solid
    ${({ props }) => (props?.background ? props?.border : props?.background)};
  background-color: ${(IGlobalButton) => IGlobalButton.props?.background};
  color: ${({ props }) => props?.color};
  padding: 10px 8px;
  width: ${({ props }) => (props?.full ? "100%" : "max-content")};
  font-size: medium;
  height: max-content;
  transition: 0.8s all ease-in-out;
  margin-bottom: 15px;
  margin-top: 8px;
  cursor: pointer;
  outline: none;
  border-radius: 5px;

  :hover {
    border-color: ${({ props }) => props?.color};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const SearchContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  width: 500px;

  @media (max-width: 768px) {
    padding: 5px;
    width: 300px;
  }
`;

export interface ISearchWrapper {
    props?: {
      square?: Boolean;
    };
  }

export const SearchWrapper = styled.span<ISearchWrapper>`
  width: max-content;
  padding: 7px 10px;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: ${({ props }) => (props?.square ? "7px" : "30px")};
  display: flex;
  transition: ${transition};

  @media (max-width: 768px) {
    flex: 1
  }
`;

export const PlainInput = styled.input`
  border: none;
  outline: none;
  font-size: medium;
  width: 100%;
`;