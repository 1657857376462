import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";
import { transition } from "../../styles";

export const TableContainer = styled.table`
  min-width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }

  tbody tr td {
    padding: 10px 0;
    position: "relative";
    overflow-x: scroll;
  }
`;
export const TableTH = styled.thead`
  font-size: 14px;
  text-align: left;
  padding: 5px 0;

  th {
    font-weight: 600;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }
`;



export const ArrowRight = styled(FaChevronRight)`
  margin: 0px 10px 0px 5px;
  transition: ${transition};
  background-color: transparent;

  :hover {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ArrowWrapper = styled.span`
  padding: 7px;
  transition: ${transition};
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    background-color: #f1f3f4;
  }
`;
