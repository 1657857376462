import styled from "styled-components";

interface IGlobalButton {
  props?: {
    background: string;
    border?: string;
    color: string;
    full: Boolean;
    hover?: string;
    hoverColor?: string;
    hoverBorder?: string;
    square?: Boolean;
  };
}

export const GlobalButton = styled.button<IGlobalButton>`
  border: none;
  border: 2px solid
    ${({ props }) => (props?.border ? props?.border : props?.background)};
  background-color: ${(IGlobalButton) => IGlobalButton.props?.background};
  color: ${({ props }) => props?.color};
  padding: 10px 20px;
  width: ${({ props }) => (props?.full ? "100%" : "max-content")};
  font-size: medium;
  height: max-content;
  border-radius: ${({ props }) => (props?.square ? "5px" : "30px")};
  transition: 0.8s all ease-in-out;
  outline: none;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  cursor: pointer;

  :hover {
    color: ${({ props }) =>
      props?.hoverColor ? props?.hoverColor : props?.background};
    background-color: ${({ props }) =>
      props?.hover ? props?.hover : props?.color};
    border-color: ${({ props }) =>
      props?.hoverBorder
        ? props?.hoverBorder
        : props?.border
        ? props?.border
        : props?.background};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 24px;
  }
`;

interface IGlobalButtonHighlight {
  props?: {
    background?: string;
    hover?: string;
    color?: string;
    hoverColor?: string;
  };
}

export const GlobalButtonHighlight = styled.button<IGlobalButtonHighlight>`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.5s all ease-in-out;
  border: none;
  background-color: ${({ props }) => props?.background || "default"};
  color: ${({ props }) => props?.color || "black"};
  white-space: nowrap;

  :hover {
    background-color: ${({ props }) => props?.hover || "rgba(0, 0, 0, 0.05)"};
    color: ${({ props }) => props?.hoverColor || "black"};
  }
`;
