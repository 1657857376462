import React from "react";
import { SummaryCardContainer } from "../../../styles/WorkSpace/Profile/Profile.style";

interface ISummaryCard {
  title: string;
  value: number;
  children: React.ReactNode;
}

const SummaryCard = ({ title, value, children }: ISummaryCard) => {
  return (
    <SummaryCardContainer
    >
      {children}
      <div>
        <span style={{ fontSize: 12}}>{title}</span>
        <h6 style={{ fontSize: 20, margin: 0 }}>{value}</h6>
      </div>
    </SummaryCardContainer>
  );
};

export default SummaryCard;
