import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "../../../utils/colors";
import {
  SideNavButtonsWrapper,
  SideNavButtonWrapper,
  SideNavigationContainer,
  SideNavLogo,
} from "./styles";
import { RiHomeLine } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgMenuGridO, CgWorkAlt } from "react-icons/cg";
import { IoBookmarksOutline } from "react-icons/io5";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Link } from "../../../styles";
import useLogout from "../../../hooks/useLogout";

const SideNavigation = () => {
  const { logout } = useLogout();
  const location = useLocation();
  const [page, setPage] = useState<null | string>(null);

  useEffect(() => {
    try {
      const pages = location.pathname?.split("/");
      const page = !pages[2] ? "" : pages[pages.length - 1];
      // console.log("Url: ", pages);
      setPage(page);
    } catch (e) {
      console.error(e);
    }
  }, [location]);

  const check = (url: string) => {
    return page === url;
  };

  const iconColor = (url: string) => {
    return check(url) ? "white" : colors.primary;
  };

  const isPath = (pathname: string) => {
    const url = location.pathname;
    const second = url?.split("/")[2] || "";
    // console.log(url.split("/"));
    return second === pathname;
  };

  return (
    <SideNavigationContainer title="Home">
      <Link to="/workspace">
        <SideNavLogo src={require("../../../assets/images/logo_dark.png")} />
      </Link>
      <SideNavButtonsWrapper>
        <Link to="/workspace" title="Dashboard">
          <SideNavButtonWrapper props={{ active: isPath("") }}>
            <RiHomeLine size={25} color={iconColor("")} />
          </SideNavButtonWrapper>
        </Link>
        <Link to="/workspace/business" title="Business">
          <SideNavButtonWrapper props={{ active: isPath("business") }}>
            <CgWorkAlt size={25} color={iconColor("business")} />
          </SideNavButtonWrapper>
        </Link>
        <Link to="/workspace/services" title="Services">
          <SideNavButtonWrapper props={{ active: isPath("services") }}>
            <CgMenuGridO size={25} color={iconColor("services")} />
          </SideNavButtonWrapper>
        </Link>
        <Link to="/workspace/saved" title="Saved">
          <SideNavButtonWrapper props={{ active: isPath("saved") }}>
            <IoBookmarksOutline size={25} color={iconColor("saved")} />
          </SideNavButtonWrapper>
        </Link>
      </SideNavButtonsWrapper>
      <SideNavButtonWrapper
        props={{ mobileHide: true }}
        title={"Log out"}
        onClick={() => logout()}
      >
        <AiOutlinePoweroff size={25} color={colors.primary} />
      </SideNavButtonWrapper>
    </SideNavigationContainer>
  );
};

export default SideNavigation;
