import styled from "styled-components";

export const NavigationRow = styled.nav`
  display: flex;
  position: fixed;
  left: 0%;
  right: 0%;
  padding: 1.5% 3%;
  background-color: white;
  align-items: center;
  z-index: 2;
  background-color: rgba(255, 255 255, 0.5);
  box-shadow: 0px 20px 40px rgb(0 48 73 / 5%), 0px 0px 2px rgb(0 0 0 / 13%);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavigationMobile = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0%;
    right: 0%;
    /* background-color: white; */
    z-index: 2;
    /* background-color: rgba(255, 255 255, 0.5); */
    box-shadow: 0px 20px 40px rgb(0 48 73 / 5%), 0px 0px 2px rgb(0 0 0 / 13%);
  }
`;

interface NavigationMenu {
  props?: {
    open: Boolean;
  };
}

export const NavigationColumn = styled.nav<NavigationMenu>`
  display: flex;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  padding: 3%;
  background-color: white;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  background-color: white;
  transition: all 0.5s ease-in-out;
  opacity: ${({ props }) => (props?.open ? "1" : "0")};
  top: ${({ props }) => (props?.open ? "0" : "-100%")};
  overflow-y: scroll;
  padding-bottom: ${({ props }) => (props?.open ? "20px" : "0")};
  &::-webkit-scrollbar {
    --webkit-appearance: none;
  }
`;