import styled from "styled-components";

export const WorkSpaceContainer = styled.div`
  height: 100vh;
  /* background-color: red; */
  display: flex;
  padding: 0;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WorkSpaceArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: 768px) {
    /* width: 100%; */
    height: max-content;
  }
`;

export const WorkSpaceAreaScroll = styled.div`
  position: relative;
  /* flex: 1; */
  height: 100%;
  background-color: #f1f3f4;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  padding: 15px;
  gap: 10px;
`;

export const HomeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  width: 49%;
  overflow: hidden;
  padding: 0px;
  height: max-content;
  background-color: white;
  /* box-shadow: 0 3px 5px rgb(0 ,0, 0,  0.1);
  border: 0.5px solid rgba(0, 0, 0, 0.1); */
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

interface IHomeCardWrap {
  props?: {
    width: number;
    widthMobile: number;
  };
}

export const HomeCardWrap = styled.div<IHomeCardWrap>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: ${({ props }) => props?.width + "%"};
  overflow: hidden;
  padding: 20px;
  height: max-content;
  background-color: white;
  /* box-shadow: 0 3px 5px rgb(0 ,0, 0,  0.1);
  border: 0.5px solid rgba(0, 0, 0, 0.1); */
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: ${({ props }) => props?.widthMobile + "%"};
  }
`;

export const HomeCardContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: space-between;
`;

export const HomeCartIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.1);
`;
