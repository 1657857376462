import { useAuth } from "../context/AuthProvider";
import useMessagePreview from "./useMessagePreview";

interface IRequest {
  baseUrl?: string;
  url: string;
  get?: boolean | "GET" | "POST" | "PUT" | "DELETE";
  body?: object;
  accessToken?: string;
}

const useExternalAPI = () => {
  const { user, setUser, logout } = useAuth();
  const base = "http://localhost:5050/business";
  const credentials = {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa(
      process.env.REACT_APP_BASE_AUTH_USERNAME +
        ":" +
        process.env.REACT_APP_BASE_AUTH_PASSWORD
    )}`,
  };
  const { addNotification } = useMessagePreview();

  const updateToken = (tokens: string) => {
    setUser({ ...user, tokens });
  };

  const refreshToken = async ({
    baseUrl,
    body,
    get,
    url,
  }: IRequest): Promise<any> => {
    const refresh: any = await fetch(baseUrl + "/user/refresh", {
      method: "GET",
      headers: { ...credentials, sessionid: user.tokens?.refreshToken },
    })
      .then((e) => e.json())
      .catch((e) => false);

    if (refresh && refresh?.status && refresh?.data?.tokens) {
      updateToken(refresh.data.tokens);
      const request = await retryPost({
        url,
        body,
        get,
        accessToken: refresh?.data?.tokens?.accessToken,
      });
      return request;
    } else {
      addNotification({ message: "Logged out, session expired!", type: 0 });
      return logout();
    }
  };

  const retryPost = async ({
    baseUrl = base,
    url,
    body,
    get,
    accessToken,
  }: IRequest) => {
    try {
      let config: {
        [key: string]: any;
      } = {};
      config["method"] = get ? "GET" : "POST";
      config["headers"] = credentials;
      if (body) config["body"] = JSON.stringify(body);
      // console.log({ token: user });
      if (user && user.tokens) {
        config["headers"] = {
          ...config["headers"],
          sessionid: accessToken,
        };
      }

      const request = await fetch(baseUrl + "/" + url, config)
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const data = isJson ? await response.json() : null;

          return data;
        })
        .catch((error) => {
          const e = error.message || "An error has occured";
          console.error("There was an error!", error);
          return { status: false, message: e };
        });
      return request;
    } catch (e: any) {
      return { status: false, message: e.message || "An error has occured" };
    }
  };

  const postData = async ({ baseUrl = base, url, body, get }: IRequest) => {
    try {
      let config: {
        [key: string]: any;
      } = {};
      config["method"] = typeof get === "string" ? get : get ? "GET" : "POST";
      config["headers"] = credentials;
      if (body) config["body"] = JSON.stringify(body);
      if (user && user.tokens) {
        config["headers"] = {
          ...config["headers"],
          sessionid: user.tokens?.accessToken,
        };
      }

      const request = await fetch(baseUrl + "/" + url, config)
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const data = isJson ? await response.json() : null;
          if (response.status === 401 && user.tokens) {
            const refresh = await refreshToken({ baseUrl, url, get, body });
            return refresh;
          } else return data;
        })
        .catch((error) => {
          const e = error.message || "An error has occured";
          console.error("There was an error!", error);
          return { status: false, message: e };
        });
      return request;
    } catch (e: any) {
      return { status: false, message: e.message || "An error has occured" };
    }
  };

  return { postData, formBaseUrl:"http://localhost:5050/setup" };
};

export default useExternalAPI;
