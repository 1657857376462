import styled from "styled-components";
import { colors } from "../../utils/colors";

interface IFormResponseContainer {
  props?: {
    status: Number;
    visible: boolean;
  };
}

export const FormResponseContainer = styled.div<IFormResponseContainer>`
  transition: 1s all ease-in-out;
  display: ${({ props }) => (props?.visible ? "flex" : "none")};
  justify-content: space-between;
  width: 100%;
  background-color: ${({ props }) =>
    props?.status === 1
      ? "green"
      : props?.status === 0
      ? colors.red_light
      : "rgba(0, 0, 0, 0.05)"};
  border: ${({ props }) =>
    props?.status === 1
      ? `1px solid green`
      : props?.status === 0
      ? `1px solid ${colors.red_light}`
      : "1px solid rgba(0, 0, 0, 0.5)"};
  align-items: center;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  color: ${({ props }) => (props?.status === 2 ? colors.secondary : "white")};
  margin-bottom: 10px;
`;