import React from "react";
import { colors } from "../../../utils/colors";

interface ISkillContainer {
  icon: any;
  total: number;
  usage: number;
  color: string;
}

const SkillContainer = ({ icon, total, usage, color }: ISkillContainer) => {
  const percentage = (usage / total) * 100

  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        flex: 1,
        width: "100%",
        marginBottom: 15,
        alignItems: "center",
      }}
    >
      <img
        src={icon}
        style={{ height: 35, width: 35, borderRadius: 5 }}
      />
      {/* Han */}
      <span
        style={{
          flex: 1,
          backgroundColor: colors.accent_light,
          width: "100%",
          height: 10,
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: color,
            width: `${percentage}%`,
            height: 10,
          }}
        />
      </span>
      {`${percentage}%`}
    </div>
  );
};

export default SkillContainer;
