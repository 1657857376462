import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const SideNavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 5;
  background-color: white;

  @media (max-width: 768px) {
    border-right: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    order: 1;
  }
`;

export const SideNavLogo = styled.img`
  height: 37px;
  width: 37px;
  border-radius: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

interface ISideButtonWrapper {
  props?: {
    active?: boolean;
    mobileHide?: boolean
  };
}

export const SideNavButtonWrapper = styled.span<ISideButtonWrapper>`
display: flex;
justify-content: center;
  background-color: ${({ props }) =>
    props?.active ? colors.secondary : "transparent"};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px;
  transition: 0.5s all ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${({ props }) =>
      !props?.active ? "rgba(0,0,0,0.2)" : colors.accent};
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: 7px;
  }

  @media (max-width: 768px) {
    display: ${({props})=> (props?.mobileHide ? "none" : "flex")}
  }
`;

export const SideNavButtonWrapperLogout = styled.span`
  background-color: transparent;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px;
  transition: 0.5s all ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${colors.red_dark};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SideNavButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;
