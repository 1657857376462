import React from "react";
import Navigations from "../../components/Global/Navigations";
import Footer from "../../components/Global/Footer";
import {
  FlexRow,
  FlexRowWrapper,
  GlobalContainer,
} from "../../styles/Global/container.style";
import { IndexHeroContainer } from "../../styles/Home.style";
import styled from "styled-components";
import { GlobalButton } from "../../styles/Global/button";
import { colors } from "../../utils/colors";
import { BodyText, Header1, Header3, Header4 } from "../../styles/Global/text";
import { BsPlayFill } from "react-icons/bs";
import { GlobalInputText, PlainInput } from "../../styles/Global/input";
import { AiOutlineSearch } from "react-icons/ai";
import useMessagePreview from "../../hooks/useMessagePreview";

const Delivery = () => {
  const { showModal } = useMessagePreview();

  return (
    <>
      <Navigations />
      <GlobalContainer
        style={{ flexDirection: "column", padding: 0, paddingTop: 100 }}
      >
        <IndexHeroContainer
          props={{ direction: "right", background: "transparent" }}
        >
          <FlexRowWrapper>
            <FlexRow props={{ width: 60 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: 20,
                }}
              >
                <GlobalButton
                  props={{
                    background: "white",
                    color: colors.red_dark,
                    full: false,
                    border: "white",
                    hover: colors.secondary,
                    hoverColor: "white",
                    hoverBorder: colors.secondary,
                  }}
                  style={{
                    fontWeight: "bold",
                    padding: "0 0 0 10px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    fontSize: 13,
                  }}
                  title="About"
                >
                  Bike Delivery{" "}
                  <img
                    src={require("../../assets/images/delivery-bike.png")}
                    style={{ width: 40, height: 40 }}
                    alt="Bike"
                  />
                </GlobalButton>
                <img
                  src={require("../../assets/images/route.png")}
                  style={{ width: 75, height: 75 }}
                  alt="Bike"
                />
              </div>
              <Header1 style={{ marginBottom: 0, marginTop: 50 }}>
                Fastest{" "}
                <span style={{ color: colors.red_light }}>Delivery</span> <br />
                & Easy <span style={{ color: colors.red_light }}>Pickup</span>.
              </Header1>
              <BodyText style={{ color: "grey", marginTop: 30 }}>
                Experience the unbeatable convenience of our delivery service
                with the fastest delivery times and hassle-free pickup options.
                We're your top choice for swift and seamless deliveries.
              </BodyText>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 30,
                }}
              >
                <GlobalButton
                  props={{
                    background: colors.secondary,
                    color: "white",
                    full: false,
                    border: colors.secondary,
                    hover: colors.red_dark,
                    hoverColor: "white",
                    hoverBorder: colors.red_dark,
                    square: true,
                  }}
                  title="Sign up"
                >
                  Order Now
                </GlobalButton>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.primary,
                    full: false,
                    border: "transparent",
                    hover: "transparent",
                    hoverColor: colors.primary,
                    hoverBorder: "transparent",
                    square: true,
                  }}
                  title="Sign up"
                >
                  <span
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                      marginRight: 5,
                    }}
                  >
                    <BsPlayFill
                      color={colors.red_light}
                      size={35}
                      style={{ marginLeft: 5 }}
                    />
                  </span>{" "}
                  Order Process
                </GlobalButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 80,
                  marginBottom: 80,
                }}
              >
                <img
                  src={require("../../assets/images/blush.png")}
                  style={{
                    width: 40,
                    height: 40,
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    borderRadius: "50%",
                  }}
                  alt="Bike"
                />
                <BodyText
                  style={{
                    color: "grey",
                    marginLeft: 20,
                    borderLeft: "5px solid " + colors.yellow,
                    borderRadius: 1,
                    paddingLeft: 10,
                    fontSize: 13,
                  }}
                >
                  When you're too lazy to move out, <br /> we are just a click
                  away !
                </BodyText>
              </div>
            </FlexRow>
            <FlexRow
              props={{ width: 40 }}
              style={{ textAlign: "right", paddingBottom: 100 }}
            >
              <Header4>Already have an existing order?</Header4>
              <BodyText
                style={{
                  color: "grey",
                  fontSize: 13,
                  marginBottom: 30,
                }}
              >
                Enter the order code below to access detailed order tracking and
                insights, enhancing your business's operational visibility and
                efficiency.
              </BodyText>
              <div
                style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
              >
                <SearchContainer style={{ padding: 10 }}>
                  <AiOutlineSearch
                    size={18}
                    color="gray"
                    style={{ marginRight: 5 }}
                  />
                  <PlainInput type={"text"} placeholder="Enter code..." />
                </SearchContainer>
                <GlobalButton
                  props={{
                    background: colors.yellow,
                    color: colors.secondary,
                    full: false,
                    border: colors.yellow,
                    hover: colors.red_dark,
                    hoverColor: "white",
                    hoverBorder: colors.red_dark,
                    // square: true,
                  }}
                  title="Sign up"
                  onClick={() =>
                    showModal({
                      header: "Delivery query",
                      message: "Please wait, verifying delivery order code.",
                      loading: true,
                      // preventClose: true,
                    })
                  }
                >
                  Submit
                </GlobalButton>
              </div>
            </FlexRow>
          </FlexRowWrapper>
        </IndexHeroContainer>
      </GlobalContainer>
      <Footer />
    </>
  );
};

const SearchContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  width: 300px;

  @media (max-width: 768px) {
    padding: 5px;
    width: max-content;
    flex: 1;
  }
`;

export default Delivery;
