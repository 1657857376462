import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useAuth } from "../../../context/AuthProvider";
import { ProfileWrapper } from "../SideProfile/style";
import "./style.css";

import "react-image-crop/dist/ReactCrop.css";
import ImageCrop from "./ImageCrop";
import { MdOutlinePhotoFilter } from "react-icons/md";
import { colors } from "../../../utils/colors";
import useImagePreview from "../../../hooks/useImagePreview";

const ChangePhoto = () => {
  const { user } = useAuth();
  const { avatar } = user.user;
  const { show } = useImagePreview();
  const [profile, setProfile] = useState(avatar || null);

  const [src, setSrc] = useState<any>(null);

  const selectImage = (file: any) => {
    setSrc(URL.createObjectURL(file));
  };

  return (
    <>
      {!src ? (
        <div style={{ display: "flex", gap: 10, alignItems: "flex-end" }}>
          <ProfileWrapper>
            {profile ? (
              <img
                src={profile || avatar}
                onError={() => setProfile(profile)}
                style={{ height: 100, width: 100 }}
                onClick={() => show([profile])}
              />
            ) : (
              <FaUser size={70} color={colors.primary} />
            )}
          </ProfileWrapper>
          <input
            id="imagePicker"
            type="file"
            accept="image/*"
            onChange={(e: any) => {
              e?.target?.files[0] && selectImage(e.target.files[0]);
            }}
            style={{ display: "none" }}
          />
          <label htmlFor="imagePicker" className="picker_label">
            <MdOutlinePhotoFilter size={20} />
            Change Photo
          </label>
        </div>
      ) : (
        <div>
          <ImageCrop src={src} setSrc={setSrc} setProfile={setProfile} />
        </div>
      )}
    </>
  );
};

export default ChangePhoto;
