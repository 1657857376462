import React, { useState } from "react";
import { BsFillCaretDownFill, BsFillCaretRightFill } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import AnimateHeight from "react-animate-height";

import { colors } from "../../../utils/colors";
import { SectionTitle } from "./style";
import { IoMdAdd } from "react-icons/io";
import { Link } from "../../../styles";

const SectionRow = ({
  title,
  edit,
  children,
}: {
  title: string;
  edit: boolean;
  children?: React.ReactNode;
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <SectionTitle>
        {!show ? (
          <BsFillCaretRightFill
            color={colors.accent}
            onClick={() => setShow((e) => !e)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <BsFillCaretDownFill
            color={colors.accent}
            onClick={() => setShow((e) => !e)}
            style={{ cursor: "pointer" }}
          />
        )}
        <span onClick={() => setShow((e) => !e)} style={{ cursor: "pointer" }}>
          {title}
        </span>
        {edit ? (
          <Link to={"profile/edit"}>
            <TbEdit
              color={colors.red_dark}
              style={{ cursor: "pointer" }}
              title="Edit"
            />
          </Link>
        ) : (
          <IoMdAdd
            color={colors.red_dark}
            size={20}
            style={{ cursor: "pointer" }}
            title="Add"
          />
        )}
      </SectionTitle>
      <AnimateHeight height={show ? "auto" : 0}>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {children}
        </div>
      </AnimateHeight>
    </>
  );
};

export default SectionRow;
