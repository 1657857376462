import styled from "styled-components";
import { colors } from "../../utils/colors";

export const PricingImg = styled.img`
  border-radius: 10px;
  width: 50%;
  margin-right: -50px;
  height: auto;

  @media (max-width: 768px) {
    width: 70%;
    align-self: flex-end;
  }
`;

export const ServiceCardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 7.5% 5% 7.5%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0 5% 5% 5%;
  }
`;

export const ServicesCard = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  /* max-height: 380px; */
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.5s all ease-in-out;
  margin: 10px;
  max-width: 33.33%;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    max-height: max-content;
    margin: 10px 0 20px 0;
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: black;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
