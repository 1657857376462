import styled from "styled-components";
import { colors } from "../utils/colors";

interface INotificationContainer {
  props?: {
    empty: boolean;
  };
}

export const NotificationPopupContainer = styled.div<INotificationContainer>`
  position: fixed;
  display: flex;
  padding: ${({ props }) => (props?.empty ? "10px" : 0)};
  flex-direction: column;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 300px;
  transition: all 0.5s ease-in-out;
  height: min-content;
  border-radius: 0px 0 0 5px;
  backdrop-filter: blur(2.5px);
  transition: 0.5s all ease-in-out;
`;

interface INotificationCard {
  props?: {
    status: Number;
    visible: boolean;
  };
}

export const NotificationCardWrapper = styled.div<INotificationCard>`
  display: flex;
  justify-content: space-between;
  width: ${({ props }) => (props?.visible ? "100%" : 0)};
  opacity: ${({ props }) => (props?.visible ? 1 : 0)};
  background-color: ${({ props }) =>
    props?.status === 1
      ? "green"
      : props?.status === 0
      ? colors.red_light
      : "white"};
  border: ${({ props }) =>
    props?.status === 1
      ? `1px solid green`
      : props?.status === 0
      ? `1px solid ${colors.red_light}`
      : "1px solid white"};
  align-items: flex-start;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  color: ${({ props }) => (props?.status === 2 ? colors.secondary : "white")};
  margin-bottom: 10px;
  box-shadow: 0px 20px 40px rgb(10 33 65 / 5%), 0px 0px 2px rgb(0 0 0 / 13%);
  transition: 0.5s all ease-in-out;
  /* white-space: nowrap; */
  overflow: hidden;
`;
