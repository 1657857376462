import React, { useEffect, useRef, useState } from "react";
import { FcCallback, FcCancel } from "react-icons/fc";
import { TbEdit } from "react-icons/tb";
import { useAuth } from "../../../context/AuthProvider";
import { HomeCardWrap } from "../../../pages/WorkSpace/styles";
import { CardTitle, FormDesc } from "../../../styles/Global/text";
import { EditSectionWrap } from "../../../styles/WorkSpace/Profile/Profile.style";
import { colors } from "../../../utils/colors";
import useExternalAPI from "../../../hooks/useExternalAPI";
import Loader from "../../Global/Loader";
import { formHandler } from "../../../services/formHandler.service";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { GlobalInputSelectBottom, GlobalInputTextBottom } from "../../../styles/Global/input";
import { GlobalButton } from "../../../styles/Global/button";
import { gray } from "../../../styles";

const EditContacts = () => {
  const baseUrl = "http://localhost:5050/setup";
  const { postData } = useExternalAPI();
  const { addNotification } = useMessagePreview();
  const { setUser, user } = useAuth();
  const { contacts } = user.user;
  // const { primary, secondary } = contacts;
  const [countries, setCountries] = useState<Array<any>>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const primaryRef = useRef<HTMLInputElement | null>(null);
  const secondaryRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const response: any = await postData({
      baseUrl,
      url: "form/countries",
      get: true,
    });

    if (response.status) {
      setCountries(response?.data);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = formHandler(e);

    const response: any = await postData({
      url: "user/updateDetails",
      body: { ...formData, category: "contacts" },
    });

    if (response.status) {
      setEdit(false);
      addNotification({ message: "Contacts changes successful", type: 1 });
      setUser({
        ...user,
        user: { ...user.user, contacts: response.data.contacts },
      });
    } else
      addNotification({ message: "Contacts changes unsuccessful", type: 0 });
    setLoading(false);
  };

  const resetForm = async () => {
    try {
      if (primaryRef.current)
        primaryRef.current!.value = contacts?.primary?.number;
      if (secondaryRef.current)
        secondaryRef.current!.value = contacts?.secondary?.number;
      setEdit(false);
    } catch (e) {}
  };

  return (
    <HomeCardWrap
      props={{ width: 49, widthMobile: 100 }}
      style={{ alignItems: "flex-start", marginTop: 20 }}
    >
      {/* <FormDesc>
            Please edit the fields below to implement changes.
          </FormDesc> */}
      <EditSectionWrap>
        <CardTitle className="profile_section_left">
          <span className="profile_title">
            <FcCallback
              size={25}
              //   color={"#d90368"}
              style={{ marginRight: 5 }}
            />
            Contacts
          </span>{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() => (edit ? resetForm() : setEdit(true))}
          >
            {loading ? (
              <Loader />
            ) : !edit ? (
              <TbEdit
                color={"#2196F3"}
                style={{ cursor: "pointer" }}
                size={18}
                title="Edit"
              />
            ) : (
              <FcCancel size={18} />
            )}
          </span>
        </CardTitle>
        <form
          onSubmit={(e) => !loading && onSubmit(e)}
          style={{
            height: "min-content",
            display: "flex",
            flexDirection: "column",
            // width: "70%",
            flex: 1,
            justifyContent: "flex-start",
          }}
        >
          <FormDesc style={{ margin: "0px 0 5px 0" }}>Primary</FormDesc>
          <span style={{ display: "flex", alignItems: "flex-end", gap: 10 }}>
            <GlobalInputSelectBottom
              placeholder="Select country"
              props={{
                background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
              }}
              // defaultValue={address?.country}
              disabled={!edit}
              // ref={countryRef}
              name="primary_code"
              required
            >
              <option value={""} selected disabled>
                select code
              </option>
              {countries.map((data, index) => (
                <option
                  key={index}
                  value={data?.phone_code}
                  selected={`${contacts?.primary?.code}` === data?.phone_code}
                >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
              ))}
            </GlobalInputSelectBottom>
            <GlobalInputTextBottom
              placeholder="Enter phone number"
              type={"tel"}
              props={{
                background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
              }}
              defaultValue={contacts?.primary?.number}
              ref={primaryRef}
              readOnly={!edit}
              name="primary"
              required
            />
          </span>
          <FormDesc style={{ margin: "10px 0 5px 0" }}>Secondary</FormDesc>
          <span style={{ display: "flex", alignItems: "flex-end", gap: 10 }}>
            <GlobalInputSelectBottom
              placeholder="Select country"
              props={{
                background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
              }}
              // defaultValue={address?.country}
              disabled={!edit}
              // ref={countryRef}
              name="secondary_code"
            >
              <option value={""} selected disabled>
                select code
              </option>
              {countries.map((data, index) => (
                <option
                  key={index}
                  value={data?.phone_code}
                  selected={`${contacts?.secondary?.code}` === data?.phone_code}
                >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
              ))}
            </GlobalInputSelectBottom>

            <GlobalInputTextBottom
              placeholder="Enter phone number"
              type={"tel"}
              props={{
                background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
              }}
              defaultValue={contacts?.secondary?.number}
              ref={secondaryRef}
              readOnly={!edit}
              name="secondary"
            />
          </span>
          {edit && (
            <GlobalButton
              props={{
                background: colors.accent_light,
                color: colors.secondary,
                full: false,
                hover: "#138B32",
                hoverColor: "white",
                hoverBorder: "#138B32",
                square: true,
              }}
              style={{ marginTop: 10 }}
              title="Sign up"
            >
              Save changes
            </GlobalButton>
          )}
        </form>
      </EditSectionWrap>
    </HomeCardWrap>
  );
};

export default EditContacts;
