import styled from "styled-components";

interface IModalContainer {
  props?: {
    visible: boolean;
  };
}

export const ModalContainer = styled.div<IModalContainer>`
  position: fixed;
  display: ${({ props }) => (props?.visible ? "flex" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2.5px);
  background-color: ${({ props }) =>
    props?.visible ? "rgba(0, 0, 0, 0.6)" : "transparent"};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999;
  transition: all 0.25s ease-in-out;
`;

interface IModalWrapper {
  props?: {
    visible: boolean;
    wrapper: boolean
  };
}

export const ModalWrapper = styled.div<IModalWrapper>`
  max-width: 70%;
  max-height: 80%;
  height: max-content;
  width: max-content;
  border-radius: 5px;
  padding: 2.5%;
  opacity: ${({ props }) => (props?.visible ? 1 : 0)};
  background-color: white;
  /* overflow: hidden; */
  transition: all 0.25s ease-in-out;
  transform: ${({ props }) => (props?.wrapper ? "scale(1)" : "scale(0)")};

  @media (max-width: 768px) {
    max-width: 90%;
    max-height: 90%;
  }
`;
