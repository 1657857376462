import React, { useEffect, useRef, useState } from "react";
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import mapStyles from "../../assets/json/customMapView.json";
import { ILocationData } from "../WorkSpace/Services/Delivery/AddDelivery.component";
import { colors } from "../../utils/colors";

const DisplayMap = ({
  isLoaded,
  loadError,
  from,
  to,
}: {
  isLoaded: any;
  loadError: any;
  to?: ILocationData[] | undefined;
  from?: ILocationData | undefined;
}) => {
  const [zoom, setZoom] = useState(10);
  const mapRef = useRef<google.maps.Map>();
  const [direction, setDirection] =
    useState<google.maps.DirectionsResult | null>(null);

  const default_position = {
    lat: 5.6037,
    lng: -0.187,
  };

  useEffect(() => {
    if (isLoaded) {
      // Check if Google Maps API is loaded
      console.log("Map loaded");
      if (from && to && to.length > 0) {
        console.log("Map values change: ", { to, from });
        const waypoints = to.map((stop) => ({
          location: { lat: stop.latitude, lng: stop.longitude },
          stopover: true,
        }));

        const directionsServiceOptions: google.maps.DirectionsRequest = {
          destination: {
            lat: to[to.length - 1].latitude,
            lng: to[to.length - 1].longitude,
          },
          origin: { lat: from.latitude, lng: from.longitude },
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: waypoints,
        };

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          directionsServiceOptions,
          (
            result: google.maps.DirectionsResult | null,
            status: google.maps.DirectionsStatus
          ) => {
            if (status === "OK" && result) {
              setDirection(result);
            }
          }
        );
      } else {
        console.error("Error: 'from' and 'to' values are missing", {
          from,
          to,
        });
      }
    } else if (loadError) {
      console.log("Error loading Google Maps:", loadError);
    }
  }, [isLoaded, loadError, from, to]);

  const handleZoomChange = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom || 10);
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          center={default_position}
          zoom={zoom}
          onLoad={(map) => {
            mapRef.current = map;
            handleZoomChange();
          }}
          onZoomChanged={handleZoomChange}
          options={{
            // disableDefaultUI: true,
            styles: mapStyles,
          }}
        >
          {direction && (
            <DirectionsRenderer
              directions={direction}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: colors.yellow,
                  strokeWeight: 2,
                },
              }}
            />
          )}
          {from && (
            <Marker
              position={{ lat: from.latitude, lng: from.longitude }}
              icon={{
                url: require("../../assets/images/markers/start_pin.png"),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              title="Pickup location"
            />
          )}
          {to &&
            to?.map((data, index) => (
              <Marker
                key={index}
                position={{ lat: data.latitude, lng: data.longitude }}
                icon={{
                  url:
                    index + 1 === to.length
                      ? require("../../assets/images/markers/end_pin.png")
                      : require("../../assets/images/markers/mid_pin.png"),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                title={`Drop off ${index + 1} - ${data.label}`}
              />
            ))}
        </GoogleMap>
      ) : (
        <div>Loading Google Maps...</div>
      )}
    </div>
  );
};

export default DisplayMap;
