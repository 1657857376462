import React, { useEffect, useState } from "react";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import loader from "../../assets/animations/loader.json";
import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";
import { FormResponseContainer } from "../../styles/Global/form";

interface IFormResponse {
  status: Number;
  visible?: boolean;
  message: string;
}

const FormResponse = ({ status, visible, message }: IFormResponse) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  //   useEffect(() => {
  //     if (status !== 2 && visible) {
  //       setTimeout(() => {
  //         setOpacity(0);
  //       }, 4000);
  //     }
  //   }, [visible]);

  return (
    <FormResponseContainer
      props={{
        status,
        visible: visible === null || visible === undefined ? true : visible,
      }}
      style={{ opacity: 1 }}
    >
      {status === 2 ? "Please wait, " + message : message}
      {status === 2 ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
          }}
          height={isBigScreen ? 90 : 50}
          width={isBigScreen ? 90 : 50}
          style={{
            margin: isBigScreen ? "-37px -33px -33px 0" : "-18px -18px -14px 0",
          }}
          isClickToPauseDisabled={true}
        />
      ) : (
        <IoNotificationsCircleOutline
          color="white"
          style={{ minWidth: 20, minHeight: 20,marginLeft: 5, alignSelf: "flex-start" }}
        />
      )}
    </FormResponseContainer>
  );
};

export default FormResponse;
