import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

const RedirectRouteGlobal = () => {
  const { pathname } = useLocation();
  const { user, navigate } = useAuth();
  const whitelisted = ["workspace"];

  useEffect(() => {
    const paths = pathname.split("/");
    // console.log({ user, paths });
    if (user?.user && user?.tokens) {
      // console.log({ exist: whitelisted.includes(paths[1]) });
        if (!whitelisted.includes(paths[1])) navigate("/workspace");
    }
  }, [pathname]);

  return null;
};

export default RedirectRouteGlobal;
