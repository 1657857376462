import { createContext, ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";

export interface IAuthContext {
  children: ReactNode;
  user: any;
  setUser: (data: object) => boolean;
  login: (data: object) => Promise<void>;
  logout: () => void;
  navigate: NavigateFunction;
}

const AuthContext = createContext<IAuthContext>({
  children: null,
  user: null,
  setUser: (): boolean => true || false,
  login: async () => {},
  logout: (): void => {},
  navigate: () => {},
});

export default AuthContext;
