/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { IDeliveryFormData } from "../AddDelivery.component";
import { FormDesc, Header3 } from "../../../../../styles/Global/text";
import { colors } from "../../../../../utils/colors";
import { gray, transition } from "../../../../../styles";
import { BsCalendarCheck } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import MapWithAutocomplete from "../../../../Map/MapWithAutocomplete.component";
import { useMediaQuery } from "react-responsive";
import useUserLocation from "../../../../../hooks/useUserLocation";
import { GlobalButtonHighlight } from "../../../../../styles/Global/button";
import { LiaMapPinSolid } from "react-icons/lia";
import { CiSquareRemove } from "react-icons/ci";
import MapContainer from "../../../../Map/MapContainer.component";

const Step2 = ({
  formData,
  setFormData,
}: {
  formData: IDeliveryFormData;
  setFormData: React.Dispatch<React.SetStateAction<IDeliveryFormData>>;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const location = useUserLocation();

  const [edit, setEdit] = useState<{
    type: 0 | 1;
    status: Boolean;
    label: string;
    index: number | null;
  }>({ type: 0, status: false, label: "", index: null });
  const [showAddStop, setShowAddStop] = useState<boolean>(false);

  useEffect(() => {
    console.log({ isMobile, location });
  }, [isMobile]);

  const submitLocation = (loc: {
    label: string;
    latitude: number;
    longitude: number;
  }) => {
    let location: any = {};

    if (edit.type === 0)
      location = {
        from: {
          label: loc.label,
          latitude: loc.latitude,
          longitude: loc.longitude,
        },
      };
    else {
      if (!formData.route?.to || formData.route.to.length === 0) {
        location = {
          to: [
            {
              label: loc.label,
              latitude: loc.latitude,
              longitude: loc.longitude,
            },
          ],
        };
      } else if (edit.index !== null) {
        const updatedToLocations = formData.route?.to.map((item, index) =>
          index === edit.index
            ? {
                label: loc.label,
                latitude: loc.latitude,
                longitude: loc.longitude,
              }
            : item
        );
        location = {
          to: updatedToLocations,
        };
      } else {
        location = {
          to: [
            ...formData.route?.to,
            {
              label: loc.label,
              latitude: loc.latitude,
              longitude: loc.longitude,
            },
          ],
        };
      }

      setShowAddStop(false);
    }

    setFormData((e) => ({ ...e, route: { ...e.route, ...location } }));
    setEdit((e) => ({ ...e, status: false, index: null }));
  };

  const removeLocationAtIndex = (index: number) => {
    if (formData.route?.to) {
      const updatedLocations = formData.route.to.filter((_, i) => i !== index);
      setFormData((prevData) => ({
        ...prevData,
        route: { ...prevData.route, to: updatedLocations },
      }));
    }
  };

  return (
    <>
      <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
        🗺️ Choose Routes
      </Header3>
      <FormDesc style={{ marginBottom: 30 }}>
        Select pickup location of the item to be delivered and the delivery
        (destination) address.
      </FormDesc>
      <div style={{ display: "flex", gap: isMobile ? 0 : 20 }}>
        <div
          style={{
            width: !isMobile ? "50%" : edit.status ? 0 : "100%",
            overflowX: "hidden",
            transition: transition,
            whiteSpace: isMobile ? "nowrap" : "pre-wrap",
          }}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <span
                style={{
                  padding: 3,
                  border: `2px solid ${colors.yellow}`,
                  borderRadius: "50%",
                  marginTop: 2,
                }}
              />
              <h1 style={{ border: "1px dashed " + gray(0.2), flex: 1 }} />
            </span>
            <div style={{ marginBottom: 100 }}>
              <h5
                style={{
                  fontSize: 11,
                  color: colors.yellow,
                  margin: "0 10px 0 0",
                }}
              >
                PICKUP LOCATION
              </h5>
              <h2
                style={{
                  fontSize: isMobile ? 13 : 18,
                  whiteSpace: "pre-wrap",
                  fontWeight: 600,
                  marginBottom: 8,
                  cursor: "pointer",
                  color: formData.route?.from?.label ? "black" : gray(0.4),
                }}
                onClick={() =>
                  setEdit({
                    type: 0,
                    status: true,
                    label: formData.route?.from?.label || "",
                    index: null,
                  })
                }
              >
                {formData.route?.from?.label || "Choose location"}{" "}
                {/* <AutoComplete /> */}
                <FiEdit3 size={20} color={gray(0.3)} />
              </h2>
              <h4
                style={{
                  fontSize: 12,
                  color: gray(0.4),
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 600,
                  margin: 0,
                }}
              >
                <BsCalendarCheck color={colors.yellow} />{" "}
                {new Date(formData?.schedule || new Date()).toDateString()}
              </h4>
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <span
                style={{
                  padding: 3,
                  border: `2px solid ${colors.yellow}`,
                  borderRadius: "50%",
                  marginTop: 2,
                }}
              />
            </span>
            <div style={{ marginBottom: 50 }}>
              <h5
                style={{
                  fontSize: 11,
                  color: colors.yellow,
                  margin: "0 10px 0 0",
                }}
              >
                DELIVERY LOCATION
              </h5>
              {formData.route?.to &&
                formData.route?.to?.length > 0 &&
                formData.route?.to?.map((data, index) => (
                  <h2
                    style={{
                      fontSize: isMobile ? 13 : 18,
                      fontWeight: 600,
                      marginBottom: 8,
                      cursor: "pointer",
                      color: data.label ? "black" : gray(0.4),
                    }}
                    key={data.label + index}
                    onClick={() =>
                      setEdit({
                        type: 1,
                        status: true,
                        label: data.label,
                        index,
                      })
                    }
                  >
                    {data.label || "Choose location"}{" "}
                    <FiEdit3 size={20} color={gray(0.3)} />
                    {data.label &&
                      formData.route?.to &&
                      formData?.route?.to?.length > 1 && (
                        <CiSquareRemove
                          size={22}
                          color={colors.red_dark}
                          style={{ marginLeft: 10 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeLocationAtIndex(index);
                          }}
                        />
                      )}
                  </h2>
                ))}
              {(!formData.route?.to ||
                formData.route?.to?.length === 0 ||
                showAddStop) && (
                <h2
                  style={{
                    fontSize: isMobile ? 13 : 18,
                    fontWeight: 600,
                    marginBottom: 8,
                    cursor: "pointer",
                    color: gray(0.4),
                  }}
                  onClick={() =>
                    setEdit({ type: 1, status: true, label: "", index: null })
                  }
                >
                  {"Choose location"} <FiEdit3 size={20} color={gray(0.3)} />
                </h2>
              )}
              {formData.route?.to &&
                formData.route?.from &&
                formData.route?.to?.length > 0 &&
                formData.route?.to?.length < 3 && (
                  <GlobalButtonHighlight
                    props={{
                      background: colors.accent_light,
                      hover: colors.yellow,
                      color: colors.secondary,
                    }}
                    style={{ marginBottom: 20 }}
                    onClick={() => setShowAddStop(true)}
                  >
                    <LiaMapPinSolid size={18} />
                    Add stop
                  </GlobalButtonHighlight>
                )}
              <h4
                style={{
                  fontSize: 12,
                  color: gray(0.4),
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 600,
                  margin: 0,
                }}
              >
                <BsCalendarCheck color={colors.yellow} />{" "}
                {new Date(formData?.schedule || new Date()).toDateString()}
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: !isMobile ? "50%" : edit.status ? "100%" : 0,
            transition: transition,
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          {
            <MapContainer
              mapType={"autocomplete"}
              autoCompleteProps={{
                edit,
                current_location: location,
                setEdit,
                from: formData.route?.from,
                to: formData.route?.to,
                submitLocation,
              }}
            />
          }
        </div>
      </div>
    </>
  );
};

export default Step2;
