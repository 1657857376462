import styled from "styled-components";
import { transition } from "../../../styles";
import { colors } from "../../../utils/colors";
import { ISideProfile } from "../../../utils/interface";

export const Container = styled.div<ISideProfile>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  opacity: ${({ props }) => (props?.show ? 1 : 0)};
  left: ${({ props }) => (props?.show ? "65%" : "100%")};
  transition: ${transition};
  padding: ${({ props }) => (props?.show ? "20px" : 0)};
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  z-index: 2;

  @media (max-width: 768px) {
    left: ${({ props }) => (props?.show ? "0%" : "100%")};
  }
`;

export const Hide = styled.div<ISideProfile>`
  display: flex;
  position: absolute;
  top: 0;
  right: ${({ props }) => (props?.show ? "35%" : "100%")};
  bottom: 0;
  opacity: ${({ props }) => (props?.show ? 1 : 0)};
  left: 0;
  transition: ${transition};
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HideButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  transition: ${transition};
  font-size: 16px;
  background: none;
  background-color: "white";
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;

  :hover {
    background-color: ${colors.accent_light};
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  min-height: 100px;
  min-width: 100px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  width: min-content;
  margin-top: 20px;
`;

export const SectionTitle = styled.span`
  display: flex;
  font-size: 14px;
  gap: 10px;
  font-weight: 600;
  margin-bottom: 15px;
  align-items: center;
  margin-top: 20px;
`;

export const LogoutButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  transition: ${transition};
  font-size: 16px;
  background: none;
  background-color: "white";
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 10px;
  margin-top: 20px;
  width: max-content;

  :hover {
    background-color: ${colors.red_dark};
    color: white;
  }
`;

export const SectionLabel = styled.span`
  color: ${colors.accent};
  font-size: 14px;
`;

export const SectionValue = styled.span`
  color: black;
  margin-left: 5px;
`;
