import styled from "styled-components";

interface PopupContainerProps {
  top: number;
  left: number;
}

export const PopupContainer = styled.div<PopupContainerProps>`
  position: fixed;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px;
  border-radius: 4px;
  width: 200px;
  transition: all 0.3s ease-in-out !important;

  .popup-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
  }

  .popup-content {
    margin-top: 10px;
  }
`;
