//Form interfaces
export interface IFormResponse {
  message: string;
  visible?: boolean;
  status: number;
}

//Side profile interfaces
export interface ISideProfile {
  props: {
    show: boolean;
  };
}

export enum EServices {
  "Courier Service",
  "AI Analytics Service",
  "E-Payment Service",
}

export enum EServicesColor {
  "Courier Service" = "#FCA311",
  "AI Analytics Service" = "#790000",
  "E-Payment Service" = "#022350",
}

export enum DeliveryItemType {
  "Food",
  "Documents",
  "Retail",
  "Medical",
  "Specialized"
}



export interface IPromptAction {
  color: string;
  label: string;
  action?: () => void;
}