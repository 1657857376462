import React from "react";
import { useSelector } from "react-redux";
import NotificationCard from "../NotificationPopup/NotificationCard";
import { RootState } from "../../redux/store";
import { NotificationPopupContainer } from "../../styles/NotificationPopup.style";

const NotificationPopup = () => {
  const list = useSelector((state: RootState) => state.notification.list);

  return (
    <NotificationPopupContainer
      props={{ empty: list.length > 0 ? true : false }}
    >
      {list.map(({ id, message, type, timer }, index) => (
        <NotificationCard
          id={id}
          message={message}
          type={type}
          timer={timer}
          key={id}
        />
      ))}
    </NotificationPopupContainer>
  );
};

export default NotificationPopup;
