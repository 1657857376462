import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import useLocalStorage from "../hooks/useLocalStorage";

export const RedirectRoute = ({
  children,
}: {
  children: React.ReactNode;
}): any => {
  const { user } = useAuth();

  if (user?.user && user?.tokens) {
    return children;
  }
  return <Navigate to="/signin" />;
};
