function formHandler(e: React.FormEvent<HTMLFormElement> | any) {
  e.preventDefault();

  const formElements = e.target.elements;

  const formData: {
    [key: string]: any;
  } = {};

  const elemArray = [...formElements];
  elemArray.forEach(({ name, tagName, value, type }) => {
    // console.log("TagName: ", tagName);
    if (type != "submit" && name && name !== "") formData[name] = value;
  });

  // console.log("Form: ", formData);

  return formData;
}

export { formHandler };
