import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";
import pricing from "../../assets/animations/pricing.json";
import Footer from "../../components/Global/Footer";
import Navigations from "../../components/Global/Navigations";
import { IndexHeroContainer, IndexHeroDiv } from "../../styles/Home.style";
import {
  ServiceCardWrapper,
  ServicesCard,
  ServicesH2,
  ServicesIcon,
  ServicesP,
} from "./styles";
import { colors } from "../../utils/colors";
import shoppingCart from "../../assets/images/shopping.svg";
import delivery from "../../assets/images/delivery.svg";
import epayment from "../../assets/images/epayment.svg";
import { Link } from "react-router-dom";
import { BodyText, Header1_Light } from "../../styles/Global/text";
import { GlobalContainer } from "../../styles/Global/container.style";
import { GlobalButton } from "../../styles/Global/button";

const Pricing = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <Navigations />
      <GlobalContainer style={{ flexDirection: "column", padding: 0 }}>
        <IndexHeroContainer
          style={{
            marginTop: 100,
          }}
          props={{ direction: "right", background: "transparent" }}
        >
          <IndexHeroDiv>
            {/* <BiTrip color={colors.red_dark} size={20} /> */}
            <Header1_Light>WE HAVE A MUTUAL BENEFITS</Header1_Light>
            <BodyText style={{color: "grey" }}>
              We only make money when you do; our services are provided on a
              pay-as-you-go basis, with a percentage charge based on service
              utilization. The percentage is determined by the services you have
              implemented.
            </BodyText>
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: false,
                  border: colors.secondary,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                }}
                title="Sign up"
              >
                Get started
              </GlobalButton>
            </Link>
          </IndexHeroDiv>
          {/* <PricingImg
            src={require("../assets/images/happyConvo.jpg")}
          /> */}
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: pricing,
            }}
            height={isBigScreen ? 600 : 300}
            width={isBigScreen ? 600 : 300}
          />
        </IndexHeroContainer>
        <div
          style={{
            background: colors.accent_light,
            display: "flex",
            flexDirection: "column",
          }}
          id="products"
        >
          <IndexHeroDiv style={{ textAlign: "center" }}>
          <Header1_Light>WHAT WE OFFER?</Header1_Light>
          </IndexHeroDiv>
          <ServiceCardWrapper>
            <ServicesCard>
              {/* <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: shopping,
                }}
                height={isBigScreen ? 300 : 150}
                width={isBigScreen ? 300 : 150}
              /> */}
              <ServicesIcon src={shoppingCart}/>
              <ServicesH2 style={{ textAlign: "center" }}>
                Digitalize Products & Services
              </ServicesH2>
              <ServicesP>
                Your products or services can be digitalized, with items
                accessible by quick response (QR) code or page search.
              </ServicesP>
            </ServicesCard>
            <ServicesCard>
              {/* <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: delivery,
                }}
                height={isBigScreen ? 300 : 200}
                width={isBigScreen ? 300 : 200}
              /> */}
              <ServicesIcon src={delivery}/>
              <ServicesH2 style={{ textAlign: "center" }}>Integrated Delivery</ServicesH2>
              <ServicesP>
                Incorporating an optional delivery system with a number of
                bespoke features that are tailored exactly to your business, you
                can conveniently distribute your services or products.
              </ServicesP>
            </ServicesCard>
            <ServicesCard>
              {/* <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: epayment,
                }}
                height={isBigScreen ? 250 : 200}
                width={isBigScreen ? 250 : 200}
              /> */}
              <ServicesIcon src={epayment}/>
              <ServicesH2 style={{ textAlign: "center" }}>E-Payment</ServicesH2>
              <ServicesP>
                You can rapidly make sales with the e-payment option, either
                locally or from a distance. When a distribution system is put in
                place, this feature operates to its full capacity.
              </ServicesP>
            </ServicesCard>
          </ServiceCardWrapper>
        </div>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default Pricing;
