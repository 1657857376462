import { useState, useEffect } from 'react';

const useUserLocation = () => {
  const [location, setLocation] = useState<any>(null);

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return;
    }

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            console.log(`Error getting location: ${error.message}`);
          }
        );
      } else if (result.state === 'prompt') {
        // Prompt the user to enable location services here
        console.log('Please enable location services to use this feature.');
      } else {
        console.log('Location permission denied.');
      }
    });
  };

  useEffect(() => {
    getLocation();
  }, []);

  return location;
};

export default useUserLocation;