import React from "react";
import { Route, Routes } from "react-router-dom";
import Business from "../pages/WorkSpace/Business/AllBusiness";
import NotFoundView from "../components/NotFoundView";
import AddBusiness from "../pages/WorkSpace/Business/AddBusiness";
import BusinessProfile from "../pages/WorkSpace/Business/BusinessProfile";
import EditBusiness from "../pages/WorkSpace/Business/EditBusiness";

const BusinessRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Business />} />
      <Route path="/add" element={<AddBusiness />} />
      <Route path="/edit" element={<EditBusiness />} />
      <Route path="/page" element={<BusinessProfile />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};

export default BusinessRoutes;
