import { useEffect, useState } from "react";
import { colors } from "../../../../../utils/colors";
import { useAuth } from "../../../../../context/AuthProvider";
import GlobalPopup from "../../../../Global/GlobalPopup";
import { BiDotsVerticalRounded, BiUser } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import useExternalAPI from "../../../../../hooks/useExternalAPI";
import useImagePreview from "../../../../../hooks/useImagePreview";
import useMessagePreview from "../../../../../hooks/useMessagePreview";
import Loader from "../../../../Global/Loader";

const MemberProfileRow = ({
  id,
  role,
  data,
  isAdmin,
  admin,
  removeUser,
  updateUser,
}: {
  id: number | null;
  role: number;
  data: any;
  isAdmin: boolean;
  admin: boolean;
  removeUser: (data: any) => boolean;
  updateUser: (data: any, role: number) => boolean;
}) => {
  const [avatar, setAvatar] = useState(data.avatar);
  const { user } = useAuth();
  const { addNotification, showModalPrompt } = useMessagePreview();
  const { show } = useImagePreview();
  const { postData } = useExternalAPI();
  const [loading, setLoading] = useState(false);
  const [profileOptions, setProfileOptions] = useState<
    Array<JSX.Element | string>
  >([
    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
      <BiUser />
      View Profile
    </div>,
  ]);

  useEffect(() => {
    isAdmin &&
      setProfileOptions((e) => [
        ...e,
        <span onClick={() => updateMemberRole()}>
          {role !== 2 ? "Make Admin" : "Remove Admin"}
        </span>,
        <span
          onClick={() =>
            showModalPrompt({
              message: `Are you sure you want to remove ${data.email} and deny them access to this business page?`,
              options: [
                {
                  label: "yes",
                  color: "green",
                  action: () => removeMemberRequest(),
                },
                { label: "No", color: colors.red_dark },
              ],
            })
          }
        >
          Remove member
        </span>,
      ]);
  }, [isAdmin]);

  const name = (user: any) =>
    `${user?.lastname || ""} ${user?.othernames || ""} ${
      user?.firstname || ""
    }`;

  const removeMemberRequest = async () => {
    try {
      setLoading(true);
      const request: any = await postData({
        url: "business/removeMember",
        body: { email: data.email, id },
      });

      const { message, status } = request;

      if (status) {
        removeUser(data);
        addNotification({ message: message || "", type: 1 });
      } else {
        addNotification({ message: message || "", type: 0 });
      }

      setLoading(false);
    } catch (e) {
      addNotification({
        message: "An error occured while removing memeber",
        type: 0,
      });
      setLoading(false);
    }
  };

  const updateMemberRole = async () => {
    try {
      setLoading(true);
      const newRole = role === 2 ? 1 : 2;
      const request: any = await postData({
        url: "business/updateMemberRole",
        body: { email: data.email, id, role: newRole },
      });

      const { message, status } = request;

      if (status) {
        updateUser(data, newRole);
        addNotification({ message: message || "", type: 1 });
      } else {
        addNotification({ message: message || "", type: 0 });
      }

      setLoading(false);
    } catch (e) {
      addNotification({
        message: "An error occured while updating memeber role",
        type: 0,
      });
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginTop: 10,
        fontSize: 13,
        fontWeight: "600",
      }}
      key={data.email}
    >
      {avatar ? (
        <img
          src={avatar}
          onError={() => setAvatar(null)}
          onClick={() => show([avatar])}
          style={{ borderRadius: "50%", height: 50, width: 50 }}
        />
      ) : (
        <FaUserCircle size={50} color={colors.primary} />
      )}
      <span
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "normal",
        }}
      >
        <span>
          {name(data)}
          {admin && (
            <span
              style={{
                display: "block",
                fontWeight: "normal",
                fontSize: 10,
                padding: "3px 5px",
                borderRadius: 2,
                width: "min-content",
                color: colors.red_dark,
                backgroundColor: "rgba(121, 0, 0, 0.1)",
              }}
            >
              Admin
            </span>
          )}
        </span>
        {loading ? (
          <Loader />
        ) : (
          data.email !== user.user.email && (
            <GlobalPopup
              id={data.email}
              icon={<BiDotsVerticalRounded size={20} />}
              options={profileOptions}
            />
          )
        )}
      </span>
    </div>
  );
};

export default MemberProfileRow;
