import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import DisplayMap from "./DisplayMap.component";
import MapWithAutocomplete from "./MapWithAutocomplete.component";
import { ILocationData } from "../WorkSpace/Services/Delivery/AddDelivery.component";

const MapContainer = ({
  mapType,
  displayProps,
  autoCompleteProps,
}: {
  mapType: "display" | "autocomplete";
  displayProps?: {
    to?: ILocationData[] | undefined;
    from?: ILocationData | undefined;
  };
  autoCompleteProps?: {
    edit: {
      type: 0 | 1;
      status: Boolean;
      label: string;
      index: number | null;
    };
    setEdit: React.Dispatch<
      React.SetStateAction<{
        type: 0 | 1;
        status: Boolean;
        label: string;
        index: number | null;
      }>
    >;
    current_location?: { lat: number; lng: number };
    to?: ILocationData[] | undefined;
    from?: ILocationData | undefined;
    submitLocation: (loc: {
      label: string;
      latitude: number;
      longitude: number;
    }) => void;
  };
}) => {
  const libraries: (
    | "places"
    | "drawing"
    | "geometry"
    | "localContext"
    | "visualization"
  )[] = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY?.toString() || "",
    libraries,
  });

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoaded && (
        <>
          {mapType === "display" && displayProps && (
            <DisplayMap
              isLoaded={isLoaded}
              loadError={loadError}
              from={displayProps.from}
              to={displayProps.to}
            />
          )}
          {mapType === "autocomplete" && autoCompleteProps && (
            <MapWithAutocomplete
              isLoaded={isLoaded}
              loadError={loadError}
              edit={autoCompleteProps.edit}
              current_location={autoCompleteProps.current_location}
              setEdit={autoCompleteProps.setEdit}
              from={autoCompleteProps.from}
              to={autoCompleteProps.to}
              submitLocation={autoCompleteProps.submitLocation}
            />
          )}
        </>
      )}
      {!isLoaded && <div>Loading Google Maps...</div>}
    </div>
  );
};

export default MapContainer;
