import { FaUserCircle } from "react-icons/fa";
import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const Cover = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
  @media (max-width: 768px) {
    height: 0;
  }
`;

export const ProfileBasicDetails = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProfileImgIcon = styled(FaUserCircle)`
  border-radius: 50%;
  height: 150px;
  width: 150px;
  color: ${colors.primary};
  background-color: white;
  margin: -45px 10px 0 50px;

  @media (max-width: 768px) {
    margin: 0 10px 0 0;
    height: 80px;
    width: 80px;
  }
`;

export const ProfileImg = styled.img`
  border-radius: 50%;
  height: 150px;
  width: 150px;
  background-color: gray;
  margin: -45px 10px 0 50px;
  border: 4px solid #f1f3f4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    margin: 0 10px 0 0;
    height: 80px;
    width: 80px;
    border: none;
  }
`;

export const ProfileName = styled.h5`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 5px 0;

  @media (max-width: 768px) {
    align-items: flex-start;
    font-size: 16px;
  }
`;

export const ProfileEmail = styled.p`
  margin: 0;
  font-size: 16px;
  color: gray;
`;

export const ProfileJoined = styled.p`
  margin: 0;
  font-size: 13px;
  color: gray;
  margin-top: 5px;
  margin-left: auto;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const SummaryCardContainer = styled.div`
  flex: 44%;
  background-color: #f2f2f2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 20px;

  @media (max-width: 768px) {
    flex: 46%;
    gap: 10px;
  }
`;

export const EditSectionWrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
