import Schedular from "../../../components/Global/Schedular";
import AddDelivery from "../../../components/WorkSpace/Services/Delivery/AddDelivery.component";

const Services = () => {

  return (
    // <Schedular daily={`${new Date()}`}/>
    <AddDelivery />
  );
};


export default Services;
