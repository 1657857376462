import { useEffect, useState } from "react";
import { BodyText, Header2 } from "../../../styles/Global/text";
import {
  BusinessDesc,
  BusinessTitleWrapper,
} from "../../../styles/WorkSpace/Business.style";
import { Link } from "../../../styles";
import { colors } from "../../../utils/colors";
import {
  BiCheckDouble,
  BiDotsVerticalRounded,
  BiPlus,
  BiUser,
} from "react-icons/bi";
import { HomeCardWrap } from "../styles";
import Table from "../../../components/Table";
import BusinessNameLogoWrapper from "../../../components/WorkSpace/Profile/BusinessNameLogoWrapper";
import useExternalAPI from "../../../hooks/useExternalAPI";
import {
  GlobalButton,
  GlobalButtonHighlight,
} from "../../../styles/Global/button";
import GlobalPopup from "../../../components/Global/GlobalPopup";
import { encrypt } from "../../../services/encryption.service";
import { FlexRow } from "../../../styles/Global/container.style";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
import { useAuth } from "../../../context/AuthProvider";
import useMessagePreview from "../../../hooks/useMessagePreview";

const AllBusiness = () => {
  const { postData } = useExternalAPI();
  const { showModal, hideModal, addNotification } = useMessagePreview();
  const { user } = useAuth();
  const [businesses, setBusinesses] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    loadBusinesses();
  }, []);

  const updateRequest = async ({
    approve,
    id,
  }: {
    approve: boolean;
    id: number;
  }) => {
    try {
      showModal({
        header: "Please wait..",
        message: "Loading business list data, this should take few seconds.",
        loading: true,
        preventClose: true,
      });

      const request = await postData({
        url: "/business/updateMemberStatus",
        body: { id, approve },
      });

      if (request.status) loadBusinesses();
      else hideModal();
    } catch (e) {
      hideModal();
      addNotification({
        message: "An error occured while loading data.",
        type: 0,
      });
    }
  };

  const loadBusinesses = async () => {
    try {
      showModal({
        header: "Please wait..",
        message: "Loading business list data, this should take few seconds.",
        loading: true,
        preventClose: true,
      });

      const request = await postData({
        url: "business/getUserBusinesses",
        get: true,
      });

      if (request.status) {
        const businesses: any = [];
        const requests: any = [];
        request.data.businesses.map((data: any, index: number) =>
          businesses.push([
            index + 1,
            <BusinessNameLogoWrapper data={data} open={true} />,
            `${data.category}`,
            `$1,345.50`,
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {new Date(data.createdAt).toDateString()}
              <GlobalPopup
                key={data.id}
                id={data.id}
                icon={<BiDotsVerticalRounded size={20} />}
                options={[
                  <Link
                    to={`page?id=${encrypt(data.id)}`}
                    state={{ props: data }}
                    style={{ display: "block" }}
                  >
                    View
                  </Link>,
                  <Link
                    to={`page?id=${encrypt(data.id)}&sub=members`}
                    state={{ props: data }}
                    // style={{ display: "flex", gap: 10, alignItems: "center" }}
                    style={{ display: "block" }}
                  >
                    {/* <BiUser /> */}
                    Members
                  </Link>,
                  "Edit",
                ]}
              />
            </span>,
          ])
        );

        request.data.requests.map((data: any, index: number) =>
          requests.push([
            index + 1,
            <BusinessNameLogoWrapper data={data} open={false} />,
            `${data.email}`,
            `${data.category}`,
            // <span
            //   style={{
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "space-between",
            //   }}
            // >
            //   {new Date(data.createdAt).toDateString()}
            // </span>,
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: 10,
              }}
            >
              <GlobalButtonHighlight
                props={{
                  background: colors.green,
                  color: "white",
                  hover: colors.secondary,
                  hoverColor: "white",
                  // hoverBorder: colors.secondary
                }}
                onClick={() => updateRequest({ approve: true, id: data.id })}
              >
                <BiCheckDouble
                  // color={colors.red_dark}
                  style={{ cursor: "pointer" }}
                  size={18}
                />
                Approve
              </GlobalButtonHighlight>
              <GlobalButtonHighlight
                props={{
                  background: colors.red_dark,
                  color: "white",
                  hover: colors.secondary,
                  hoverColor: "white",
                  // hoverBorder: colors.secondary
                }}
                type="button"
                onClick={() => updateRequest({ approve: false, id: data.id })}
              >
                <IoCloseCircleOutline
                  // color={colors.red_dark}
                  style={{ cursor: "pointer" }}
                  size={18}
                />
                Reject
              </GlobalButtonHighlight>
            </div>,
          ])
        );

        setRequests(requests);
        setBusinesses(businesses);
        hideModal();
      }
    } catch (e) {
      addNotification({
        message: "An error occured while loading data.",
        type: 0,
      });
      hideModal();
    }
  };

  return (
    <>
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 20 }}
      >
        <BusinessTitleWrapper>
          <BusinessDesc>
            <Header2 style={{ marginBottom: 20, fontSize: 24 }}>
              Business Directory
            </Header2>
            <BodyText style={{ color: "grey", fontSize: 16 }}>
              Business directories allow you to categorize your prepared list so
              that you can easily sort and filter them all.
            </BodyText>
          </BusinessDesc>
          <Link to={`/workspace/business/add`}>
            <GlobalButton
              props={{
                background: colors.red_dark,
                color: "white",
                full: false,
                border: colors.red_dark,
                hoverBorder: colors.secondary,
                hover: colors.secondary,
                hoverColor: "white",
                // square:
              }}
              title="Home"
              style={{
                whiteSpace: "nowrap",
                alignItems: "center",
                display: "flex",
                gap: 5,
              }}
            >
              Add new
              <BiPlus color="white" size={20} />
            </GlobalButton>
          </Link>
        </BusinessTitleWrapper>
      </HomeCardWrap>
      {requests.length > 0 && (
        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{ marginBottom: 20 }}
        >
          <Table
            title="Request List"
            header={{
              name: "Request list",
              total: 4,
              labels: [
                "Rank",
                "Name",
                "email",
                "Category",
                // "Request Date",
                "Action",
              ],
            }}
            rows={requests}
          />
        </HomeCardWrap>
      )}
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 40 }}
      >
        <Table
          title="Businesses"
          header={{
            name: "Businesses",
            total: 4,
            labels: ["Rank", "Name", "Category", "Revenue", "Created"],
          }}
          search={true}
          rows={businesses}
          pagination={{
            rows: { total: businesses.length, current: 5 },
            pages: { total: 1, current: 1 },
          }}
        />
      </HomeCardWrap>
    </>
  );
};

export default AllBusiness;
