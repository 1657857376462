import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPromptAction } from "../utils/interface";

interface promptState {
  visible: boolean;
  dialog: {
    message: string;
    options: Array<IPromptAction>;
  };
}

const initialState: promptState = {
  visible: false,
  dialog: {
    message: "",
    options: [],
  },
};

export const promptSlice = createSlice({
  name: "prompt",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showPrompt: (
      state,
      action: PayloadAction<{
        message: string;
        options: Array<IPromptAction>;
      }>
    ) => {
      state.visible = true;
      state.dialog = {
        message: action.payload?.message || "",
        options: action.payload?.options || [],
      };
    },
    hidePrompt: (state) => {
      state.visible = false;
      state.dialog = {
        message: "",
        options: [],
      };
    },
  },
});

export const { showPrompt, hidePrompt } = promptSlice.actions;

export default promptSlice.reducer;
