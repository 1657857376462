import React from "react";
import { EServices, EServicesColor } from "../../../../../utils/interface";
import { BsCashCoin } from "react-icons/bs";
import { gray } from "../../../../../styles";
import { TbCashBanknote } from "react-icons/tb";
import { MdDeliveryDining } from "react-icons/md";
import { IoAnalyticsSharp } from "react-icons/io5";
import { colors } from "../../../../../utils/colors";

interface IActivityRow {
  type: EServices;
}

const ActivityRow = ({ type }: IActivityRow) => {
  const iconBackground = () => {
    const icon =
      type === EServices["Courier Service"] ? (
        <MdDeliveryDining color={colors.secondary} size={20} />
      ) : type === EServices["AI Analytics Service"] ? (
        <IoAnalyticsSharp
          color={EServicesColor["AI Analytics Service"]}
          size={20}
        />
      ) : (
        <TbCashBanknote color={EServicesColor["E-Payment Service"]} size={20} />
      );

    const backgorund =
      type === EServices["Courier Service"]
        ? "#FED095"
        : type === EServices["AI Analytics Service"]
        ? "#F2E7E6"
        : "#dbf0f9";

    const text =
      type === EServices["Courier Service"]
        ? "Delrivery made by"
        : type === EServices["AI Analytics Service"]
        ? "Analytics request made by"
        : "Payment transaction made to";

    return { icon, backgorund, text };
  };

  return (
    <div style={{ display: "flex", gap: 15 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            borderRadius: "50%",
            backgroundColor: iconBackground().backgorund,
            height: 45,
            width: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {iconBackground().icon}
        </span>
        <hr
          style={{
            width: 2,
            height: 15,
            backgroundColor: gray(0.2),
            border: "none",
          }}
        />
      </div>
      <div style={{}}>
        <span style={{ fontSize: 12, color: gray(0.5), fontWeight: 500 }}>
          9:30 AM, Sat 17 Jun 2023
        </span>
        <h4
          style={{
            fontWeight: 500,
            fontSize: 14,
            paddingTop: 5,
            margin: 0,
            color: "gray",
          }}
        >
          {iconBackground().text}{" "}
          <span style={{ fontWeight: 500, color: "black" }}>Tanko Adingu</span>{" "}
          by{" "}
          <span style={{ fontWeight: 500, color: "black" }}>
            Primacy Business
          </span>
        </h4>
      </div>
    </div>
  );
};

export default ActivityRow;
