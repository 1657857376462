import styled from "styled-components";
import { colors } from "../../utils/colors";
import { gray, transition } from "..";
import { Link } from "../index";

export const BusinessTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const BusinessDesc = styled.div`
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BusinessAddFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BusinessAddFormDiv = styled.span`
  width: 50%;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;
export const BusinessEditButtonWrapper = styled.button`
  width: max-content;
  padding: 5px 7px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: ${transition};

  :hover {
    background-color: rgba(121, 0, 0, 0.1);
    border: 1px solid ${colors.red_dark};
  }
`;

export const BusinessAvatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

export const BusinessProfileDataWrapLight = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border: 1px solid #b0b2c9;
  border-radius: 7px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
`;

interface ISubPageLink {
  props: {
    active: boolean;
  };
}

export const SubPageLink = styled(Link)<ISubPageLink>`
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
  border-bottom: ${({ props }) =>
    props.active ? `2.5px solid ${colors.red_dark}` : "none"};
  padding-bottom: 10px;
  color: ${({ props }) => (props.active ? colors.red_dark : gray(0.3))};
`;

export const BusinessServiceRowContainer = styled.div`
  display: flex;
  padding: 10px;
  transition: ${transition};
  gap: 10px;
  box-shadow: none;
  border-bottom: 0.5px solid ${gray(0.3)};
  flex-wrap: wrap;

  :hover {
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  }
`;
