import React, { useState } from "react";
import {
  TopNavigationContainer,
  TopNavProfileImg,
  TopNavProfileName,
} from "./styles";
import { colors } from "../../../utils/colors";
import { Link, } from "../../../styles";
import { AiOutlineSearch } from "react-icons/ai";
import { useAuth } from "../../../context/AuthProvider";
import { FaUserCircle } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";
import { PlainInput, SearchContainer } from "../../../styles/Global/input";

const TopNavigation = ({
  setShowProfile,
  showProfile,
}: {
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
  showProfile: boolean;
}) => {
  const { user } = useAuth();
  const { firstname, othernames, lastname, avatar } = user.user;
  const [profile, setProfile] = useState(avatar || null);

  return (
    <TopNavigationContainer>
      <SearchContainer style={{ marginRight: 5 }}>
        <AiOutlineSearch size={18} color="gray" style={{ marginRight: 5 }} />
        <PlainInput type={"text"} placeholder="search..." />
      </SearchContainer>
      <span style={{ flex: 1 }} />
      <Link to="/workspace/profile" title="Profile">
        {profile && avatar ? (
          <TopNavProfileImg src={avatar} onError={() => setProfile(null)} />
        ) : (
          <FaUserCircle size={40} color={colors.primary} />
        )}
      </Link>
      <TopNavProfileName>{`${lastname} ${
        othernames || ""
      } ${firstname}`}</TopNavProfileName>
      <Link
        to="/workspace/notifications"
        style={{
          margin: "0 20px 0 5%",
          position: "relative",
        }}
      >
        <IoMdNotificationsOutline size={25} color={colors.primary} />
        <span
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            right: -5,
            top: -5,
            backgroundColor: colors.red_dark,
            padding: 5,
            borderRadius: 10,
            color: "white",
            fontSize: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          8
        </span>
      </Link>
      <CgMenuRight
        size={25}
        color={colors.primary}
        style={{ cursor: "pointer" }}
        onClick={() => setShowProfile((e) => !e)}
      />
      {/* <MenuButton  onClick={() => setShowProfile((e) => !e)}>
        {showProfile ? (
          <BsArrowRightShort color={colors.primary} size={20} />
        ) : (
          <TbDotsVertical color={colors.primary} size={20} />
        )}
      </MenuButton> */}
    </TopNavigationContainer>
  );
};

export default TopNavigation;
