import React from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "../redux/modalReducer";
import { add } from "../redux/notificationPopupReducer";
import { IPromptAction } from "../utils/interface";
import { showPrompt } from "../redux/promptReducer";

interface dialog {
  header?: string;
  message: string;
  preventClose?: boolean;
  loading?: boolean;
  state?: number;
}

const useMessagePreview = () => {
  const dispatch = useDispatch();

  const showModal = ({
    header,
    message,
    preventClose,
    loading,
    state,
  }: dialog) => {
    const type = state === undefined || state === null ? 2 : state;
    dispatch(
      show({
        header: header || "Notification",
        message,
        preventClose,
        loading,
        state: type,
      })
    );
  };

  const hideModal = () => {
    dispatch(hide());
  };

  const addNotification = ({
    message,
    type,
    timer = 4,
  }: {
    message: string;
    type: number;
    timer?: number;
  }) => {
    dispatch(
      add({
        message,
        type,
        timer: timer * 1000,
      })
    );
  };

  const showModalPrompt = ({
    message,
    options,
  }: {
    message: string;
    options: Array<IPromptAction>;
  }) => {
    dispatch(
      showPrompt({
        message,
        options,
      })
    );
  };

  return {
    showModal,
    hideModal,
    addNotification,
    showModalPrompt,
  };
};

export default useMessagePreview;
