import React from "react";
import { FormDesc, Header3 } from "../../../../../styles/Global/text";
import CustomRadioButton from "../../../../Global/Forms/CustomRadioButton.component";
import { colors } from "../../../../../utils/colors";
import { transition } from "../../../../../styles";
import { GlobalInputText } from "../../../../../styles/Global/input";
import useMessagePreview from "../../../../../hooks/useMessagePreview";
import { IDeliveryFormData } from "../AddDelivery.component";
import {
  formatDate,
  isDateValidSchedule,
} from "../../../../../services/date.service";

const Step1 = ({
  formData,
  setFormData,
}: {
  formData: IDeliveryFormData;
  setFormData: React.Dispatch<React.SetStateAction<IDeliveryFormData>>;
}) => {
  const { addNotification } = useMessagePreview();

  const setSchedule = (type: "instant" | "scheduled", value: string) => {
    try {
      const valid = isDateValidSchedule(value);

      if (type === "scheduled" && new Date(value) <= new Date())
        return addNotification({
          message: `Date can't be in  the past, for date: ${formatDate(
            new Date(value)
          )}`,
          type: 0,
          timer: 10,
        });

      if (valid.status) setFormData((e) => ({ ...e, type, schedule: value }));
      else {
        setFormData((e) => ({ ...e, schedule: null }));
        addNotification({
          message: `Wrong date selected, ${
            valid?.message
          } for date: ${formatDate(new Date(value))}`,
          type: 0,
          timer: 10,
        });
      }
    } catch (e) {
      console.log("Schedule Error", e);
    }
  };

  return (
    <>
      <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
      ⏱️ Courier Delivery Service Type
      </Header3>
      <FormDesc style={{ marginBottom: 30 }}>
        This option gives the flexibility to choose delivery type being an
        option of instant or scheduled.
      </FormDesc>
      <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
        <CustomRadioButton
          props={{
            value: "instant",
            active: formData.type === "instant",
            description: "Schedule on form submit (Now)",
            label: "Instant Delivery",
          }}
          styles={{
            active: {
              background: "rgba(252, 163, 17, 0.2)",
              border: colors.yellow,
            },
          }}
          click={() => {
            setSchedule("instant", new Date().toISOString());
          }}
        />
        <CustomRadioButton
          props={{
            value: "scheduled",
            active: formData.type === "scheduled",
            description:
              "Schedule between now and the next 7 business working days.",
            label: "Scheduled Delivery",
          }}
          styles={{
            active: {
              background: "rgba(252, 163, 17, 0.2)",
              border: colors.yellow,
            },
          }}
          click={(type) =>
            setFormData((e) => ({ ...e, type: "scheduled", schedule: null }))
          }
        />
        <div
          style={{
            flex: 1,
            opacity: formData.type === "scheduled" ? 1 : 0,
            transition: transition,
          }}
        >
          <FormDesc style={{ margin: "0 0 10px 0" }}>
            Please select the scheduled date of delivery.{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>NB:</span> Must
            be a{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              business working day
            </span>{" "}
            and time of{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              8:00 AM - 5:00 PM.
            </span>
          </FormDesc>
          <GlobalInputText
            type="datetime-local"
            onChange={(time) => setSchedule("scheduled", time.target.value)}
            props={{
              square: true,
              background: "transparent",
              color: "black",
              full: false,
            }}
            style={{ marginBottom: 0 }}
          />
        </div>
      </div>
    </>
  );
};

export default Step1;
