import { useEffect, useState } from "react";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { remove } from "../../redux/notificationPopupReducer";
import { NotificationCardWrapper } from "../../styles/NotificationPopup.style";
import { colors } from "../../utils/colors";

interface INotificationCard {
  id: number;
  message: string;
  type: number;
  timer: number;
}

const NotificationCard = ({ id, message, type, timer }: INotificationCard) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
    setTimeout(() => {
      setVisible(false);
    }, timer - 500);
    setTimeout(() => {
      dispatch(remove(id));
    }, timer);
  }, []);

  return (
    <NotificationCardWrapper props={{ status: type, visible }}>
      {visible && <span style={{ flex: 1 }}>{message}</span>}
      <IoNotificationsCircleOutline
        color={type === 2 ? colors.secondary : "white"}
        size={20}
      />
    </NotificationCardWrapper>
  );
};

export default NotificationCard;
