import React from "react";
import ActivityRow from "./ActivityRow";
import { EServices } from "../../../../../utils/interface";

const Activity = () => {
  return (
    <div style={{paddingTop: 10}}>
      {/* <div>Activity</div> */}
      <ActivityRow type={EServices["Courier Service"]}/>
      <ActivityRow type={EServices["AI Analytics Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["Courier Service"]}/>
      <ActivityRow type={EServices["AI Analytics Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["Courier Service"]}/>
      <ActivityRow type={EServices["AI Analytics Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
      <ActivityRow type={EServices["E-Payment Service"]}/>
    </div>
  );
};

export default Activity;
