import styled from "styled-components";

export const GlobalContainer = styled.div`
  min-height: 100vh;
  background-color: white;
  padding: 3%;
  display: flex;
`;

export const FlexRowWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface IFlexRow {
  props?: {
    width: number;
  };
}

export const FlexRow = styled.div<IFlexRow>`
  width: ${({ props }) => `${props?.width}%`};

  @media (max-width: 768px) {
    width: 100%;
  }
`;