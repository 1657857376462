import React, { useState } from "react";
import Footer from "../../components/Global/Footer";
import { colors } from "../../utils/colors";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../context/AuthProvider";

import Reviews from "../../components/SignupSignIn/Reviews";
import { FormDesc, FormLabel, Header3 } from "../../styles/Global/text";
import FormResponse from "../../components/Global/FormResponse";
import { formHandler } from "../../services/formHandler.service";
import useMessagePreview from "../../hooks/useMessagePreview";
import { IFormResponse } from "../../utils/interface";
import useExternalAPI from "../../hooks/useExternalAPI";
import { GlobalContainer } from "../../styles/Global/container.style";
import { AccessDiv } from "../../styles/Access.style";
import { GlobalInputText } from "../../styles/Global/input";
import { GlobalButton } from "../../styles/Global/button";

const Signin = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { postData } = useExternalAPI();
  const { addNotification } = useMessagePreview();
  const [resend, setResend] = useState({ show: false, email: "" });
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 2,
  });

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (response) => {
      post({ token: response.code });
    },
    onError: (error) => {
      addNotification({
        message:
          error.error_description?.toString() ||
          "Error occured while trying to Google sign in.",
        type: 0,
      });
    },
  });

  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = formHandler(e);
    post(formData);
  };

  const post = async (e: any) => {
    setFormResponse({
      message: "signing you up.",
      visible: true,
      status: 2,
    });
    const response: any = await postData({
      url: "user/login",
      body: e,
    });

    if (response.status) {
      login(response.data);
      setFormResponse({
        message: response.message || formResponse.message,
        status: 1,
      });
    } else {
      setFormResponse({
        message: response.message || formResponse.message,
        status: 0,
      });
      if (response.resend && e.email) {
        setResend({ email: e.email, show: true });
      }
    }

    return { status: response.status, data: response.data };
  };

  return (
    <>
      <GlobalContainer style={{ padding: 0, maxHeight: "100vh" }}>
        <AccessDiv props={{ hide: false }}>
          <Header3 style={{ marginBottom: 0 }}>Welcome back</Header3>
          <FormDesc>Welcome back! Please enter your details.</FormDesc>
          <form
            onSubmit={(e) => loginUser(e)}
            style={{ height: "min-content" }}
          >
            <FormLabel htmlFor="email">Email</FormLabel>
            <GlobalInputText
              placeholder="Enter your email"
              type={"email"}
              id="email"
              props={{
                background: "white",
                color: colors.secondary,
                full: true,
                square: true,
                border: "grey",
              }}
              name="email"
              required
            />
            <FormLabel htmlFor="password">Password</FormLabel>
            <GlobalInputText
              placeholder="Enter your password"
              type={"password"}
              id="password"
              props={{
                background: "white",
                color: colors.secondary,
                full: true,
                square: true,
                border: "grey",
              }}
              name="password"
              required
            />
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "7px",
                marginBottom: 15,
              }}
            >
              <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                <FormLabel
                  style={{
                    cursor: "pointer",
                    margin: 0,
                  }}
                >
                  Forgot Password?
                </FormLabel>
              </Link>
            </span>
            <FormResponse
              message={formResponse.message}
              visible={formResponse.visible}
              status={formResponse.status}
            />
            {resend.show ? (
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: true,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                  square: true,
                }}
                style={{ marginTop: 17 }}
                title="Resend"
                onClick={() => navigate("/verification?email=" + resend.email)}
              >
                Resend Confirmation
              </GlobalButton>
            ) : null}
            <GlobalButton
              props={{
                background: colors.secondary,
                color: "white",
                full: true,
                hover: colors.red_dark,
                hoverColor: "white",
                hoverBorder: colors.red_dark,
                square: true,
              }}
              style={{ marginTop: 10 }}
              title="Sign up"
            >
              Sign in
            </GlobalButton>
          </form>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: true,
              border: "rgba(0,0,0,0.2)",
              hover: "rgba(0,0,0,0.2)",
              hoverColor: colors.secondary,
              hoverBorder: "rgba(0,0,0,0.2)",
              square: true,
            }}
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            title="Sign up"
            onClick={() => googleLogin()}
          >
            <FcGoogle size={22} style={{ marginRight: 5 }} />
            Sign in with Google
          </GlobalButton>

          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginTop: 15,
              fontSize: 12,
              marginBottom: 15,
              textAlign: "center"
            }}
          >
            Don't have a account?
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <FormLabel
                style={{
                  cursor: "pointer",
                  margin: 0,
                  marginLeft: 5,
                  textAlign: "center"
                }}
              >
                Sign up for free
              </FormLabel>
            </Link>
          </span>
        </AccessDiv>
        <AccessDiv props={{ hide: true }} style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/access1.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
          <Reviews
            message={`"I've been using CMS for months and I can't believe how much my business has grown in only a few months."`}
            name="Saladin Yitzhak"
            role="Head of Marketing, TAID"
            stars={4}
            institution="Groceries Supply"
          />
        </AccessDiv>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default Signin;
