import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Global/Footer";
import FormResponse from "../../components/Global/FormResponse";
import useExternalAPI from "../../hooks/useExternalAPI";
import useMessagePreview from "../../hooks/useMessagePreview";
import { FormDesc, Header3 } from "../../styles/Global/text";
import { colors } from "../../utils/colors";
import { IFormResponse } from "../../utils/interface";
import { GlobalContainer } from "../../styles/Global/container.style";
import { AccessDiv } from "../../styles/Access.style";
import { GlobalButton } from "../../styles/Global/button";

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [timer, setTimer] = useState(0);
  const { showModal } = useMessagePreview();
  const { postData } = useExternalAPI();
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 0,
  });
  const [email, setEmail] = useState("");

  useEffect(() => {
    const code = searchParams.get("code");
    const email = searchParams.get("email");
    if (code && email) {
      setEmail(email);
      setFormResponse({
        message: "verifying email",
        visible: true,
        status: 2,
      });
      initVerify(code);
    } else {
      if (!code && !email)
        showModal({
          state: 0,
          header: "Error verifying email.",
          message: "Please use referer code sent to your email.",
        });
      if (email) {
        setEmail(email);
        setResend(true);
      }
    }
  }, []);

  useEffect(() => {
    if (timer !== 0) {
      setTimeout(() => {
        console.log("Timer", timer);
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const initVerify = async (code: string) => {
    const request: any = await postData({
      url: "user/verifyAccount",
      body: { usercode: code },
    });

    if (request.status) {
      showModal({
        header: "Success",
        message: request.message,
        state: 1,
      });
      setFormResponse({
        message: request.message || formResponse.message,
        status: 1,
      });
      navigate("/signin");
    } else {
      setFormResponse({
        message: request.message || formResponse.message,
        status: 0,
      });
      if (request?.data?.resend) setResend(true);
    }
  };

  const resendCode = async () => {
    if (email.length < 1) {
      setFormResponse({
        message: "Error making request",
        status: 0,
      });
      return;
    }

    setFormResponse({
      message: "resending verification mail.",
      status: 2,
    });

    const request = await postData({
      url: "user/resendVerificationMail",
      body: { email },
    });
    if (request.status) {
      showModal({
        header: "Success",
        message: request.message,
        state: 1,
      });
      setTimer(120);
      setFormResponse({
        message: request.message || formResponse.message,
        status: 1,
      });
    } else {
      setFormResponse({
        message: request.message || formResponse.message,
        status: 0,
      });
      if (request?.data?.resend) setResend(true);
    }
  };

  return (
    <>
      <GlobalContainer style={{ padding: 0, maxHeight: "100vh" }}>
        <AccessDiv props={{ hide: false }}>
          <Header3 style={{ marginBottom: 0 }}>Email verification</Header3>
          <FormDesc>Auto verification initiated</FormDesc>
          <FormResponse
            message={formResponse.message}
            visible={formResponse.visible}
            status={formResponse.status}
          />
          {resend ? (
            timer === 0 ? (
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: true,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                  square: true,
                }}
                style={{ marginTop: 17 }}
                title="Resend"
                onClick={() =>
                  formResponse.status !== 2 ? resendCode() : null
                }
              >
                Resend Confirmation
              </GlobalButton>
            ) : (
              `Resend again in ${timer} seconds.`
            )
          ) : null}
        </AccessDiv>
        <AccessDiv props={{ hide: true }} style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/access2.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </AccessDiv>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default EmailVerification;
