import React from "react";

import "../../styles/progressbar.style.css";
import { transition } from "../../styles";

const ProgressBar = ({
  steps,
  active,
}: {
  steps: Array<string>;
  active: number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: 35,
        textAlign: "center",
        alignItems: "center",
        gap: 0,
      }}
    >
      {steps.map((data, index) => (
        <>
          <span
          key={index}
            style={{
              width: `${100 / steps.length}%`,
              padding: "10px 0 10px 10px",
              borderTop: index <= active ? `1px solid #BFBFBF` : "none",
              borderBottom: index <= active ? `1px solid #BFBFBF` : "none",
              borderLeft: index === 0 ? `1px solid #BFBFBF` : "none",
              color: index <= active ? "black" : "#BFBFBF",
              borderRadius: index === 0 ? "7px 0 0 7px" : 0,
              fontSize: 12,
              fontWeight: index <= active ? 600 : 500,
              transition: transition
            }}
          >
            {data}
          </span>
          {active === index && <i className="arrow right"></i>}
        </>
      ))}

      {/* <span
        style={{
          width: "25%",
          padding: "10px 0 10px 10px",
          borderTop: `1px solid #BFBFBF`,
          borderBottom: `1px solid #BFBFBF`,
          borderLeft: `1px solid #BFBFBF`,
          borderRadius: "7px 0 0 7px",
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        Transport type
      </span>

      <span
        style={{
          width: "25%",
          padding: "10px 0 10px 10px",
          borderTop: `1px solid #BFBFBF`,
          borderBottom: `1px solid #BFBFBF`,
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        Route
      </span>
      <i className="arrow right"></i>
      <span
        style={{
          width: "25%",
          fontSize: 14,
          fontWeight: 600,
          color: "#BFBFBF",
        }}
      >
        Details
      </span>
      <span
        style={{
          width: "25%",
          fontSize: 14,
          fontWeight: 600,
          color: "#BFBFBF",
        }}
      >
        Shipment
      </span> */}
    </div>
  );
};

export default ProgressBar;
