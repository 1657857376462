import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
  width: min-content;
`;

export const transition = "0.5s all ease-in-out";

export const GlobalLogo = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 5%;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
    margin: 10px 0;
  }
`;

export const GlobalLogoH1 = styled.h1`
  font-weight: bolder;
  margin-left: 10px;
`;

export const gray = (level: number) => `rgba(0,0,0,${level})`;
