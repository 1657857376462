import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Wrapper = styled.div`
  align-self: center;
  width: 100%;
`;

export const H1 = styled.h1`
  font-size: 24px;
  color: ${colors.secondary};
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
