import React from "react";
import { colors } from "../../../utils/colors";
import { Header3, Header4 } from "../../../styles/Global/text";
import { gray, transition } from "../../../styles";

interface ICustomRadioButton {
  styles: {
    active?: {
      background: string;
      border: string;
    };
  };
  props: {
    label: string;
    description: string;
    active?: boolean;
    value: string;
  };
  click: React.Dispatch<React.SetStateAction<any>>;
}

const CustomRadioButton = ({ styles, props, click }: ICustomRadioButton) => {
  return (
    <div
      style={{
        transition: transition,
        width: "max-content",
        maxWidth: 300,
        border: `2px solid ${props.active ? styles.active?.border : gray(0.2)}`,
        backgroundColor: props.active
          ? styles.active?.background
          : "transparent",
        borderRadius: 5,
        padding: "15px 20px",
        cursor: "pointer",
      }}
      onClick={() => click(props.value)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header4
          style={{ fontSize: 16, fontWeight: 500, margin: "0 20px 0 0" }}
        >
          {props.label}
        </Header4>
        <span
          style={{
            padding: props.active ? 5 : 10,
            border: `${props.active ? "6px" : "1px"} solid ${
              props.active ? styles.active?.border : "gray"
            }`,
            borderRadius: "50%",
            // transition: transition,
          }}
        ></span>
      </div>
      <p style={{ margin: "15px 0 0 0", fontSize: 12 }}>{props.description}</p>
    </div>
  );
};

export default CustomRadioButton;
