import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { scrollIntoViewWithOffset, scrollTotop } from "../../services/scroll.service";

const UseScrollToTop = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    scrollEffect();
  }, [pathname]);

  useEffect(() => {
    scrollEffect();
  }, [searchParams.get("products")]);

  const scrollEffect = () => {
    const section = searchParams.get("products");
    if (section) {
      scrollIntoViewWithOffset("#products", 90);
    } else {
      scrollTotop();
    }
  };

  return null;
};

export default UseScrollToTop;
