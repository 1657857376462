import React from "react";
import Lottie from "react-lottie";
import * as NotFound from "../../assets/animations/404.json";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { H1, Wrapper } from "./styles";

const NotFoundView = () => {
  const { pathname } = useLocation();
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Wrapper>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: NotFound,
        }}
        height={isBigScreen ? 450 : 250}
        width={isBigScreen ? 450 : 250}
      />
      <H1>{pathname}</H1>
      <p style={{ textAlign: "center" }}>
        Page doesn't exist or was removed, we suggest you go back home.
      </p>
    </Wrapper>
  );
};

export default NotFoundView
