import React from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "../redux/imagePreviewReducer";

const useImagePreview = () => {
  const dispatch = useDispatch();

  return {
    hide: () => dispatch(hide()),
    show: (images: Array<string>, active: number = 0) =>
      dispatch(show({ images, active })),
  };
};

export default useImagePreview;
