import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./modalReducer";
import notificationPopupReducer from "./notificationPopupReducer";
import imagePreviewReducer from "./imagePreviewReducer";
import promptReducer from "./promptReducer";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    prompt: promptReducer,
    notification: notificationPopupReducer,
    imagePreview: imagePreviewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
