import { useEffect, useRef, useState } from "react";
import { colors } from "../../../../../utils/colors";
import { useAuth } from "../../../../../context/AuthProvider";
import MemberProfileRow from "./MemberProfileRow";
import {
  GlobalInputTextRaw,
  SearchWrapper,
} from "../../../../../styles/Global/input";
import { AiOutlineSearch, AiOutlineUserAdd } from "react-icons/ai";
import { FormDesc, FormLabel } from "../../../../../styles/Global/text";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GlobalButtonHighlight } from "../../../../../styles/Global/button";
import AnimateHeight from "react-animate-height";
import { formHandler } from "../../../../../services/formHandler.service";
import useExternalAPI from "../../../../../hooks/useExternalAPI";
import useMessagePreview from "../../../../../hooks/useMessagePreview";
import {
  FlexRow,
  FlexRowWrapper,
} from "../../../../../styles/Global/container.style";
import { TbMailFast } from "react-icons/tb";

const Members = ({
  members,
  id,
  updateProfile,
}: {
  id: number | null;
  members: any;
  updateProfile: React.Dispatch<any>;
}) => {
  const addMmemberFormRef = useRef<HTMLFormElement | null>(null);
  const { user, navigate } = useAuth();
  const { postData } = useExternalAPI();
  const { addNotification, showModal, hideModal, showModalPrompt } =
    useMessagePreview();
  const [isAdmin, setIsAdmin] = useState(false);
  const [queryList, setQueryList] = useState(members);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [addMemberView, setaAddMemberView] = useState(false);

  useEffect(() => {
    try {
      const admin = members.find(
        (e: { role: number; user: { email: any } }) =>
          e.role === 2 && e.user.email === user.user.email
      );
      if (admin) setIsAdmin(admin ? true : false);
    } catch (e) {}
  }, []);

  useEffect(() => {
    setQueryList(members);
  }, [members]);

  useEffect(() => {
    try {
      if (searchQuery === "") setQueryList(members);
      else
        setQueryList(
          members.filter(
            (item: {
              user: { lastname: any; othernames: any; firstname: any };
            }) => {
              const regex = new RegExp(searchQuery, "i");
              return regex.test(
                `${item?.user?.lastname || ""} ${
                  item?.user?.othernames || ""
                } ${item?.user?.firstname || ""}`
              );
            }
          )
        );
    } catch (e) {
      console.error({ searchError: e });
    }
  }, [searchQuery]);

  const addUser = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const formData = formHandler(e);

      const request: any = await postData({
        url: "business/addMember",
        body: { email: formData.email, id },
      });

      const { message, status, data } = request;

      if (status) {
        updateProfile((e: any) => ({ ...e, members: data.members }));
        showModal({
          header: "Business member add",
          message: `${formData.email} has been successfully added to the business page`,
          state: 1,
        });
        clearAddMemberForm();
      } else {
        addNotification({
          message: message || "An error occured while adding user to gourp",
          type: 0,
        });
      }
    } catch (e) {}
  };

  const clearAddMemberForm = () => {
    try {
      if (addMmemberFormRef.current) {
        addMmemberFormRef.current.reset();
        setaAddMemberView(false);
      }
    } catch (e) {}
  };

  const removeUser = (data: any): boolean => {
    try {
      const newList = members.filter((e: any) => e.user !== data);
      updateProfile((e: any) => ({ ...e, members: newList }));
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateUser = (data: any, role: number): boolean => {
    try {
      updateProfile((prevState: any) => {
        const members = prevState?.members?.map((obj: any) => {
          console.log({ data, obj });
          if (obj.user.email === data.email) {
            console.log({ email: data.email, role });
            return { ...obj, role };
          }
          return obj;
        });
        return { ...prevState, members };
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  const exitGroup = async () => {
    try {
      showModal({
        loading: true,
        header: "Exitting group",
        message: "Please wait, processing goup exit",
        
      });

      const request: any = await postData({
        url: `business/exitBusiness?id=${id}`,
        get: true,
      });

      hideModal();
      const { message, status, data } = request;

      if (status) {
        updateProfile((e: any) => ({ ...e, members: data.members }));
        navigate("workspace/business");
      } else {
        addNotification({
          message: message || "An error occured while adding user to gourp",
          type: 0,
          timer: 5000
        });
      }
    } catch (e) {
      addNotification({
        message: "An error occured while exitting business",
        type: 0,
      });
    }
  };

  return members?.length > 0 ? (
    <>
      <FormDesc>This list contains members related to the business</FormDesc>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 10,
          alignItems: "center",
          margin: "10px 0 15px 0",
        }}
      >
        <SearchWrapper
          props={{ square: true }}
          style={{ width: searchQuery ? 400 : 250 }}
        >
          <AiOutlineSearch size={20} color="rgba(0,0,0,0.4)" />
          <GlobalInputTextRaw
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery || ""}
            placeholder={`search members...`}
          />
          {searchQuery !== "" && (
            <IoCloseCircleOutline
              style={{ cursor: "pointer" }}
              size={20}
              color="rgba(0,0,0,0.4)"
              onClick={() => setSearchQuery("")}
            />
          )}
        </SearchWrapper>
        <div style={{ display: "flex", flex: 1 }}>
          {!addMemberView && isAdmin && (
            <GlobalButtonHighlight
              props={{
                background: "white",
                // hoverBorder: colors.secondary
              }}
              style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              onClick={() => setaAddMemberView(true)}
            >
              <AiOutlineUserAdd
                // color={colors.red_dark}
                style={{ cursor: "pointer" }}
                size={18}
              />
              Add member
            </GlobalButtonHighlight>
          )}
          <GlobalButtonHighlight
            props={{
              background: "white",
              color: colors.red_dark,
              hover: colors.red_dark,
              hoverColor: "white",
            }}
            style={{ whiteSpace: "nowrap" }}
            onClick={() =>
              showModalPrompt({
                message:
                  "Are you sure you want to leave this business, you will be denied to it's page and related resources?",
                options: [
                  {
                    label: "yes",
                    color: "green",
                    action: () => exitGroup(),
                  },
                  { label: "No", color: colors.red_dark },
                ],
              })
            }
          >
            <AiOutlineUserAdd
              // color={colors.red_dark}
              style={{ cursor: "pointer" }}
              size={18}
            />
            Exit Page
          </GlobalButtonHighlight>
        </div>
      </div>
      {isAdmin && (
        <AnimateHeight height={addMemberView ? "auto" : 0}>
          <form onSubmit={(e) => addUser(e)} ref={addMmemberFormRef}>
            <div>
              <FormLabel
                style={{
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                Email
              </FormLabel>
              <FormDesc>
                Please input the email of the user you're adding.
              </FormDesc>
            </div>
            <FlexRowWrapper
              style={{
                alignItems: "center",
                gap: 10,
                marginTop: 20,
              }}
            >
              <FlexRow props={{ width: 70 }}>
                <SearchWrapper
                  props={{ square: true }}
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <TbMailFast size={15} color="rgba(0,0,0,0.4)" />
                  <GlobalInputTextRaw
                    name="email"
                    required
                    placeholder={`input email...`}
                  />
                </SearchWrapper>

                {/* <GlobalInputTextBottom
                  placeholder="example@gmail.com"
                  type={"email"}
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: colors.primary,
                  }}
                  style={{ flex: 1, margin: 0 }}
                  name="email"
                  required
                /> */}
              </FlexRow>
              <FlexRow
                props={{ width: 30 }}
                style={{ display: "flex", gap: 10 }}
              >
                <GlobalButtonHighlight
                  props={{
                    background: colors.green,
                    color: "white",
                    hover: colors.secondary,
                    hoverColor: "white",
                    // hoverBorder: colors.secondary
                  }}
                  style={{ height: 35, marginLeft: "auto" }}
                  type="submit"
                >
                  <AiOutlineUserAdd
                    // color={colors.red_dark}
                    style={{ cursor: "pointer" }}
                    size={20}
                  />
                  Add member
                </GlobalButtonHighlight>
                <GlobalButtonHighlight
                  props={{
                    background: colors.red_dark,
                    color: "white",
                    hover: colors.secondary,
                    hoverColor: "white",
                    // hoverBorder: colors.secondary
                  }}
                  type="button"
                  onClick={() => setaAddMemberView(false)}
                >
                  <IoCloseCircleOutline
                    // color={colors.red_dark}
                    style={{ cursor: "pointer" }}
                    size={20}
                  />
                  Cancel
                </GlobalButtonHighlight>
              </FlexRow>
              {/* <FlexRow
                props={{ width: 50 }}
                style={{
                  display: "flex",
                  // alignItems: "flex-end",
                  // justifyContent: "flex-end",
                  gap: 10,
                }}
              >
                <GlobalButtonHighlight
                  props={{
                    background: colors.green,
                    color: "white",
                    hover: colors.secondary,
                    hoverColor: "white",
                    // hoverBorder: colors.secondary
                  }}
                  style={{ marginLeft: "auto" }}
                  type="submit"
                >
                  <AiOutlineUserAdd
                    // color={colors.red_dark}
                    style={{ cursor: "pointer" }}
                    size={18}
                  />
                  Add member
                </GlobalButtonHighlight>
                <GlobalButtonHighlight
                  props={{
                    background: colors.red_dark,
                    color: "white",
                    hover: colors.secondary,
                    hoverColor: "white",
                    // hoverBorder: colors.secondary
                  }}
                  type="button"
                  onClick={() => setaAddMemberView(false)}
                >
                  <IoCloseCircleOutline
                    // color={colors.red_dark}
                    style={{ cursor: "pointer" }}
                    size={18}
                  />
                  Cancel
                </GlobalButtonHighlight>
              </FlexRow> */}
            </FlexRowWrapper>
          </form>
        </AnimateHeight>
      )}
      {queryList?.map(
        (data: any, index: number) =>
          data.role === 2 && (
            <MemberProfileRow
              id={id}
              role={data.role}
              data={data.user}
              key={data.user.email}
              admin={true}
              isAdmin={isAdmin}
              removeUser={removeUser}
              updateUser={updateUser}
            />
          )
      )}
      {queryList?.map(
        (data: any, index: number) =>
          data.role !== 2 && (
            <MemberProfileRow
              id={id}
              role={data.role}
              data={data.user}
              key={data.user.email}
              admin={false}
              isAdmin={isAdmin}
              removeUser={removeUser}
              updateUser={updateUser}
            />
          )
      )}
    </>
  ) : null;
};

export default Members;
