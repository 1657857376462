import React from "react";
import { Chart } from "react-google-charts";
import { colors } from "../../../utils/colors";

const LineGraph = () => {
  const data = [
    ["Year", "Sales", "Expenses"],
    ["November", 1000, 400],
    ["December", 1170, 460],
    ["January", 660, 1120],
    ["February", 1030, 540],
  ];

  const options = {
    title: "Brand Performance",
    curveType: "function",
    legend: { position: "bottom" },
    backgroundColor: "white",
    vAxis: {
      gridlines: { color: colors.accent_light },
    },
    colors: [colors.red_dark, colors.secondary],
  };

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="325px"
      data={data}
      options={options}
    />
  );
};

export default LineGraph;
