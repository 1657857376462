import { HomeCardWrap } from "../styles";
import {
  BusinessAddFormDiv,
  BusinessAddFormWrapper,
  BusinessDesc,
  BusinessTitleWrapper,
} from "../../../styles/WorkSpace/Business.style";
import {
  BodyText,
  FormDesc,
  FormLabel,
  Header2,
  Header3,
} from "../../../styles/Global/text";
import { colors } from "../../../utils/colors";
import { IoCloseCircle, IoCloseCircleOutline } from "react-icons/io5";
import { BiPlus } from "react-icons/bi";
import { useAuth } from "../../../context/AuthProvider";
import state from "../../../utils/state";
import useExternalAPI from "../../../hooks/useExternalAPI";
import { useEffect, useRef, useState } from "react";
import { formHandler } from "../../../services/formHandler.service";
import { IFormResponse } from "../../../utils/interface";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { FileUploader } from "react-drag-drop-files";
import { getbase64 } from "../../../services/base64.service";
import {
  GlobalInputSelectBottom,
  GlobalInputTextAreaBottom,
  GlobalInputTextBottom,
} from "../../../styles/Global/input";
import { GlobalButton } from "../../../styles/Global/button";
import Loader from "../../../components/Global/Loader";
import { gray } from "../../../styles";

const AddBusiness = () => {
  const baseUrl = "http://localhost:5050/setup";
  const { navigate } = useAuth();
  const { postData } = useExternalAPI();
  const [countries, setCountries] = useState<Array<any>>([]);
  const [countries2, setCountries2] = useState<Array<any>>([]);
  const [states, setStates] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [file, setFile] = useState<any>(null);
  const { showModal, addNotification } = useMessagePreview();
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 2,
  });

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length < 1) getCountries();
  }, [countries]);

  const getCountries = async () => {
    const response: any = await postData({
      baseUrl,
      url: "form/countries",
      get: true,
    });

    if (response.status) {
      setCountries(response?.data);
      setCountries2(response?.data);
    }
  };

  const getState = async ({
    name,
    countryList = countries,
  }: {
    name: string;
    countryList?: Array<any>;
  }) => {
    // console.log(name);
    const data = countryList.find((e) => e.name === name);
    if (data) {
      const response: any = await postData({
        baseUrl,
        url: "form/countries/?id=" + data.id,
        get: true,
      });

      if (response.status) setStates(response.data);
    }
  };

  const handleChange = async (file: any) => {
    try {
      const base64 = await getbase64(file);
      if (base64) setAvatar(base64);
    } catch (e) {}
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = formHandler(e);

    setLoading(true);

    const response: any = await postData({
      url: "business/register",
      body: {
        ...formData,
        avatar,
        contacts: {
          primary: {
            code: formData.primary_code,
            number: formData.primary,
          },
          secondary: {
            code: formData.secondary_code,
            number: formData.secondary,
          },
        },
        address: {
          street: formData.street,
          state: formData.state,
          country: formData.country,
        },
      },
    });

    if (response.status) {
      showModal({
        header: "Business Added",
        message: response.message,
        state: 1,
      });
      navigate(-1);
    } else {
      setFormResponse({
        message: response.message || formResponse.message,
        status: 0,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 20 }}
      >
        <BusinessTitleWrapper>
          <BusinessDesc style={{ width: "100%" }}>
            <Header2
              style={{
                marginBottom: 20,
                fontSize: 24,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Add Business{" "}
              <img
                style={{ height: 35, width: 35 }}
                src={require("../../../assets/images/icons/briefcase.png")}
              />
            </Header2>
            <BodyText style={{ color: "grey", fontSize: 16 }}>
              Add your business to our platform and expand your reach to new
              customers by creating a comprehensive online profile that
              showcases your products or services, business hours, location, and
              contact information.
            </BodyText>
          </BusinessDesc>
        </BusinessTitleWrapper>
      </HomeCardWrap>
      <form onSubmit={(e) => submit(e)}>
        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{ marginBottom: 20 }}
        >
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
                Business name
              </Header3>
              <FormDesc>
                The name of the company, this will be displayed to all users.
              </FormDesc>
            </BusinessAddFormDiv>

            <BusinessAddFormDiv>
              <FormLabel htmlFor="name">Name</FormLabel>
              <GlobalInputTextBottom
                placeholder="Enter your business name"
                type={"text"}
                id="name"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="name"
                required
              />
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
                Email address
              </Header3>
              <FormDesc>
                This will be your operational mail for your business, example
                receiving receipt report etc.
              </FormDesc>
            </BusinessAddFormDiv>

            <BusinessAddFormDiv>
              <FormLabel htmlFor="email">Email</FormLabel>
              <GlobalInputTextBottom
                placeholder="Enter your business email"
                type={"email"}
                id="email"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="email"
                required
              />
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
                Industry
              </Header3>
              <FormDesc>
                Select the category that best describes your business to help
                customers easily find and identify your products or services.
                Choose from a range of categories in the list.
              </FormDesc>
            </BusinessAddFormDiv>

            <BusinessAddFormDiv>
              <FormLabel htmlFor="category">Category</FormLabel>
              <GlobalInputSelectBottom
                placeholder="Select category"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="category"
                required={true}
              >
                <option disabled selected value="">
                  Select industry
                </option>
                {state.business_categories.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </GlobalInputSelectBottom>
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
                Call Contacts
              </Header3>
              <FormDesc>
                Call contacts of your business, this will be displayed publicly
                to all persons interacting with your business.
              </FormDesc>
            </BusinessAddFormDiv>
            <BusinessAddFormDiv>
              <FormLabel htmlFor="primary_code">Contacts</FormLabel>
              <FormDesc style={{ margin: "0px 0 5px 0" }}>Primary</FormDesc>
              <span
                style={{ display: "flex", alignItems: "flex-end", gap: 10 }}
              >
                <GlobalInputSelectBottom
                  placeholder="Select country"
                  props={{
                    background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                  }}
                  // ref={countryRef}
                  name="primary_code"
                  required
                >
                  <option disabled selected value="">
                    Select country code
                  </option>
                  {countries.map((data, index) => (
                    <option
                      key={index}
                      value={data?.phone_code}
                    >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
                  ))}
                </GlobalInputSelectBottom>
                <GlobalInputTextBottom
                  placeholder="Enter phone number"
                  type={"number"}
                  props={{
                    background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                  }}
                  name="primary"
                  required
                />
              </span>
              <FormDesc style={{ margin: "0px 0 5px 0" }}>Secondary</FormDesc>
              <span
                style={{ display: "flex", alignItems: "flex-end", gap: 10 }}
              >
                <GlobalInputSelectBottom
                  placeholder="Select country"
                  props={{
                    background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                  }}
                  // ref={countryRef}
                  name="secondary_code"
                >
                  <option disabled selected value="">
                    Select country code
                  </option>
                  {countries.map((data, index) => (
                    <option
                      key={index}
                      value={data?.phone_code}
                    >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
                  ))}
                </GlobalInputSelectBottom>
                <GlobalInputTextBottom
                  placeholder="Enter phone number"
                  type={"number"}
                  props={{
                    background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                  }}
                  name="secondary"
                />
              </span>
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
                Location
              </Header3>
              <FormDesc>
                Business physical location including country, district or state
                and street.
              </FormDesc>
            </BusinessAddFormDiv>

            <BusinessAddFormDiv>
              <FormLabel htmlFor="country">Address</FormLabel>
              <FormDesc style={{ margin: "10px 0 5px 0" }}>Country</FormDesc>
              <GlobalInputSelectBottom
                placeholder="Select country"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                // defaultValue={address?.country}
                onChange={(e) => getState({ name: e.target.value || "" })}
                id="country"
                name="country"
                required
              >
                <option disabled selected value="">
                  Select country
                </option>
                {countries2.map((data, index) => (
                  <option
                    key={index}
                    value={data?.name}
                  >{`${data?.name} ${data?.emoji}`}</option>
                ))}
              </GlobalInputSelectBottom>
              <FormDesc style={{ margin: "10px 0 5px 0" }}>State</FormDesc>
              <GlobalInputSelectBottom
                placeholder="Select country"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="state"
                required
              >
                {states.map((data, index) => (
                  <option key={index} value={data?.name}>
                    {data?.name}
                  </option>
                ))}
              </GlobalInputSelectBottom>
              <FormDesc style={{ margin: "0px 0 5px 0" }}>Street name</FormDesc>
              <GlobalInputTextBottom
                placeholder="Enter your street name"
                type={"text"}
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="street"
                required
              />
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>About</Header3>
              <FormDesc>
                Write your business decription stating the objective, goal and
                services. This will be helpful for users reviewing your business
                page.
              </FormDesc>
            </BusinessAddFormDiv>
            <BusinessAddFormDiv>
              <FormLabel htmlFor="description">Description</FormLabel>
              <GlobalInputTextAreaBottom
                placeholder="Enter your business description"
                rows={5}
                id="description"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="description"
                required
              />
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3 style={{ marginBottom: 0, fontSize: 20 }}>Logo</Header3>
              <FormDesc>
                This image is for your brand trademark. For best representation,
                upload business picture with ratio 1:1. Image size must be less
                than 5 MB.
              </FormDesc>
            </BusinessAddFormDiv>

            <BusinessAddFormDiv>
              <FileUploader
                multiple={false}
                onSelect={(e: any) => {
                  setFile(e);
                  handleChange(e);
                }}
                name="file"
                maxSize={5}
                types={state.fileTypes}
                fileOrFiles={file}
              />
              {avatar && (
                <div
                  style={{
                    position: "relative",
                    height: 150,
                    width: 150,
                    marginTop: 10,
                  }}
                >
                  <img
                    src={avatar}
                    style={{
                      height: 150,
                      width: 150,
                      border: `1px solid ${colors.accent_light}`,
                      objectFit: "cover",
                      borderRadius: 10,
                    }}
                  />
                  <IoCloseCircle
                    size={35}
                    onClick={() => {
                      setAvatar(null);
                      setFile(null);
                    }}
                    style={{
                      position: "absolute",
                      right: -10,
                      bottom: -10,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
        </HomeCardWrap>
        {/* <FormResponse
          message={formResponse.message}
          visible={loading}
          status={2}
        /> */}

        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{ flexDirection: "row", gap: 10, flexWrap: "wrap" }}
        >
          <BodyText
            style={{ color: "grey", fontSize: 16, marginRight: "auto" }}
          >
            Done, filling the form? Submit to proceed
          </BodyText>
          {!loading ? (
            <>
              <GlobalButton
                props={{
                  background: colors.red_dark,
                  color: "white",
                  full: false,
                  border: colors.red_dark,
                  hoverBorder: colors.secondary,
                  hover: colors.secondary,
                  hoverColor: "white",
                  // square:
                }}
                title="Home"
                type="button"
                style={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  gap: 5,
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
                <IoCloseCircleOutline color="white" size={20} />
              </GlobalButton>
              <GlobalButton
                props={{
                  background: colors.green,
                  color: "white",
                  full: false,
                  border: colors.green,
                  hoverBorder: colors.secondary,
                  hover: colors.secondary,
                  hoverColor: "white",
                  // square:
                }}
                title="Home"
                style={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  display: "flex",
                  gap: 5,
                }}
              >
                Submit
                <BiPlus color="white" size={20} />
              </GlobalButton>
            </>
          ) : (
            <Loader />
          )}
        </HomeCardWrap>
      </form>
    </>
  );
};

export default AddBusiness;
