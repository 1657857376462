import styled from "styled-components";
import { colors } from "../utils/colors";

export const FooterContainer = styled.footer`
  padding: 3%;
  background-color: ${colors.secondary};
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const FooterTitle = styled.h1`
  font-size: 18px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16xpx;
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    margin-right: 10px;
  }
`;

export const FooterAnchor = styled.a`
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;

  :hover {
    color: ${colors.accent};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;