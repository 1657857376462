import { useEffect, useState } from "react";
import { colors } from "../../utils/colors";
import { BodyText } from "../../styles/Global/text";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { ModalContainer, ModalWrapper } from "../../styles/Modal.style";
import { GlobalButton } from "../../styles/Global/button";
import { hidePrompt } from "../../redux/promptReducer";

const Prompt = () => {
  const [wrapper, setWrapper] = useState(false);
  const [container, setContainer] = useState(false);
  const { visible, dialog } = useSelector((state: RootState) => state.prompt);
  const { message, options } = dialog;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!visible) {
      setWrapper(visible);
      setTimeout(() => {
        setWrapper(visible);
        setContainer(visible);
      }, 100);
    } else {
      setContainer(visible);
      setTimeout(() => {
        setContainer(visible);
        setWrapper(visible);
      }, 100);
    }
  }, [visible]);

  return (
    <ModalContainer props={{ visible: container }} onClick={() => null}>
      <ModalWrapper
        props={{ visible: container, wrapper }}
        onClick={(e) => e.stopPropagation()}
      >
        <BodyText style={{ marginBottom: 30 }}>{message}</BodyText>
        <div style={{ display: "flex", gap: 10 }}>
          {options?.map((data, index) => (
            <GlobalButton
            key={index}
              props={{
                background: data.color,
                color: "white",
                full: false,
                border: data.color,
                hover: colors.accent_light,
                hoverColor: colors.secondary,
                hoverBorder: colors.accent,
                square: false,
              }}
              onClick={() => {
                if (data?.action) {
                  data?.action()
                }
                dispatch(hidePrompt())
              }}
            >
              {data.label}
            </GlobalButton>
          ))}
        </div>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default Prompt;
