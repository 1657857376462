import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Global/Footer";
import FormResponse from "../../components/Global/FormResponse";
import { IoIosArrowRoundBack } from "react-icons/io";
import validator from "validator";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { colors } from "../../utils/colors";
import { FormDesc, FormLabel, Header3 } from "../../styles/Global/text";
import useMessagePreview from "../../hooks/useMessagePreview";
import useExternalAPI from "../../hooks/useExternalAPI";
import { useSearchParams } from "react-router-dom";
import { IFormResponse } from "../../utils/interface";
import { formHandler } from "../../services/formHandler.service";
import { useAuth } from "../../context/AuthProvider";
import { GlobalContainer } from "../../styles/Global/container.style";
import { AccessDiv } from "../../styles/Access.style";
import { GlobalInputText } from "../../styles/Global/input";
import { GlobalButton } from "../../styles/Global/button";

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({ email: "", code: "" });
  const [stage, setStage] = useState(0);
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 0,
  });
  const {navigate} = useAuth()
  const { postData } = useExternalAPI();
  const swiperRef = useRef<any>();
  const { showModal } = useMessagePreview();

  useEffect(() => {
    const code = searchParams.get("code");
    const email = searchParams.get("email");

    if (code && email) {
      setData({ code, email });
      showModal({
        header: "Step 1 of 2",
        message:
          "Mail data queried, confirm email and click next to set new password.",
        state: 1,
      });
    } else {
      showModal({
        state: 0,
        header: "Error verifying email.",
        message: "Please use referer code sent to your email.",
      });
    }
  }, []);

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = formHandler(e);

    if (stage !== 1) {
      swiper(1);
      return;
    }

    if (formData.password !== formData.confirmpassword) {
      setFormResponse({
        message: "Password mismatch",
        status: 0,
      });

      return;
    }

    const passwordCheck: any = checkPassword(formData.password);
    if (!passwordCheck) {
      setFormResponse({
        message:
          "Password must be more than 6 characters, must contain numbers and symbols.",
        status: 0,
      });
      return;
    }

    return post(formData);
  };

  const post = async (e: Object) => {
    setFormResponse({
      message: "setting new password.",
      visible: true,
      status: 2,
    });

    const response: any = await postData({
      url: "user/resetpassword",
      body: e,
    });

    const { message, status } = response;

    if (status) {
      showModal({
        header: "Success",
        message: message,
        state: 1,
      });
      setFormResponse({
        message: message || formResponse.message,
        status: 1,
      });
      navigate("/signin")
    } else {
      setFormResponse({
        message: message || formResponse.message,
        status: 0,
      });
    }
  };

  const swiper = (index: number) => {
    setStage(index);
    swiperRef.current.slideTo(index);
  };

  const checkPassword = (e: string) => {
    if (e === "") return;
    // console.log("Data", e);
    const strong = validator.isStrongPassword(e, { minLength: 6 });

    setFormResponse({
      message: strong ? "Strong password" : "Weak password",
      visible: true,
      status: strong ? 1 : 0,
    });

    return strong;
  };

  return (
    <>
      <GlobalContainer style={{ padding: 0, maxHeight: "100vh" }}>
        <AccessDiv props={{ hide: false }}>
          <Header3 style={{ marginBottom: 0 }}>
            Reset Password? let's proceed
          </Header3>
          <FormDesc>Start by providing your account name or email</FormDesc>
          <form onSubmit={(e) => request(e)} style={{ height: "min-content" }}>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              allowTouchMove={false}
              noSwiping={true}
              autoHeight={true}
            >
              <SwiperSlide>
                <FormLabel>Email</FormLabel>
                <GlobalInputText
                  placeholder="Your email"
                  type={"email"}
                  name="email"
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  readOnly={true}
                  value={data.email}
                  required
                />
                <FormLabel>Code</FormLabel>
                <GlobalInputText
                  placeholder="Your generated code"
                  name="code"
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  readOnly={true}
                  value={data.code}
                  required
                />
              </SwiperSlide>
              <SwiperSlide>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "normal",
                    marginBottom: 15,
                    cursor: "pointer",
                    maxWidth: "min-content",
                  }}
                  onClick={() => {
                    swiper(0);
                    setStage(0);
                  }}
                >
                  <IoIosArrowRoundBack color={colors.secondary} size={20} />{" "}
                  cancel
                </span>
                <FormLabel>New password</FormLabel>
                <GlobalInputText
                  placeholder="Enter your password"
                  type={"password"}
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="password"
                  onChange={(e) => checkPassword(e.target.value)}
                  required={stage === 1}
                />
                <FormLabel>Confirm password</FormLabel>
                <GlobalInputText
                  placeholder="Enter your password"
                  type={"password"}
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="confirmpassword"
                  required={stage === 1}
                />
              </SwiperSlide>
            </Swiper>
            <FormResponse
              message={formResponse.message}
              visible={formResponse.visible}
              status={formResponse.status}
            />
            <GlobalButton
              props={{
                background: colors.secondary,
                color: "white",
                full: true,
                hover: colors.red_dark,
                hoverColor: "white",
                hoverBorder: colors.red_dark,
                square: true,
              }}
              style={{ marginTop: 17 }}
              title="Sign up"
              type="submit"
              // onClick={() => swiperRef.current.slideNext()}
            >
              Finish
            </GlobalButton>
          </form>
        </AccessDiv>
        <AccessDiv props={{ hide: true }} style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/access2.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </AccessDiv>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default PasswordReset;
