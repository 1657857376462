import { HiIdentification } from "react-icons/hi";
import { TbEdit } from "react-icons/tb";
import { HomeCardWrap } from "../../../pages/WorkSpace/styles";
import { CardTitle, FormDesc } from "../../../styles/Global/text";
import { EditSectionWrap } from "../../../styles/WorkSpace/Profile/Profile.style";
import { colors } from "../../../utils/colors";
import state from "../../../utils/state";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useState } from "react";
import Loader from "../../Global/Loader";
import { FcCancel } from "react-icons/fc";
import { formHandler } from "../../../services/formHandler.service";
import useExternalAPI from "../../../hooks/useExternalAPI";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { useAuth } from "../../../context/AuthProvider";
import { IdentificationImage } from "../../../styles/WorkSpace/EditProfile.style";
import useImagePreview from "../../../hooks/useImagePreview";
import { GlobalButton } from "../../../styles/Global/button";

const EditIdentification = () => {
  const { setUser, user } = useAuth();
  const { identification } = user.user;
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<{
    front: string | undefined;
    back: string | undefined;
  }>({
    front: identification?.front,
    back: identification?.back,
  });
  const { postData } = useExternalAPI();
  const { addNotification } = useMessagePreview();
  const {show} = useImagePreview()

  const handleChange = (file: any, front: boolean) => {
    getbase64(file, front);
  };

  const getbase64 = (file: any, front: boolean) => {
    try {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64String = reader.result;
        if (typeof base64String === "string")
          front
            ? setFiles((e) => ({ ...e, front: base64String }))
            : setFiles((e) => ({ ...e, back: base64String }));
      };
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!files.front || !files.back) {
      setLoading(false);
      addNotification({ message: "Select files before sunmit.", type: 0 });
      return;
    }

    const response: any = await postData({
      url: "user/updateDetails",
      body: { ...files, category: "identification" },
    });

    if (response.status) {
      const { identification } = response.data;
      setEdit(false);
      addNotification({ message: "About changes successful", type: 1 });
      setUser({
        ...user,
        user: {
          ...user.user,
          identification,
        },
      });
      setFiles(identification);
    } else addNotification({ message: response?.message, type: 0 });
    setLoading(false);
  };

  const showImages = (active = 0) => {
    const images = []
    if(files.front)images.push(files.front)
    if(files.back)images.push(files.back)  
    show(images, active)
  }

  return (
    <HomeCardWrap
      props={{ width: 49, widthMobile: 100 }}
      style={{ alignItems: "flex-start", marginTop: 20, }}
    >
      <EditSectionWrap>
        <CardTitle className="profile_section_left">
          <span className="profile_title">
            <HiIdentification
              size={25}
              color={"#138B32"}
              style={{ marginRight: 5 }}
            />
            Identification
          </span>{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() => setEdit((e) => !e)}
          >
            {loading ? (
              <Loader />
            ) : !edit ? (
              <TbEdit
                color={"#138B32"}
                style={{ cursor: "pointer" }}
                size={18}
                title="Edit"
              />
            ) : (
              <FcCancel size={18} />
            )}
          </span>
        </CardTitle>
        <form
          onSubmit={(e) => !loading ? onSubmit(e) : e.preventDefault()}
          style={{
            height: "min-content",
            display: "flex",
            flexDirection: "column",
            // width: "70%",
            flex: 1,
            justifyContent: "flex-start",
          }}
        >
          <FormDesc>
            View or upload front and back of your national identity card
          </FormDesc>
          <FormDesc style={{ margin: "0px 0 5px 0", fontWeight: "600" }}>
            Front View
          </FormDesc>
          {edit && (
            <div style={{ margin: "10px 0" }}>
              <FileUploader
                multiple={false}
                handleChange={(e: any) => handleChange(e, true)}
                name="file"
                maxSize={5}
                types={state.fileTypes}
              />
            </div>
          )}
          {!files.front ? (
            <span style={{ color: colors.red_dark, fontWeight: 500 }}>
              No file uploads
            </span>
          ) : (
            <IdentificationImage src={files.front} onClick={()=> showImages()}/>
          )}
          <FormDesc style={{ margin: "20px 0 5px 0", fontWeight: "600" }}>
            Back View
          </FormDesc>
          {edit && (
            <div style={{ margin: "10px 0" }}>
              <FileUploader
                multiple={false}
                handleChange={(e: any) => handleChange(e, false)}
                name="file"
                maxSize={5}
                types={state.fileTypes}
              />
            </div>
          )}
          {!files.back ? (
            <span style={{ color: colors.red_dark, fontWeight: 500 }}>
              No file uploads
            </span>
          ) : (
            <IdentificationImage src={files.back} onClick={()=> showImages(1)}/>
          )}
          {edit && (
            <GlobalButton
              props={{
                background: colors.accent_light,
                color: colors.secondary,
                full: false,
                hover: "#138B32",
                hoverColor: "white",
                hoverBorder: "#138B32",
                square: true,
              }}
              style={{ marginTop: 10 }}
              title="Sign up"
            >
              Save changes
            </GlobalButton>
          )}
        </form>
      </EditSectionWrap>
    </HomeCardWrap>
  );
};

export default EditIdentification;
