import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface IImagePreviewState {
  images: Array<string>;
  visible: boolean;
  active: number;
}

// Define the initial state using that type
const initialState: IImagePreviewState = {
  images: [],
  visible: false,
  active: 0,
};

export const imagePreviewSlice = createSlice({
  name: "imagePreview",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    show: (
      state,
      action: PayloadAction<{
        images: Array<string>;
        active: number;
      }>
    ) => {
      state.visible = true;
      state.images = action.payload.images;
      state.active = action.payload.active;
    },
    hide: (state) => {
      state.visible = false;
      state.images = [];
      state.active = 0;
    },
  },
});

export const { show, hide } = imagePreviewSlice.actions;

export default imagePreviewSlice.reducer;
