import Footer from "../../components/Global/Footer";
import Navigations from "../../components/Global/Navigations";
import { IndexHeroContainer, IndexHeroDiv } from "../../styles/Home.style";
import { BodyText, Header2, Header4 } from "../../styles/Global/text";
import { FlexRow, FlexRowWrapper, GlobalContainer } from "../../styles/Global/container.style";

const About = () => {
  return (
    <>
      <Navigations />
      <GlobalContainer
        style={{ flexDirection: "column", padding: 0, paddingTop: 100 }}
      >
        <IndexHeroContainer
          props={{ direction: "right", background: "transparent" }}
        >
          <FlexRowWrapper>
            <FlexRow props={{ width: 50 }}>
              <IndexHeroDiv
                style={{
                  alignSelf: "flex-end",
                  marginBottom: 0,
                }}
              >
                <Header2 style={{ marginBottom: 0 }}>
                  We build bridges between{" "}
                  <span style={{ color: "gray" }}>companies and customers</span>
                </Header2>
              </IndexHeroDiv>
            </FlexRow>
            <FlexRow
              props={{ width: 50 }}
              style={{ alignSelf: "flex-end", paddingTop: 20 }}
            >
              <BodyText style={{ color: "grey" }}>
                To develop software that enables small- and medium-sized
                businesses' customer-facing personnel to forge successful
                relationships with clients. This technology offers businesses a
                customizable solution that necessitates minimal interaction
                between workers at a workspace.
              </BodyText>
            </FlexRow>
          </FlexRowWrapper>
          <img
            style={{
              width: "108%",
              height: "cover",
              margin: "60px -4% 60px -4%",
            }}
            src={require("../../assets/images/team.png")}
          />
          <FlexRowWrapper>
            <FlexRow props={{ width: 50 }}>
              <IndexHeroDiv
                style={{
                  alignSelf: "flex-end",
                  marginBottom: 0,
                }}
              >
                <Header2 style={{ marginBottom: 0 }}>
                  Together we're strong
                </Header2>
              </IndexHeroDiv>
            </FlexRow>
            <FlexRow
              props={{ width: 50 }}
              style={{ alignSelf: "flex-end", paddingTop: 20 }}
            >
              <BodyText
                style={{
                  fontWeight: "bold",
                  marginBottom: 20,
                }}
              >
                Our team is always expanding, but we all strive toward the same
                objective: making sales success for businesses everywhere not
                only feasible.
              </BodyText>
              <BodyText
                style={{
                  color: "grey",
                }}
              >
                In the year 2022, our founder Mr. Bekoe Kojo came up with the
                brilliant notion of automating corporate environments
                (contactless management system). He then started this business
                logic in his capacity as a software engineer, and everything
                else went off without a hitch.
              </BodyText>
            </FlexRow>
          </FlexRowWrapper>
          <FlexRowWrapper style={{ marginTop: 80 }}>
            <FlexRow props={{ width: 50 }}>
              <IndexHeroDiv
                style={{
                  alignSelf: "flex-end",
                  marginBottom: 0,
                  padding: 0
                }}
              >
                <Header4 style={{ marginTop: 0 }}>WHO THE FOUNDER IS?</Header4>
              </IndexHeroDiv>
            </FlexRow>
            <FlexRow
              props={{ width: 50 }}
              style={{ alignSelf: "flex-end", paddingTop: 20 }}
            >
              <BodyText
                style={{
                  color: "grey",
                }}
              >
                <img
                  style={{
                    width: 140,
                    height: "auto",
                    float: "left",
                    margin: "0 10px 0px 0",
                    borderRadius: "10px 10px 0 10px",
                  }}
                  src={require("../../assets/images/founder.jpg")}
                />
                <strong style={{ color: "black", fontSize: 20 }}>
                  Mr. Bekoe Kojo Isaac
                </strong>{" "}
                <br />
                holds a bachelor's degree in software engineering from Coventry
                University, he began his engineering career as a freelancer with
                Duaf International in Ghana. <br />
                <br />
                He also contributed to a great deal of desktop, mobile, and
                online application projects. He has had the honor of working
                with both foreign organizations, such as Really Great Tech,
                Tel-Aviv, Israel, and government institutions, such as Ghana's
                Ministry of Communications.
              </BodyText>
            </FlexRow>
          </FlexRowWrapper>
        </IndexHeroContainer>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default About;
