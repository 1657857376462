import React, { useEffect, useState } from "react";
import {
  Container,
  Hide,
  HideButton,
  LogoutButton,
  SectionLabel,
  SectionValue,
} from "./style";
import { BsArrowRightShort } from "react-icons/bs";
import { useAuth } from "../../../context/AuthProvider";
import SectionRow from "./SectionRow";
import { AiOutlinePoweroff } from "react-icons/ai";
import useLogout from "../../../hooks/useLogout";
import ChangePhoto from "../ChangePhoto";
import { useLocation } from "react-router-dom";

const SideProfile = ({
  showProfile,
  setShowProfile,
}: {
  showProfile: boolean;
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { logout } = useLogout();
  const {
    firstname,
    othernames,
    lastname,
    avatar,
    email,
    contacts,
    address,
    dob,
    identification,
  } = user.user;

  useEffect(() => {
    if (showProfile) setShowProfile(false);
  }, [pathname]);

  return (
    <>
      <Hide
        props={{ show: showProfile }}
        onClick={() => setShowProfile(false)}
      />
      <Container props={{ show: showProfile }}>
        {showProfile && (
          <>
            <HideButton onClick={() => setShowProfile(false)}>
              Hide <BsArrowRightShort size={20} />
            </HideButton>
            <ChangePhoto />
            <div style={{ marginTop: 20, fontWeight: "600", fontSize: 18 }}>
              {lastname + " " + othernames + " " + firstname}
            </div>
            <SectionRow title="Personal details" edit={true}>
              <SectionLabel>
                Email <SectionValue>{email}</SectionValue>
              </SectionLabel>
              <SectionLabel>
                Contacts{" "}
                <SectionValue>
                  {contacts
                    ? `+${contacts?.primary?.code || "N/A"}${
                        contacts?.primary?.number || "N/A"
                      }, +${contacts?.secondary?.code || "N/A"}${
                        contacts?.secondary?.number || "N/A"
                      }`
                    : "N/A"}
                </SectionValue>
              </SectionLabel>
              <SectionLabel>
                Address{" "}
                <SectionValue>
                  {address
                    ? `${address?.street} - ${address?.state}, ${address?.country}`
                    : "N/A"}
                </SectionValue>
              </SectionLabel>
              <SectionLabel>
                Date of Birth <SectionValue>{dob || "N/A"}</SectionValue>
              </SectionLabel>
              <SectionLabel>
                Identification{" "}
                <SectionValue>
                  {identification?.front ? "Submitted" : "N/A"}
                </SectionValue>
              </SectionLabel>
            </SectionRow>
            <SectionRow title="Businesses (0)" edit={false}></SectionRow>
            <SectionRow title="Services (0)" edit={false}></SectionRow>
            <LogoutButton onClick={() => logout()}>
              Log out <AiOutlinePoweroff size={20} />
            </LogoutButton>
          </>
        )}
      </Container>
    </>
  );
};

export default SideProfile;
