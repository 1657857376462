import { Routes, Route, Link } from "react-router-dom";

import About from "./pages/About";
import Index from "./pages/Index";
import Notfound from "./pages/Notfound";
import Pricing from "./pages/Pricing";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import UseScrollToTop from "./components/Global/UseScrollToTop";
import PasswordReset from "./pages/PasswordReset";
import Workspace from "./pages/WorkSpace";
import Modal from "./components/Global/Modal";
import NotificationPopup from "./components/Global/NotificationPopup";
import { GoogleOAuthProvider } from "@react-oauth/google";
import EmailVerification from "./pages/EmailVerification";
import { RedirectRoute } from "./routes/RedirectRoute";
import ImagePreviewModal from "./components/Global/ImagePreviewModal";
import RedirectRouteGlobal from "./routes/RedirectRouteGlobal";
import ForgotPassword from "./pages/ForgotPassword";
import Prompt from "./components/Global/Prompt";
import Delivery from "./pages/Delivery";

import "./App.css";


function App() {
  

  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/signin"
          element={
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_OAUTH_CLIENT_ID?.toString() || ""}
            >
              <Signin />
            </GoogleOAuthProvider>
          }
        />
        <Route
          path="/signup"
          element={
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_OAUTH_CLIENT_ID?.toString() || ""}
            >
              <Signup />
            </GoogleOAuthProvider>
          }
        />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/verification" element={<EmailVerification />} />
        <Route
          path="/workspace/*"
          element={
            <RedirectRoute>
              <Workspace />
            </RedirectRoute>
          }
        />
        <Route path="*" element={<Notfound />} />
      </Routes>
      <RedirectRouteGlobal />
      <UseScrollToTop />
      <Modal />
      <Prompt />
      <NotificationPopup />
      <ImagePreviewModal />
    </>
  );
}

export default App;
