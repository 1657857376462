import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

// Define a type for the slice state
interface modalState {
  visible: boolean;
  dialog: {
    header: string;
    message: string;
    state?: number;
    preventClose?: boolean;
    loading?: boolean;
  };
}

// Define the initial state using that type
const initialState: modalState = {
  visible: false,
  dialog: { header: "", message: "" },
};

export const modalSlice = createSlice({
  name: "modal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    show: (
      state,
      action: PayloadAction<{
        header: string;
        message: string;
        state?: number;
        preventClose?: boolean;
        loading?: boolean;
      }>
    ) => {
      state.visible = true;
      state.dialog = action.payload;
    },
    hide: (state) => {
      state.visible = false;
    },
  },
});

export const { show, hide } = modalSlice.actions;

export default modalSlice.reducer;
