import styled from "styled-components";
import { transition } from "../../../styles";
import { colors } from "../../../utils/colors";

export const TopNavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  padding: 20px 4% 20px 7%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const TopNavProfileImg = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  background-color: gray;

  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
    max-height: 35px;
    max-width: 35px;
  }
`;

export const TopNavProfileName = styled.span`
  /* font-weight: bold; */
  margin-left: 10px;
  text-transform: capitalize;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 2px;
  width: 25;
  height: 25;
  margin-left: 10px;
  cursor: pointer;
  transition: ${transition};

  :hover{
    border-color: ${colors.primary};
  }
`;
