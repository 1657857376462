import { useEffect, useState } from "react";
import { ArrowWrapper, TableContainer, TableTH } from "./styles";
import { AiOutlineSearch } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CardTitle } from "../../styles/Global/text";
import { GlobalInputTextRaw, SearchWrapper } from "../../styles/Global/input";

interface ITable {
  header: {
    name: string;
    total: number;
    labels: Array<string>;
  };
  queryName?: string;
  search?: boolean;
  title?: string;
  rows?: Array<Array<any>>;
  pagination?: {
    rows: {
      total: number;
      current: number;
    };
    pages: {
      total: number;
      current: number;
    };
  };
}

const Table = ({ header, rows, title, search, pagination }: ITable) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [query, setQuery] = useState<any[][] | undefined>(rows);

  useEffect(() => {
    // console.log({ rows });
    setQuery(rows);
  }, [rows]);

  const searchRows = (query: string) => {
    setSearchQuery(query);
    query === ""
      ? setQuery(rows)
      : setQuery((e) =>
          e
            ? e.filter((data: any) =>
                data?.name?.toLowerCase().includes(query?.toLowerCase())
              )
            : e
        );
  };

  const setIndex = (data: any, index: number) => {
    try {
      return JSON?.stringify(data);
    } catch (e) {
      return index;
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: search ? 40 : 0,
          flexWrap: "wrap",
        }}
      >
        {title && <CardTitle>{title}</CardTitle>}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            fontWeight: "bold",
            fontSize: 12,
            flexWrap: "wrap",
          }}
        >
          {pagination && (
            <span>
              1 - {pagination.rows.current}{" "}
              <span style={{ fontWeight: "normal" }}>of</span> {rows?.length}
              <ArrowWrapper
                title="previous"
                style={{ margin: "0px 10px 0px 30px" }}
              >
                <FaChevronLeft />
              </ArrowWrapper>
              <ArrowWrapper title="next" style={{ margin: "0px 10px 0px 5px" }}>
                <FaChevronRight />
              </ArrowWrapper>
            </span>
          )}
          {search && (
            <SearchWrapper props={{ square: true }}>
              <AiOutlineSearch size={20} color="rgba(0,0,0,0.4)" />
              <GlobalInputTextRaw
                onChange={(e) => searchRows(e.target.value)}
                value={searchQuery}
                placeholder={`search ${title?.toLocaleLowerCase() || ""}...`}
              />
              {searchQuery !== "" && (
                <IoCloseCircleOutline
                  style={{ cursor: "pointer" }}
                  size={20}
                  color="rgba(0,0,0,0.4)"
                  onClick={() => searchRows("")}
                />
              )}
            </SearchWrapper>
          )}
        </span>
      </div>
      {rows ? (
        query?.length === 0 ? (
          <span style={{ alignSelf: "flex-start" }}>
            {`No ${title} data to present.`}
          </span>
        ) : (
          <div style={{ width: "100%", overflowX: "auto" }}>
            <TableContainer>
              <TableTH>
                <tr style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}>
                  {header.labels.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </TableTH>
              <tbody style={{ tableLayout: "auto" }}>
                {rows &&
                  query?.map((data, index) => (
                    <tr key={setIndex(data, index)}>
                      {data.map((e, index) => (
                        <td key={index} style={{ fontSize: 13 }}>
                          {e}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </TableContainer>
          </div>
        )
      ) : (
        <span style={{ alignSelf: "flex-start" }}>
          {`No ${title} data to present.`}
        </span>
      )}
    </>
  );
};

export default Table;
