import React, { useRef, useState } from "react";
import Footer from "../../components/Global/Footer";
import FormResponse from "../../components/Global/FormResponse";
import "swiper/css";
import { colors } from "../../utils/colors";
import { FormDesc, FormLabel, Header3 } from "../../styles/Global/text";
import useMessagePreview from "../../hooks/useMessagePreview";
import useExternalAPI from "../../hooks/useExternalAPI";
import { IFormResponse } from "../../utils/interface";
import { formHandler } from "../../services/formHandler.service";
import { GlobalContainer } from "../../styles/Global/container.style";
import { AccessDiv } from "../../styles/Access.style";
import { GlobalInputText } from "../../styles/Global/input";
import { GlobalButton } from "../../styles/Global/button";

const ForgotPassword = () => {
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 0,
  });
  const [complete, setComplete] = useState(false);
  const { postData } = useExternalAPI();
  const swiperRef = useRef<any>();
  const { showModal, addNotification } = useMessagePreview();

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (complete) return null;

    const formData = formHandler(e);

    return post(formData);
  };

  const post = async (e: Object) => {
    setFormResponse({
      message: "setting new password.",
      visible: true,
      status: 2,
    });

    const response: any = await postData({
      url: "user/forgetpassword",
      body: e,
    });

    const { message, status } = response;

    if (status) {
      showModal({
        header: "Success",
        message: message,
        state: 1,
      });
      setFormResponse({
        message: message || formResponse.message,
        status: 1,
      });
      setComplete(true);
    } else {
      setFormResponse({
        message: message || formResponse.message,
        status: 0,
      });
    }
  };

  return (
    <>
      <GlobalContainer style={{ padding: 0, maxHeight: "100vh" }}>
        <AccessDiv props={{ hide: false }}>
          <Header3 style={{ marginBottom: 0 }}>
            Forgot password? let's help you
          </Header3>
          <FormDesc>Start by providing your account name or email</FormDesc>
          <form onSubmit={(e) => request(e)} style={{ height: "min-content" }}>
            <FormLabel>Email</FormLabel>
            <GlobalInputText
              placeholder="Your email"
              type={"email"}
              name="email"
              props={{
                background: "white",
                color: colors.secondary,
                full: true,
                square: true,
                border: "grey",
              }}
              required
            />
            <FormResponse
              message={formResponse.message}
              visible={formResponse.visible}
              status={formResponse.status}
            />
            {!complete && (
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: true,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                  square: true,
                }}
                style={{ marginTop: 17 }}
                title="Sign up"
                type="submit"
                // onClick={() => swiperRef.current.slideNext()}
              >
                Finish
              </GlobalButton>
            )}
          </form>
        </AccessDiv>
        <AccessDiv props={{ hide: true }} style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/access2.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </AccessDiv>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default ForgotPassword;
