import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface notificationPopupState {
  list: { id: number; message: string; type: number; timer: number }[];
}

// Define the initial state using that type
const initialState: notificationPopupState = {
  list: [],
};

export const notificationPopupSlice = createSlice({
  name: "modal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    add: (
      state,
      action: PayloadAction<{
        message: string;
        type: number;
        timer: number;
      }>
    ) => {
      const { message, type, timer } = action.payload;
      const data = {
        id: Date.now(),
        message,
        type,
        timer: timer || 3000,
      };
      state.list.push(data);
    },
    remove: (state, action: PayloadAction<number>) => {
      // console.log("Remove: ", action.payload);
      const newlist = state.list.filter((e) => e.id !== action.payload);
      state.list = newlist;
      // console.log("List: ", state.list);
    },
  },
});

export const { add, remove } = notificationPopupSlice.actions;

export default notificationPopupSlice.reducer;
