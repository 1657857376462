import React, { useState } from "react";
import { MdOutlinePhotoFilter } from "react-icons/md";
import { TbPhotoOff } from "react-icons/tb";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useAuth } from "../../../context/AuthProvider";
import useExternalAPI from "../../../hooks/useExternalAPI";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { colors } from "../../../utils/colors";
import { IFormResponse } from "../../../utils/interface";
import FormResponse from "../../Global/FormResponse";
import { getBase64FileSize } from "../../../services/base64.service";
import { GlobalButton } from "../../../styles/Global/button";

interface ImageCropProps {
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<null>>;
  setProfile: React.Dispatch<any>;
}

const ImageCrop: React.FC<ImageCropProps> = ({ src, setSrc, setProfile }) => {
  const { setUser, user } = useAuth();
  const [crop, setCrop] = useState<Crop>({
    x: 1,
    y: 1,
    width: 100,
    height: 100,
    unit: "px",
    aspect: 1,
  });
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const { postData } = useExternalAPI();
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 2,
  });
  const { addNotification } = useMessagePreview();

  const handleCropComplete = async () => {
    // console.log({ crop });
    setFormResponse({
      message: "updating profile photo.",
      visible: true,
      status: 2,
    });
    if (!imageRef || !crop.width || !crop.height) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx: any = canvas.getContext("2d");
    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const croppedImageUrl = canvas.toDataURL("image/jpeg");

    const fileSizeInBytes = croppedImageUrl.length;
    const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
    if (parseFloat(fileSizeInMB) > 5) {
      addNotification({
        message: "File size must not be more than 5 MB",
        type: 0,
      });
      setFormResponse({ message: "", visible: false, status: 2 });
      return;
    }

    const response: any = await postData({
      url: "user/updateAvatar",
      body: { avatar: croppedImageUrl },
    });
    if (response.status) {
      setProfile(croppedImageUrl);
      setSrc(null);
      addNotification({ message: "Photo update successful", type: 1 });
      setUser({
        ...user,
        user: { ...user.user, avatar: response.data.avatar },
      });
    } else {
      setFormResponse({
        message: response.message || formResponse.message,
        status: 0,
      });
    }
  };

  return (
    <>
      <ReactCrop
        src={src}
        crop={crop}
        style={{ borderRadius: 10, marginBottom: 10 }}
        onChange={(e) => setCrop(e)}
        // onComplete={(c) => handleCropComplete(c)}
        onImageLoaded={(e) => setImageRef(e)}
      />
      {formResponse.message !== "" && (
        <FormResponse
          message={formResponse.message}
          visible={formResponse.visible}
          status={formResponse.status}
        />
      )}

      {formResponse.status === 2 && (
        <div style={{ display: "flex", gap: 10 }}>
          <GlobalButton
            props={{
              background: colors.secondary,
              color: "white",
              full: true,
              hover: colors.green,
              hoverColor: "white",
              hoverBorder: colors.green,
              square: true,
            }}
            style={{
              marginTop: 17,
              gap: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            title="Change Photo"
            onClick={() => handleCropComplete()}
          >
            <MdOutlinePhotoFilter size={20} />
            Save
          </GlobalButton>
          <GlobalButton
            props={{
              background: colors.red_dark,
              color: "white",
              full: true,
              hover: colors.accent_light,
              hoverColor: colors.secondary,
              hoverBorder: colors.accent_light,
              square: true,
            }}
            style={{
              marginTop: 17,
              gap: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            title="Change Photo"
            onClick={() => setSrc(null)}
          >
            <TbPhotoOff size={20} />
            Cancel
          </GlobalButton>
        </div>
      )}
    </>
  );
};

export default ImageCrop;
