import Lottie from "react-lottie";
import * as service from "../assets/animations/service.json";
import * as delivery_guy from "../assets/animations/delivery_guy.json";
import * as register from "../assets/animations/register.json";
import * as support from "../assets/animations/help.json";
import { useMediaQuery } from "react-responsive";
import { colors } from "../utils/colors";
import { IndexHeroContainer, IndexHeroDiv } from "../styles/Home.style";
import Navigations from "../components/Global/Navigations";
import Footer from "../components/Global/Footer";
import { BodyText, Header1_Light } from "../styles/Global/text";
import { GlobalContainer } from "../styles/Global/container.style";
import { GlobalButton } from "../styles/Global/button";
import { Link } from "../styles";

const Index = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <Navigations />
      <GlobalContainer style={{ flexDirection: "column", padding: 0 }}>
        <IndexHeroContainer
          style={{
            marginTop: 100,
          }}
          props={{ direction: "right", background: "transparent" }}
        >
          <IndexHeroDiv>
            {/* <BiTrip color={colors.red_dark} size={20} /> */}
            <Header1_Light>TAKE A TRIP WITH US</Header1_Light>
            <BodyText style={{ color: "grey" }}>
              Embark on a journey of convenience and efficiency with our
              delivery service. Whether it's parcels, documents, or special
              packages, our commitment is to make your delivery experience
              seamless and stress-free. Trust us to take your items on a
              reliable trip to their destination.
            </BodyText>
            <Link to="/about">
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: false,
                  border: colors.secondary,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                }}
                title="Sign up"
              >
                Learn more
              </GlobalButton>
            </Link>
          </IndexHeroDiv>
          {/* <img src={trip} height={500} width={500} /> */}
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: support,
            }}
            height={isBigScreen ? 500 : 300}
            width={isBigScreen ? 500 : 300}
          />
        </IndexHeroContainer>
        <IndexHeroContainer
          props={{ direction: "left", background: colors.accent_light }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: delivery_guy,
            }}
            height={isBigScreen ? 500 : 300}
            width={isBigScreen ? 500 : 300}
          />
          <IndexHeroDiv style={{ textAlign: "right", alignItems: "flex-end" }}>
            {/* <BiTrip color={colors.red_dark} size={20} /> */}
            <Header1_Light>SECURED SWIFT EXPRESS DELIVERIES</Header1_Light>
            <BodyText style={{ color: "grey" }}>
              Need it there in a hurry? Our express delivery service ensures
              your items reach their destination with speed and security.
              Experience the unmatched efficiency of our delivery network,
              providing swift solutions for your urgent shipping needs.
            </BodyText>
            <Link to="/pricing?products=true">
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: false,
                  border: colors.secondary,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                }}
                title="Sign up"
              >
                View offers
              </GlobalButton>
            </Link>
          </IndexHeroDiv>
        </IndexHeroContainer>
        <IndexHeroContainer
          props={{ direction: "right", background: "transparent" }}
        >
          <IndexHeroDiv>
            {/* <BiTrip color={colors.red_dark} size={20} /> */}
            <Header1_Light>EASY SETUP</Header1_Light>
            <BodyText style={{ color: "grey" }}>
              Setting up your deliveries has never been easier.
              With our user-friendly platform, you can seamlessly book, manage,
              and track your deliveries from the comfort of your device. Enjoy
              the simplicity of our easy setup and focus on what matters most –
              your business.
            </BodyText>
            <Link to="/signup">
              <GlobalButton
                props={{
                  background: colors.secondary,
                  color: "white",
                  full: false,
                  border: colors.secondary,
                  hover: colors.red_dark,
                  hoverColor: "white",
                  hoverBorder: colors.red_dark,
                }}
                title="Sign up"
              >
                Get started
              </GlobalButton>
            </Link>
          </IndexHeroDiv>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: register,
            }}
            height={isBigScreen ? 500 : 300}
            width={isBigScreen ? 500 : 300}
          />
        </IndexHeroContainer>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default Index;
