import React from "react";
import { Chart } from "react-google-charts";
import { colors } from "../../../utils/colors";

export const data = [
  ["Activities", "Hours per Day"],
  ["Analytics", 8],
  ["Delivery", 6],
  ["E-Payment", 5],
];

export const options = {
  title: "Daily Activities",
  pieHole: 0.4,
  is3D: false,
  colors: [colors.red_dark, "#FCA311", "#012350"],
};

const PieChart = () => {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="200px"
      data={data}
      options={options}
    />
  )
}

export default PieChart
