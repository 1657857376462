import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundView from "../../components/NotFoundView";
import SideNavigation from "../../components/WorkSpace/SideNavigation";
import TopNavigation from "../../components/WorkSpace/TopNavigation";
import { useAuth } from "../../context/AuthProvider";
import useExternalAPI from "../../hooks/useExternalAPI";
import {
  WorkSpaceArea,
  WorkSpaceAreaScroll,
  WorkSpaceContainer,
} from "./styles";
import SideProfile from "../../components/WorkSpace/SideProfile";
import Home from "./Home";
import EditProfile from "./Profile/EditProfile";
import Business from "./Business/AllBusiness";
import BusinessRoutes from "../../routes/BusinessRoutes";
import ProfileRoutes from "../../routes/ProfileRoutes";
import Services from "./Services";

const Workspace = () => {
  const { setUser, user } = useAuth();
  const [showProfile, setShowProfile] = useState(false);
  const { postData } = useExternalAPI();

  useEffect(() => {
    if (!user.user.identification) loadProfile();
  }, []);

  const loadProfile = async () => {
    const response = await postData({ url: "user/get", get: true });
    if (response.status) {
      setUser({
        ...user,
        user: { ...user.user, identification: response.data.identification },
      });
    }
  };

  return (
    <WorkSpaceContainer>
      <SideNavigation />
      <WorkSpaceArea>
        <TopNavigation
          setShowProfile={setShowProfile}
          showProfile={showProfile}
        />
        <WorkSpaceAreaScroll>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile/*" element={<ProfileRoutes />} />
            <Route path="/business/*" element={<BusinessRoutes />} />
            <Route path="/services" element={<Services />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </WorkSpaceAreaScroll>
        <SideProfile
          setShowProfile={setShowProfile}
          showProfile={showProfile}
        />
      </WorkSpaceArea>
    </WorkSpaceContainer>
  );
};

export default Workspace;
