interface INextDay {
  day: number;
  date?: Date;
}

interface IAddDays {
  days: number;
  date: Date;
}

let State: any = {
  daysIndex: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
};

function getDateIndex(date: Date) {
  try {
    const index = new Date(date).getDay();
    return { index, day: State.daysIndex[index] };
  } catch (e) {
    throw e;
  }
}

function getNextDay({ day, date = new Date() }: INextDay) {
  const dateCopy = new Date(date.getTime());

  const nextDay = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + day) % 7 || 7)
    )
  );

  const isoDate = nextDay.toISOString().split("T")[0];

  return nextDay;
}

function addDays({ days, date = new Date() }: IAddDays) {
  try {
    const newDate = date.setDate(date.getDate() + days);
    return new Date(newDate);
  } catch (e) {
    throw e;
  }
}

function isValidDate(value: string) {
  try {
    return new Date(value);
  } catch (e) {
    return false;
  }
}

function createDateTime(date: Date, time: string) {
  try {
    return new Date(`${new Date(date).toISOString().split("T")[0]} ${time}`);
  } catch (e) {
    return false;
  }
}
function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

function getDayNumber(date: string) {
  try {
    return date?.split("-")[2];
  } catch (e) {
    return "";
  }
}

function isDateValidSchedule(dateString: string): {
  status: boolean;
  message?: string;
} {
  try {
    const inputDate: any = new Date(dateString);
    const dayOfWeek = inputDate.getDay();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Check if the date is on a weekend (Saturday or Sunday)
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return { status: false, message: "day can't be weekend" };
    }

    // Check if the time is within the range of 8:00 AM to 5:00 PM
    if (hours < 8 || (hours >= 17 && minutes > 0)) {
      return { status: false, message: "time range error" };
    }

    // Get the next business day
    const nextBusinessDay = new Date();
    nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);

    // Check if the date exceeds seven days from the next business day
    if (inputDate > nextBusinessDay.setDate(nextBusinessDay.getDate() + 7)) {
      return { status: false, message: "days range error" };
    }

    return { status: true };
  } catch (e) {
    return { status: false, message: "An error occured" };
  }
}

function formatDate(date: Date) {
  try {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate: string = date.toLocaleDateString(undefined, options);
    // const formattedTime: string = date.toLocaleTimeString(undefined, options);

    return formattedDate;
  } catch (e) {
    console.error("Error formatting date", e);
    return date.toString();
  }
}

export {
  getDateIndex,
  getNextDay,
  addDays,
  isValidDate,
  createDateTime,
  addMinutes,
  getDayNumber,
  isDateValidSchedule,
  formatDate,
};
