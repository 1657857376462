import React from "react";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { colors } from "../../utils/colors";
import { FooterAnchor, FooterContainer, FooterTitle, FooterWrap } from "../../styles/Footer.style";
import { GlobalInputText } from "../../styles/Global/input";
import { GlobalButton } from "../../styles/Global/button";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <Link to="/" style={{ textDecoration: "none", color: "white" }}>
          <FooterTitle style={{ display: "flex", alignItems: "center" }}>
            Contactless Management Service
          </FooterTitle>
        </Link>
        <FooterAnchor>contactlessms2022@gmail.com</FooterAnchor>
        <FooterAnchor>+233 (0) 203 477 216</FooterAnchor>
        <FooterAnchor>Privacy Policy</FooterAnchor>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 10,
          }}
        >
          <FaFacebookSquare
            onClick={() =>
              window.open(
                "https://web.facebook.com/lawsocietyghana?_rdc=1&_rdr"
              )
            }
            title="Facebook"
            size={30}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
          <FaLinkedin
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/lawsocietyghana/about/"
              )
            }
            size={30}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
          <FaTwitterSquare
            onClick={() => window.open("https://twitter.com/lawsocietyghana")}
            size={30}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
          <FaInstagramSquare
            onClick={() =>
              window.open("https://www.instagram.com/lawsocietyghana/")
            }
            size={30}
            style={{ cursor: "pointer" }}
          />
        </div>
        <p style={{ fontSize: 12, color: "gray", marginTop: 10 }}>
          CMS © {new Date().getFullYear()} All rights reserved.
        </p>
      </FooterWrap>
      <FooterWrap>
        <FooterTitle>Services & Support</FooterTitle>
        <FooterAnchor
          onClick={() => window.open("https://login.lawsociety.org.gh/join")}
        >
          Become a Member
        </FooterAnchor>
        <FooterAnchor>Business Directory</FooterAnchor>
        <FooterAnchor>Complaints & Legal Aid</FooterAnchor>
      </FooterWrap>
      <FooterWrap>
        <FooterTitle>About</FooterTitle>
        <FooterAnchor>Who we are</FooterAnchor>
        <FooterAnchor>Our Initiatives</FooterAnchor>
        <FooterAnchor>Media Centre</FooterAnchor>
      </FooterWrap>
      <FooterWrap>
        <FooterTitle>Subscribe to Newsletter</FooterTitle>
        <GlobalInputText
          placeholder="Full name"
          type={"text"}
          props={{
            background: "white",
            color: colors.secondary,
            full: true,
            square: true,
          }}
        />
        <GlobalInputText
          placeholder="Email address"
          type={"email"}
          props={{
            background: "white",
            color: colors.secondary,
            full: true,
            square: true,
          }}
        />
        <GlobalButton
          props={{
            background: colors.yellow,
            color: colors.secondary,
            full: true,
            hover: colors.red_light,
            hoverColor: 'white',
            hoverBorder: colors.red_light,
            square: true,
          }}
          style={{fontWeight: 600}}
          title="Sign up"
        >
          Subscribe
        </GlobalButton>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
