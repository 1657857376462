import React from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../context/AuthProvider";
import { hide } from "../redux/modalReducer";
import useExternalAPI from "./useExternalAPI";
import useMessagePreview from "./useMessagePreview";
import { options } from "../components/WorkSpace/PieGraph";
import { colors } from "../utils/colors";

const useLogout = () => {
  const { logout } = useAuth();
  const { showModal, showModalPrompt } = useMessagePreview();
  const { postData } = useExternalAPI();
  const dispatch = useDispatch();

  const logUserOut = async () => {
    showModalPrompt({
      message: "Are you sure you want to logout of your account?",
      options: [
        {
          color: "green",
          label: "Yes",
          action: async () => {
            showModal({
              header: "Logging out",
              message: "Please wait, ending your login session.",
              preventClose: true,
              loading: true,
            });
            await postData({ url: "user/logout" });
            dispatch(hide());
            logout();
          },
        },
        {
          color: colors.red_dark,
          label: "No",
        },
      ],
    });
    // if (window.confirm("Are you sure you want to logout of your account?")) {

    // }
  };

  return { logout: logUserOut };
};

export default useLogout;
