import React, { useEffect, useState } from "react";
import { HomeCardWrap } from "../styles";
import { useLocation, useSearchParams } from "react-router-dom";
import { BsArrowLeftShort, BsCalendarCheck } from "react-icons/bs";
import { colors } from "../../../utils/colors";
import { FiEdit3 } from "react-icons/fi";
import { Link, gray } from "../../../styles";
import { useAuth } from "../../../context/AuthProvider";
import {
  BusinessAvatar,
  BusinessEditButtonWrapper,
  BusinessProfileDataWrapLight,
  SubPageLink,
} from "../../../styles/WorkSpace/Business.style";
import { BsDot } from "react-icons/bs";
import useImagePreview from "../../../hooks/useImagePreview";
import { BodyText, CardTitle } from "../../../styles/Global/text";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import useExternalAPI from "../../../hooks/useExternalAPI";
import { decrypt } from "../../../services/encryption.service";
import ActivityCard from "../../../components/WorkSpace/Business/ActivityCard";
import Activity from "../../../components/WorkSpace/Business/SubPages/Activity";
import Services from "../../../components/WorkSpace/Business/SubPages/Services";
import Members from "../../../components/WorkSpace/Business/SubPages/Members";
import { BiCategoryAlt } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import NotFoundView from "../../../components/NotFoundView";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { EServices } from "../../../utils/interface";

const BusinessProfile = () => {
  const [searchParams] = useSearchParams();
  const { navigate, user } = useAuth();
  const { show } = useImagePreview();
  const { showModal, hideModal } = useMessagePreview();
  const { postData } = useExternalAPI();
  const [props, setProps] = useState(useLocation().state?.props);
  const [exist, setExist] = useState<boolean>(true);
  const [subPage, setSubPage] = useState<string>("about");

  useEffect(() => {
    const id = searchParams.get("id");
    const subPage = searchParams.get("sub");

    if (subPage) setSubPage(subPage);

    if (id) {
      setProps((e: any) => ({ ...e, encryptedId: id }));
      const decrypted_Id = `${decrypt(id)}`;
      if (typeof decrypted_Id === "string") loadBusinessProfile(decrypted_Id);
    }
  }, []);

  useEffect(() => {
    const id = searchParams.get("id");
    const subPage = searchParams.get("sub");

    if (subPage) setSubPage(subPage);
  }, [searchParams]);

  const loadBusinessProfile = async (id: string) => {
    try {
      if (!props?.id)
        showModal({
          header: "Please wait..",
          message:
            "Loading business profile, this should take some few seconds",
          loading: true,
          preventClose: true,
        });
      const request = await postData({
        url: `business/get?id=${id}`,
        get: true,
      });
      if (request.status) {
        setExist(true);
        setProps((e: any) => ({
          ...e,
          ...request.data,
          members: request.data.businessaccounts,
        }));
      } else setExist(false);
      hideModal();
    } catch (e) {
      setExist(false);
      hideModal();
    }
  };

  const setStatus = (): { label: string; color: string } => {
    let return_type = { label: "Pending", color: "orange" };
    if (props?.status === 1) return_type = { label: "Active", color: "green" };
    else if (props?.status === 0) return { label: "Deactivated", color: "red" };
    else if (props?.status === 2) return { label: "Suspended", color: "red" };

    return return_type;
  };

  const setContacts = (): { primary: string; secondary?: string | null } => {
    return {
      primary: `${props?.contacts.primary.code} ${props?.contacts.primary.number}`,
      secondary:
        props?.contacts.secondary.code && props?.contacts.secondary.number
          ? `${props?.contacts.secondary.code} ${props?.contacts.secondary.number}`
          : null,
    };
  };

  const getSubPage = (page: string) => {
    return subPage === page;
  };

  const isAdmin = () => {
    try {
      const admin = props?.members.find(
        (e: { role: number; user: { email: any } }) =>
          e.role === 2 && e.user.email === user.user.email
      );
      return admin;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {!props?.id || !exist ? (
        !exist ? (
          <NotFoundView />
        ) : !props?.id ? null : null
      ) : (
        <>
          <HomeCardWrap
            props={{ width: 100, widthMobile: 100 }}
            style={{ marginBottom: 20, flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                width: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <BsArrowLeftShort
                size={25}
                style={{ cursor: "pointer", marginRight: 5 }}
                onClick={() => navigate(-1)}
              />
              <Link to="/workspace/business">
                <span
                  style={{
                    color: colors.red_dark,
                    marginRight: 2,
                  }}
                >
                  {"business / "}
                </span>
              </Link>
              {` ${props?.name}`}
              {isAdmin() && (
                <Link
                  to="/workspace/business/edit"
                  state={{ props }}
                  style={{ marginLeft: "auto" }}
                >
                  <BusinessEditButtonWrapper>
                    <FiEdit3 />
                  </BusinessEditButtonWrapper>
                </Link>
              )}
            </div>
            <div
              style={{ width: "100%", marginTop: 20, display: "flex", gap: 20 }}
            >
              <BusinessAvatar
                src={props?.avatar}
                onError={() =>
                  setProps((e: any) => ({
                    ...e,
                    avatar: require("../../../assets/images/business_default.png"),
                  }))
                }
                onClick={() => show([props?.avatar])}
              />
              <div style={{ flex: 1, marginTop: 10 }}>
                <BodyText style={{ fontWeight: "600", display: "flex" }}>
                  {props?.name}
                  <span
                    style={{
                      fontSize: 12,
                      color: setStatus().color,
                      display: "flex",
                      width: "max-content",
                      alignItems: "flex-start",
                      gap: 5,
                      marginLeft: 10,
                    }}
                  >
                    <AiOutlineCheckCircle /> {setStatus().label}
                  </span>
                </BodyText>
                <div
                  style={{
                    fontSize: 13,
                    color: "rgba(0,0,0,0.5",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <BiCategoryAlt
                    style={{ marginRight: 5 }}
                    size={20}
                    color={colors.yellow}
                  />
                  {props?.category}
                  <BsDot
                    style={{ color: "black", margin: "0 3px" }}
                    size={20}
                  />
                  <MdLocationPin
                    style={{ marginRight: 5 }}
                    size={20}
                    color={colors.red_light}
                  />{" "}
                  {` ${props?.address.street} ${props?.address.state}, ${props?.address.country}`}
                </div>
                <div
                  style={{
                    fontSize: 13,
                    color: "rgba(0,0,0,0.5",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <FaUsers
                    style={{ marginRight: 5 }}
                    size={20}
                    color={colors.blue}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {props?.members?.length || ""}
                  </span>
                  Members
                  <BsDot
                    style={{ color: "black", margin: "0 7px" }}
                    size={20}
                  />
                  <BsCalendarCheck
                    style={{ marginRight: 5 }}
                    size={15}
                    color={colors.red_dark}
                  />
                  Created{" "}
                  <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                    {new Date(props?.createdAt).toDateString()}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 40,
                display: "flex",
                gap: 20,
                flexWrap: "wrap",
              }}
            >
              <BusinessProfileDataWrapLight>
                <IoMailOutline /> {props?.email}
              </BusinessProfileDataWrapLight>
              <BusinessProfileDataWrapLight>
                <IoIosCall />
                {setContacts().primary}
                {setContacts().secondary && ` / ${setContacts().secondary}`}
              </BusinessProfileDataWrapLight>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 40,
                paddingTop: 10,
                gap: 20,
                flexWrap: "wrap",
                borderTop: "0.5px solid rgba(0,0,0,0.1)",
              }}
            >
              <CardTitle style={{ color: "black", width: "100%" }}>
                Ongoing Activities
              </CardTitle>
              <div style={{ display: "flex", flexWrap: "wrap", gap: 15 }}>
                <ActivityCard
                  status={1}
                  service_type={EServices["Courier Service"]}
                />
                <ActivityCard
                  status={2}
                  service_type={EServices["AI Analytics Service"]}
                />
                <ActivityCard
                  status={3}
                  service_type={EServices["E-Payment Service"]}
                />
              </div>
            </div>
          </HomeCardWrap>
          <HomeCardWrap
            props={{ width: 100, widthMobile: 100 }}
            style={{ marginBottom: 40 }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                overflowX: "auto",
                borderBottom: `0.5px solid ${gray(0.1)}`,
                gap: 30,
              }}
              className=".scrollY"
            >
              <SubPageLink
                to={`?id=${props.encryptedId}&sub=about`}
                state={{ props }}
                props={{ active: getSubPage("about") }}
              >
                About
              </SubPageLink>
              <SubPageLink
                to={`?id=${props.encryptedId}&sub=activity`}
                state={{ props }}
                props={{ active: getSubPage("activity") }}
              >
                Activity
              </SubPageLink>
              <SubPageLink
                to={`?id=${props.encryptedId}&sub=services`}
                state={{ props }}
                props={{ active: getSubPage("services") }}
              >
                Services
              </SubPageLink>
              <SubPageLink
                to={`?id=${props.encryptedId}&sub=members`}
                state={{ props }}
                props={{ active: getSubPage("members") }}
              >
                Members {` (${props?.members?.length || ""})`}
              </SubPageLink>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 10,
                marginBottom: 20,
                flexDirection: "column",
                maxHeight: "85vh",
                overflowY: "auto",
              }}
              className=".scrollY"
            >
              {getSubPage("about") && (
                <p style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>
                  {props?.description}
                </p>
              )}
              {getSubPage("activity") && <Activity />}
              {getSubPage("services") && <Services />}
              {getSubPage("members") && (
                <Members
                  members={props?.members}
                  updateProfile={setProps}
                  id={props.id}
                />
              )}
            </div>
          </HomeCardWrap>
        </>
      )}
    </>
  );
};

export default BusinessProfile;
