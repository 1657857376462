import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Header1 = styled.h1`
  font-size: 45px;
  font-weight: bolder;
  font-family: "Montserrat-Bold";
  margin: 0 0 25px 0;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const Header2 = styled.h2`
  font-size: 32px;
  font-weight: bolder;
  font-family: "Montserrat-Bold";
  margin: 0 0 20px 0;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const Header3 = styled.h3`
  font-size: 28px;
  font-weight: bolder;
  font-family: "Montserrat-Bold";
  margin: 0 0 15px 0;

  @media (max-width: 768px) {
    font-size: 16px !important;
  }
`;

export const Header4 = styled.h4`
  font-size: 25px;
  font-weight: bolder;
  font-family: "Montserrat-Bold";
  margin: 0 0 25px 0;

  @media (max-width: 768px) {
    font-size: 14px !important;
  }
`;

export const BodyText = styled.p`
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Header1_Light = styled.h1`
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0 0 25px 0;

  @media (max-width: 768px) {
    font-size: 23px;
  }
`;

export const Paragraph = styled.p`
  font-size: 16px;
  margin: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FormLabel = styled.label`
  color: black;
  font-weight: bold;
  
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const FormDesc = styled.p`
  color: grey;
  margin-bottom: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CardTitle = styled.h5`
  text-align: left;
  align-self: flex-start;
  margin: 0 0 20px 0;
  font-size: 16;
  width: 30%;
  color: ${colors.primary};
`;