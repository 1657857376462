import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Header4, Paragraph } from "../../styles/Global/text";

interface IReviews {
  message: string;
  name: string;
  role: string;
  stars: number;
  institution: string;
}

const Reviews = ({ message, name, role, stars, institution }: IReviews) => {
  const [starCount, setStarsCount] = useState<number[]>([]);

  useEffect(() => {
    setStarsCount([]);
    for (var i = 0; i < stars; i++) {
      setStarsCount((e) => [...e, i]);
    }
  }, []);

  return (
    <div
      style={{
        zIndex: 2,
        position: "absolute",
        bottom: "5%",
        left: "7.5%",
        right: "7.5%",
        border: "1px solid rgba(255, 255, 255, 0.4)",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: 2,
        backdropFilter: "blur(7.5px)",
        display: "flex",
        color: "white",
        padding: "5%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Header4>{message}</Header4>
      <Header4
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span>
          {name}
          <Paragraph>
            {role}
            <br />
          </Paragraph>
        </span>{" "}
        <div style={{ flex: 1 }} />
        {starCount.map((data, index) => (
          <FaStar
            color="white"
            size={16}
            style={{ marginLeft: 3 }}
            key={index}
          />
        ))}
      </Header4>
      <Paragraph
      >
        {institution}
      </Paragraph>
    </div>
  );
};

export default Reviews;
