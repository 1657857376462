import { useState } from "react";
import { colors } from "../../utils/colors";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import { NavigationMobile, NavigationRow } from "../../styles/Navigation.style";
import { GlobalLogo } from "../../styles";
import { GlobalButton } from "../../styles/Global/button";

const Navigations = () => {
  const [dropNave, setDropNav] = useState<Boolean>(false);

  return (
    <>
      <NavigationRow>
        <Link to="/">
          <GlobalLogo src={require("../../assets/images/logo_dark.png")} />
        </Link>
        {/* <GlobalLogoH1>CMS</GlobalLogoH1> */}
        <div style={{ flex: 1, display: "flex", justifyContent: 'center', gap: 5 }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: false,
              border: "white",
            }}
            title="Home"
          >
            Home
          </GlobalButton>
        </Link>
        <Link to="/about" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: false,
              border: "white",
            }}
            title="About"
          >
            About
          </GlobalButton>
        </Link>
        <Link to="/pricing?products=true" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: false,
              border: "white",
            }}
            title="Products"
          >
            Products
          </GlobalButton>
        </Link>
        <Link to="/delivery" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: false,
              border: "white",
            }}
            title="Delivery"
          >
            Delivery
          </GlobalButton>
        </Link>
        <Link to="/pricing" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              full: false,
              border: "white",
            }}
            title="Pricing"
          >
            Pricing
          </GlobalButton>
        </Link>
        </div>
        <div style={{ display: "flex", gap: 5}}>
        <Link to="/signin" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: "white",
              color: colors.secondary,
              hover: colors.red_dark,
              hoverBorder: colors.red_dark,
              full: false,
              border: "white",
            }}
            title="Sign in"
          >
            Sign in
          </GlobalButton>
        </Link>
        <Link to="/signup" style={{ textDecoration: "none" }}>
          <GlobalButton
            props={{
              background: colors.secondary,
              color: "white",
              full: false,
              border: colors.secondary,
              hover: colors.red_dark,
              hoverColor: "white",
              hoverBorder: colors.red_dark,
            }}
            title="Sign up"
          >
            Sign up
          </GlobalButton>
        </Link>
        </div>
      </NavigationRow>
      <NavigationMobile>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            backgroundColor: "white",
            padding: "0 2%",
            paddingBottom: "2%",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <GlobalLogo src={require("../../assets/images/logo_dark.png")} />
          </Link>
          {dropNave ? (
            <IoClose
              color={colors.secondary}
              size={30}
              onClick={() => setDropNav(false)}
            />
          ) : (
            <HiMenuAlt3
              color={colors.secondary}
              style={{ zIndex: 1 }}
              size={30}
              onClick={() => setDropNav(true)}
            />
          )}
        </div>
        <AnimateHeight height={dropNave ? "auto" : 0}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "0 2%",
                paddingBottom: "2%",
                gap: 10,
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  title="Home"
                  onClick={() => setDropNav(false)}
                >
                  Home
                </GlobalButton>
              </Link>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  title="About"
                  onClick={() => setDropNav(false)}
                >
                  About
                </GlobalButton>
              </Link>
              <Link
                to="/pricing?products=true"
                style={{ textDecoration: "none" }}
              >
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  title="Products"
                  onClick={() => setDropNav(false)}
                >
                  Products
                </GlobalButton>
              </Link>
              <Link to="/delivery" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  title="delivery"
                  onClick={() => setDropNav(false)}
                >
                  Delivery
                </GlobalButton>
              </Link>
              <Link to="/pricing" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  title="Pricing"
                  onClick={() => setDropNav(false)}
                >
                  Pricing
                </GlobalButton>
              </Link>
              <div
                style={{
                  border: "0.5px solid " + colors.primary,
                  width: "100%",
                }}
              />
              <Link to="/signin" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  style={{
                    marginBottom: 10,
                    marginTop: 20,
                    fontWeight: "bolder",
                  }}
                  title="Sign in"
                  onClick={() => setDropNav(false)}
                >
                  Sign in
                </GlobalButton>
              </Link>
              <Link to="/signup" style={{ textDecoration: "none" }}>
                <GlobalButton
                  props={{
                    background: "transparent",
                    color: colors.secondary,
                    full: false,
                    hoverColor: "white",
                  }}
                  style={{ fontWeight: "bolder" }}
                  title="Sign up"
                  onClick={() => setDropNav(false)}
                >
                  Sign up
                </GlobalButton>
              </Link>
            </div>
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                width: "100%",
              }}
              onClick={() => setDropNav(false)}
            ></div>
          </div>
        </AnimateHeight>
      </NavigationMobile>
    </>
  );
};

export default Navigations;
