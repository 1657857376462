import { useEffect, useState } from "react";
import { decrypt, encrypt } from "../services/encryption.service";
import { isJSON } from "../services/validators.service";

const useLocalStorage = (keyName: string, defaultValue: object) => {

  const [user, setUser] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        const data = decrypt(value);
        return isJSON(data);
      } else {
        // const data = encrypt(defaultValue);
        // window.localStorage.setItem(keyName, data);
        return defaultValue;
      }
    } catch (err) {
      console.log(err);
      return defaultValue;
    }
  });

  const setValue = (newValue: object) => {
    try {
      const data = encrypt(newValue);
      window.localStorage.setItem(keyName, JSON.stringify(data));
      setUser(newValue);
      return true;
    } catch (err) {
      console.error("UserlocaStorage error: ", err);
      return false;
    }
  };

  const removeValue = async() => {
    try {
      setUser(null)
      await window.localStorage.removeItem(keyName);
      return true;
    } catch (err) {
      console.error("UserlocaStorage error: ", err);
      return false;
    }
  };

  return { user, setUser: setValue, removeValue };
};

export default useLocalStorage;
