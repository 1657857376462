import { useEffect, useState } from "react";
import { HomeCardWrap } from "../../../../../pages/WorkSpace/styles";
import {
  GlobalInputSelectBottom,
  GlobalInputTextAreaBottom,
  GlobalInputTextBottom,
} from "../../../../../styles/Global/input";
import {
  FormDesc,
  FormLabel,
  Header3,
} from "../../../../../styles/Global/text";
import {
  BusinessAddFormDiv,
  BusinessAddFormWrapper,
} from "../../../../../styles/WorkSpace/Business.style";
import { colors } from "../../../../../utils/colors";
import { DeliveryItemType } from "../../../../../utils/interface";
import { IDeliveryFormData } from "../AddDelivery.component";
import useExternalAPI from "../../../../../hooks/useExternalAPI";
import { gray } from "../../../../../styles";
import { GlobalButton } from "../../../../../styles/Global/button";
import { MdNavigateNext } from "react-icons/md";
import { formHandler } from "../../../../../services/formHandler.service";

const Step3 = ({
  formData,
  setFormData,
  swiper,
}: {
  formData: IDeliveryFormData;
  setFormData: React.Dispatch<React.SetStateAction<IDeliveryFormData>>;
  swiper: (next: boolean) => void;
}) => {
  const deliveryItems = [
    { label: "🍔 Food", value: DeliveryItemType[DeliveryItemType.Food] },
    {
      label: "📄 Documents",
      value: DeliveryItemType[DeliveryItemType.Documents],
    },
    { label: "🛍️ Retail", value: DeliveryItemType[DeliveryItemType.Retail] },
    { label: "⚕️ Medical", value: DeliveryItemType[DeliveryItemType.Medical] },
    {
      label: "🎁 Specialized",
      value: DeliveryItemType[DeliveryItemType.Specialized],
    },
  ];
  const { postData, formBaseUrl } = useExternalAPI();
  const [countries, setCountries] = useState<Array<any>>([]);

  useEffect(() => {
    if (countries.length < 1) getCountries();
  }, [countries]);

  const getCountries = async () => {
    const response: any = await postData({
      baseUrl: formBaseUrl,
      url: "form/countries",
      get: true,
    });

    if (response.status) {
      setCountries(response?.data);
    }
  };

  const optionalTag = () => (
    <span style={{ fontWeight: "normal", fontSize: 12, color: colors.yellow }}>
      (optional)
    </span>
  );

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = formHandler(e);

    console.log({ form });
    const pickup = {
      description: form["pickup_description"],
      contact: {
        name: form["pickup_contact_name"],
        code: form["pickup_contact_code"],
        number: form["pickup_contact_number"],
      },
    };
    const delivery: any[] = [];
    if (formData.route?.to)
      formData.route.to.map((data, index) => {
        delivery?.push({
          item: {
            name: form[`delivery_item_name_${index}`],
            description: form[`delivery_description_${index}`],
          },
          contact: {
            name: form[`delivery_contact_name_${index}`],
            code: form[`delivery_contact_code_${index}`],
            number: form[`delivery_contact_number_${index}`],
          },
        });
      });

    console.log({ pcikup: pickup, delivery });
    setFormData((e) => ({
      ...e,
      pickup_route: pickup,
      delivery_routes: delivery,
    }));

    swiper(true);
  };

  return (
    <>
      <form onSubmit={(e) => submit(e)}>
        <Header3 style={{ marginBottom: 0, fontSize: 20 }}>
          🛍️ Item Details
        </Header3>
        <FormDesc style={{ marginBottom: 50 }}>
          Select item and details for the selected routes in the previous form.
        </FormDesc>
        <HomeCardWrap
          props={{ width: 100, widthMobile: 100 }}
          style={{ marginBottom: 20, padding: 0 }}
        >
          <BusinessAddFormWrapper>
            <BusinessAddFormDiv>
              <Header3
                style={{
                  marginBottom: 0,
                  fontSize: 20,
                  color: colors.secondary,
                }}
              >
                Item Type
              </Header3>
              <FormDesc>
                The type of item to be delivered, select one from the list.
              </FormDesc>
            </BusinessAddFormDiv>
            <BusinessAddFormDiv>
              <FormLabel htmlFor="name">Category</FormLabel>
              <GlobalInputSelectBottom
                placeholder="Select category"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="category"
                required={true}
              >
                <option disabled selected value="">
                  Select type
                </option>
                {deliveryItems.map(({ label, value }, index) => (
                  <option key={index} value={value}>
                    {label}
                  </option>
                ))}
              </GlobalInputSelectBottom>
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          <BusinessAddFormWrapper
            style={{ borderTop: "1px solid " + gray(0.1), paddingTop: 20 }}
          >
            <BusinessAddFormDiv>
              <Header3
                style={{
                  marginBottom: 0,
                  fontSize: 20,
                  color: colors.secondary,
                }}
              >
                📍 Pickup Route
              </Header3>
              <FormDesc>
                <span style={{ color: colors.red_dark, fontWeight: 600 }}>
                  {`(${formData.route?.from?.label})`}
                </span>
              </FormDesc>
            </BusinessAddFormDiv>
            <BusinessAddFormDiv>
              <FormLabel htmlFor="pickup_contact_name">Contact name</FormLabel>
              <GlobalInputTextBottom
                placeholder="Enter pickup person name"
                id="pickup_contact_name"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="pickup_contact_name"
                required
              />
              <FormLabel htmlFor="pickup_contact_code">
                Contact number
              </FormLabel>
              <span
                style={{ display: "flex", alignItems: "flex-end", gap: 10 }}
              >
                <GlobalInputSelectBottom
                  placeholder="Select country"
                  props={{
                    background: gray(0.05),
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "transparent",
                  }}
                  // ref={countryRef}
                  id="pickup_contact_code"
                  name="pickup_contact_code"
                  value={"233"}
                  disabled
                  required
                >
                  <option disabled value="">
                    Select country code
                  </option>
                  {countries.map((data, index) => (
                    <option
                      key={index}
                      value={data?.phone_code}
                    >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
                  ))}
                </GlobalInputSelectBottom>
                <GlobalInputTextBottom
                  placeholder="Enter phone number"
                  type={"number"}
                  props={{
                    background: gray(0.05),
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "transparent",
                  }}
                  name="pickup_contact_number"
                  id="pickup_contact_number"
                  required
                />
              </span>
              <FormLabel htmlFor="pickup_description">
                Pickup additional notes {optionalTag()}
              </FormLabel>
              <GlobalInputTextAreaBottom
                placeholder="Enter additional item description"
                rows={5}
                id="pickup_description"
                props={{
                  background: gray(0.05),
                  color: colors.secondary,
                  full: true,
                  square: true,
                  border: "transparent",
                }}
                name="pickup_description"
              />
            </BusinessAddFormDiv>
          </BusinessAddFormWrapper>
          {formData.route?.to &&
            formData.route.to?.map((data, index) => (
              <BusinessAddFormWrapper
                style={{
                  borderTop:
                    "1px solid " + (index === 0 ? gray(0.2) : gray(0.1)),
                  paddingTop: index === 0 ? 20 : 10,
                }}
              >
                <BusinessAddFormDiv>
                  <Header3
                    style={{
                      marginBottom: 0,
                      fontSize: 20,
                      color: colors.secondary,
                    }}
                  >
                    📍 Delivery Route {index + 1}
                  </Header3>
                  <FormDesc>
                    <span style={{ color: colors.yellow, fontWeight: 600 }}>
                      {data.label}
                    </span>
                  </FormDesc>
                </BusinessAddFormDiv>
                <BusinessAddFormDiv>
                  <FormLabel htmlFor={`delivery_item_name_${index}`}>
                    Item name
                  </FormLabel>
                  <GlobalInputTextBottom
                    placeholder="Enter pickup person name"
                    id={`delivery_item_name_${index}`}
                    props={{
                      background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                    }}
                    name={`delivery_item_name_${index}`}
                    required
                  />
                  <FormLabel htmlFor={`delivery_contact_name_${index}`}>
                    Contact name
                  </FormLabel>
                  <GlobalInputTextBottom
                    placeholder="Enter pickup person name"
                    id={`delivery_contact_name_${index}`}
                    props={{
                      background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                    }}
                    name={`delivery_contact_name_${index}`}
                    required
                  />
                  <FormLabel htmlFor={`delivery_contact_code_${index}`}>
                    Contact number
                  </FormLabel>
                  <span
                    style={{ display: "flex", alignItems: "flex-end", gap: 10 }}
                  >
                    <GlobalInputSelectBottom
                      placeholder="Select country"
                      props={{
                        background: gray(0.05),
                        color: colors.secondary,
                        full: true,
                        square: true,
                        border: "transparent",
                      }}
                      // ref={countryRef}
                      name={`delivery_contact_code_${index}`}
                      value={"233"}
                      disabled
                      required
                    >
                      <option disabled value="">
                        Select country code
                      </option>
                      {countries.map((data, index) => (
                        <option
                          key={index}
                          value={data?.phone_code}
                        >{`${data?.iso3} (${data?.phone_code} ${data?.emoji})`}</option>
                      ))}
                    </GlobalInputSelectBottom>
                    <GlobalInputTextBottom
                      placeholder="Enter phone number"
                      type={"number"}
                      props={{
                        background: gray(0.05),
                        color: colors.secondary,
                        full: true,
                        square: true,
                        border: "transparent",
                      }}
                      name={`delivery_contact_number_${index}`}
                      required
                    />
                  </span>
                  <FormLabel htmlFor={`delivery_description_${index}`}>
                    Delivery additional notes {optionalTag()}
                  </FormLabel>
                  <GlobalInputTextAreaBottom
                    placeholder="Enter additional item description"
                    rows={5}
                    id={`delivery_description_${index}`}
                    props={{
                      background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
                    }}
                    name={`delivery_description_${index}`}
                  />
                </BusinessAddFormDiv>
              </BusinessAddFormWrapper>
            ))}
        </HomeCardWrap>
        <GlobalButton
          props={{
            background: colors.secondary,
            full: false,
            color: "white",
            square: true,
            border: colors.secondary,
            hover: colors.yellow,
            hoverColor: colors.secondary,
            hoverBorder: colors.yellow,
          }}
          style={{ fontSize: 13, fontWeight: 600, marginLeft: "auto" }}
          type="submit"
        >
          Continue Shipping
          <MdNavigateNext />
        </GlobalButton>
      </form>
    </>
  );
};

export default Step3;
