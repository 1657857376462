import {
  ActivityBarStatus,
  ActivityCardContainer,
  ActivityStatus,
} from "../../../styles/WorkSpace/Activity.style";
import { GoDotFill } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import { gray } from "../../../styles";
import { colors } from "../../../utils/colors";
import GlobalPopup from "../../Global/GlobalPopup";
import { EServices, EServicesColor } from "../../../utils/interface";

interface IActivityCard {
  status: 0 | 1 | 2 | 3;
  service_type: EServices;
}

const ActivityCard = ({ status, service_type }: IActivityCard) => {
  const statusReturnType = (): {
    backgroundColor: string;
    color: string;
    status: string;
  } => {
    let status_return = {
      backgroundColor: "#EEF7EA",
      color: "green",
      status: "Active",
    };

    if (status === 0)
      status_return = {
        backgroundColor: gray(0.2),
        color: gray(0.8),
        status: "Scheduled",
      };

    if (status === 2)
      status_return = {
        backgroundColor: "#b6e4f9",
        color: "#2f82ef",
        status: "Completed",
      };

    if (status === 3)
      status_return = {
        backgroundColor: "rgba(121, 0, 0, 0.1)",
        color: colors.red_dark,
        status: "Failed",
      };

    return status_return;
  };

  const serviceTypeColor = (): string => {
    const service = EServices[service_type];
    const enumValue = service as keyof typeof EServicesColor;
    const color: string = EServicesColor[enumValue];

    return color;
  };

  return (
    <ActivityCardContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ActivityStatus
          props={{
            backgroundColor: statusReturnType().backgroundColor,
            color: statusReturnType().color,
          }}
        >
          <GoDotFill
            style={{ color: statusReturnType().color }}
            size={15}
          />
          {statusReturnType().status}
        </ActivityStatus>
        <GlobalPopup
          id={status}
          icon={<BsThreeDots color={gray(0.5)} size={20} />}
          options={["View", "Cancel"]}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          position: "relative",
          marginTop: 20,
        }}
      >
        <ActivityBarStatus
          props={{ backgroundColor: serviceTypeColor() }}
          style={{ color: "transparent" }}
        >
          .
        </ActivityBarStatus>
        <div style={{ flex: 1 }}>
          <h5 style={{ color: "black", margin: 0, fontSize: 14 }}>
            {EServices[service_type]}
          </h5>
          <p style={{ fontSize: 12, margin: "10px 0 0 0" }}>
            <span style={{ color: colors.blue }}>Initiated</span>: 12:05pm 27th,
            May 2023
          </p>
        </div>
      </div>
    </ActivityCardContainer>
  );
};

export default ActivityCard;
