import styled from "styled-components";

export const ActivityCardContainer = styled.div`
  width: max-content;
  padding: 15px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 250px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IActivityStatus {
  props: {
    color: string;
    backgroundColor: string;
  };
}

export const ActivityStatus = styled.span<IActivityStatus>`
  display: flex;
  width: max-content;
  margin: 0;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  background-color: ${({ props }) => props.backgroundColor};
  color: ${({ props }) => props.color};
  padding: 5px 7px;
  border-radius: 5px;
`;

interface IActivityBarStatus {
  props: {
    backgroundColor: string;
  };
}

export const ActivityBarStatus = styled.span<IActivityBarStatus>`
  /* height: 100%; */
  width: 3px;
  background-color: ${({ props }) => props.backgroundColor};
  border-radius: 2px;
`;
