import React, { useRef, useState } from "react";
import validator from "validator";
import { useGoogleLogin } from "@react-oauth/google";

import Footer from "../../components/Global/Footer";
import { colors } from "../../utils/colors";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import Reviews from "../../components/SignupSignIn/Reviews";
import { Swiper, SwiperSlide } from "swiper/react";
import { FormDesc, FormLabel, Header3 } from "../../styles/Global/text";
import { formHandler } from "../../services/formHandler.service";
import FormResponse from "../../components/Global/FormResponse";
import { IFormResponse } from "../../utils/interface";
import useMessagePreview from "../../hooks/useMessagePreview";
import { IoIosArrowRoundBack } from "react-icons/io";
import useExternalAPI from "../../hooks/useExternalAPI";
import { GlobalContainer } from "../../styles/Global/container.style";
import { AccessDiv } from "../../styles/Access.style";
import { GlobalInputText } from "../../styles/Global/input";
import { GlobalButton } from "../../styles/Global/button";

const Signup = () => {
  const [stage, setStage] = useState(0);
  const { showModal } = useMessagePreview();
  const { postData } = useExternalAPI();
  const [token, setToken] = useState<string | null>(null);
  const formRef = useRef<any>();
  const navigate = useNavigate();
  const swiperRef = useRef<any>();
  const [formFills, setFormFills] = useState({
    firstname: "",
    lastname: "",
    email: "",
    avatar: null,
  });

  const { addNotification } = useMessagePreview();
  const [formResponse, setFormResponse] = useState<IFormResponse>({
    message: "",
    visible: false,
    status: 2,
  });
  
  const googleSignup = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (data) => {
      const token = data.code;
      const response = await post({ token });
      if (response.status) {
        setFormFills({ ...response.data });
        setToken(response.data.token);
      }
    },
    onError: (error) => {
      addNotification({
        message:
          error.error_description?.toString() ||
          "Error occured while trying to Google sign in.",
        type: 0,
      });
    },
  });

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (stage !== 2) {
      swiper(2);
      return;
    }

    let formData = formHandler(e);

    if (formData.password !== formData.confirmpassword) {
      setFormResponse({
        message: "Password mismatch",
        status: 0,
      });

      return;
    }

    const passwordCheck: any = checkPassword(formData.password);
    if (!passwordCheck) {
      setFormResponse({
        message:
          "Password must be more than 6 characters, must contain numbers and symbols.",
        status: 0,
      });
      return;
    }

    if (token) formData = { ...formData, token };
    const { mailed, login, status } = await post(formData);
    if (status) {
      mailed || login
        ? navigate("/signin")
        : navigate("/verification?email=" + formData.email);
    }
  };

  const post = async (e: Object) => {
    setFormResponse({
      message: "signing you up.",
      visible: true,
      status: 2,
    });

    const response: any = await postData({
      url: "user/register",
      body: { ...e, avatar: formFills.avatar },
    });

    const { message, login, status, data, mailed } = response;

    if (status) {
      showModal({
        header: "Success",
        message: message,
        state: 1,
      });
      setFormResponse({
        message: message || formResponse.message,
        status: 1,
      });
    } else {
      setFormResponse({
        message: message || formResponse.message,
        status: 0,
      });
    }

    return { status, data, login, mailed };
  };

  const swiper = (index: number) => {
    setStage(index);
    swiperRef.current.slideTo(index);
  };

  const checkPassword = (e: string) => {
    if (e === "") return;
    // console.log("Data", e);
    const strong = validator.isStrongPassword(e, { minLength: 6 });

    setFormResponse({
      message: strong ? "Strong password" : "Weak password",
      visible: true,
      status: strong ? 1 : 0,
    });

    return strong;
  };

  return (
    <>
      <GlobalContainer style={{ padding: 0, maxHeight: "100vh" }}>
        <AccessDiv props={{ hide: false }}>
          <Header3 style={{ marginBottom: 0 }}>Great, let's set you up</Header3>
          <FormDesc>Fill the form below to get your account started.</FormDesc>
          <form
            ref={formRef}
            onSubmit={(e) => request(e)}
            style={{ height: "min-content" }}
          >
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              allowTouchMove={false}
              noSwiping={true}
              autoHeight={true}
            >
              <SwiperSlide>
                <FormLabel htmlFor="firstname">First name</FormLabel>
                <GlobalInputText
                  placeholder="Enter your first name"
                  type={"text"}
                  value={formFills.firstname}
                  onChange={(e) =>
                    setFormFills({ ...formFills, firstname: e.target.value })
                  }
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="firstname"
                  id="firstname"
                  required
                />
                <FormLabel htmlFor="othernames">Other names</FormLabel>
                <GlobalInputText
                  placeholder="Enter your first name"
                  type={"text"}
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="othernames"
                  id="othernames"
                />
                <FormLabel htmlFor="lastname">Last name</FormLabel>
                <GlobalInputText
                  placeholder="Enter your last name"
                  type={"text"}
                  value={formFills.lastname}
                  onChange={(e) =>
                    setFormFills({ ...formFills, lastname: e.target.value })
                  }
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="lastname"
                  id="lastname"
                  required
                />
              </SwiperSlide>
              <SwiperSlide>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "normal",
                    marginBottom: 15,
                    cursor: "pointer",
                    maxWidth: "min-content",
                  }}
                  onClick={() => swiper(0)}
                >
                  <IoIosArrowRoundBack color={colors.secondary} size={20} />{" "}
                  back
                </span>
                <FormLabel htmlFor="email">Email</FormLabel>
                <GlobalInputText
                  placeholder="Enter your email"
                  type={"email"}
                  id="email"
                  value={formFills.email}
                  onChange={(e) =>
                    setFormFills({ ...formFills, email: e.target.value })
                  }
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="email"
                  required={stage === 2}
                />
                <FormLabel htmlFor="password">Password</FormLabel>
                <GlobalInputText
                  placeholder="Enter your password"
                  type={"password"}
                  id="password"
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  onChange={(e) => checkPassword(e.target.value)}
                  name="password"
                  required={stage === 2}
                />
                <FormLabel htmlFor="confirmpassword">
                  Confirm password
                </FormLabel>
                <GlobalInputText
                  placeholder="Enter your password"
                  type={"password"}
                  id="confirmpassword"
                  props={{
                    background: "white",
                    color: colors.secondary,
                    full: true,
                    square: true,
                    border: "grey",
                  }}
                  name="confirmpassword"
                  required={stage === 2}
                />
              </SwiperSlide>
            </Swiper>
            <FormResponse
              message={formResponse.message}
              visible={formResponse.visible}
              status={formResponse.status}
            />
            <GlobalButton
              props={{
                background: colors.secondary,
                color: "white",
                full: true,
                hover: colors.red_dark,
                hoverColor: "white",
                hoverBorder: colors.red_dark,
                square: true,
              }}
              style={{ marginTop: 17 }}
              title="Sign up"
            >
              {stage === 0 ? "Continue" : "Sign up"}
            </GlobalButton>
          </form>
          {!token ? (
            <GlobalButton
              props={{
                background: "white",
                color: colors.secondary,
                full: true,
                border: "rgba(0,0,0,0.2)",
                hover: "rgba(0,0,0,0.2)",
                hoverColor: colors.secondary,
                hoverBorder: "rgba(0,0,0,0.2)",
                square: true,
              }}
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              title="Sign up"
              onClick={() => googleSignup()}
            >
              <FcGoogle size={22} style={{ marginRight: 5 }} />
              Sign up with Google
            </GlobalButton>
          ) : null}
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginTop: 15,
              fontSize: 12,
              marginBottom: 15,
              textAlign: "center"
            }}
          >
            Have an account?
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <FormLabel
                style={{
                  cursor: "pointer",
                  margin: 0,
                  marginLeft: 5,
                  textAlign: "center"
                }}
              >
                Sign in
              </FormLabel>
            </Link>
          </span>
        </AccessDiv>
        <AccessDiv props={{ hide: true }} style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/access2.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
          <Reviews
            message={`"We've been using CMS to kick start every sales tracking and can't
        imagine working without it."`}
            name="Enoch Twum"
            role="Founder, Bravetek"
            stars={5}
            institution="Tech Supplies"
          />
        </AccessDiv>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default Signup;
