import styled from "styled-components";

interface IIndexHero {
  props?: {
    direction: string;
    background: string;
  };
}

export const IndexHeroContainer = styled.div<IIndexHero>`
  display: flex;
  flex-wrap: wrap;
  padding: 3%;
  justify-content: ${({ props }) =>
    props?.direction === "right" ? "flex-start" : "flex-end"};
  background-color: ${({ props }) => props?.background};
`;

export const IndexHeroDiv = styled.h1`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    font-size: 25px;
    flex: 100%;
  }
`;
