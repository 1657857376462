import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ModalContainer, ModalWrapper } from "../../styles/Modal.style";
import { IdentificationImage } from "../../styles/WorkSpace/EditProfile.style";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { colors } from "../../utils/colors";
import { HideButton } from "../WorkSpace/SideProfile/style";
import useImagePreview from "../../hooks/useImagePreview";
import { gray } from "../../styles";

const ImagePreviewModal = () => {
  const { images, visible, active } = useSelector(
    (state: RootState) => state.imagePreview
  );
  const [wrapper, setWrapper] = useState(false);
  const [container, setContainer] = useState(false);
  const [selected, setSelected] = useState<number>(active);
  const { hide } = useImagePreview();

  useEffect(() => {
    if (!visible) {
      setWrapper(visible);
      setTimeout(() => {
        setWrapper(visible);
        setContainer(visible);
      }, 100);
    } else {
      setContainer(visible);
      setTimeout(() => {
        setContainer(visible);
        setWrapper(visible);
      }, 100);
    }
  }, [visible]);

  useEffect(() => {
    setSelected(active);
  }, [active]);

  return (
    <ModalContainer props={{ visible: container }}>
      <HideButton
        onClick={() => hide()}
        style={{
          position: "fixed",
          top: 15,
          right: 15,
          gap: 10,
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        Close <AiFillCloseCircle color={colors.red_dark} size={20} />
      </HideButton>
      <ModalWrapper
        props={{ visible: container, wrapper }}
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          background: "rgba(0,0,0,0)",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            flex: 1,
            // overflow: "hidden",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
          }}
        >
          {images.length > 1 && (
            <FaChevronLeft
              color="white"
              size={44}
              style={{
                cursor: "pointer" /*opacity: selected - 1 > -1 ? 1 : 0*/,
              }}
              title="back"
              onClick={() =>
                setSelected((e) => (e - 1 < 0 ? images.length - 1 : e - 1))
              }
            />
          )}
          <span
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {images.length > 0 && (
              <img
                src={images[selected]}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </span>

          {images.length > 1 && (
            <FaChevronRight
              color="white"
              size={44}
              style={{
                cursor: "pointer",
                /*opacity: selected + 1 < images.length ? 1 : 0,*/
              }}
              title="next"
              onClick={() =>
                setSelected((e) => (e + 1 === images.length ? 0 : e + 1))
              }
            />
          )}
        </div>
      </ModalWrapper>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: 0,
          padding: "5px 0",
          gap: 10,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: gray(0.3),
        }}
      >
        {images.length > 1 &&
          images.map((data, index) => (
            <IdentificationImage
              src={data}
              key={index}
              style={{
                height: 40,
                border: `2px solid ${
                  index === selected ? "white" : "transparent"
                }`,
              }}
              onClick={() => setSelected(index)}
            />
          ))}
      </div>
    </ModalContainer>
  );
};

export default ImagePreviewModal;
