import React, { useEffect, useRef, useState } from "react";
import { FaAddressBook, FaUserTie } from "react-icons/fa";
import { TbEdit } from "react-icons/tb";
import { useAuth } from "../../../context/AuthProvider";
import useExternalAPI from "../../../hooks/useExternalAPI";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { HomeCardWrap } from "../../../pages/WorkSpace/styles";
import { formHandler } from "../../../services/formHandler.service";
import { CardTitle, FormDesc } from "../../../styles/Global/text";
import { EditSectionWrap } from "../../../styles/WorkSpace/Profile/Profile.style";
import { colors } from "../../../utils/colors";
import Loader from "../../Global/Loader";
import { FcCancel } from "react-icons/fc";
import { GlobalInputSelectBottom, GlobalInputTextBottom } from "../../../styles/Global/input";
import { GlobalButton } from "../../../styles/Global/button";
import { gray } from "../../../styles";

const EditAddress = () => {
  const baseUrl = "http://localhost:5050/setup";
  const { postData } = useExternalAPI();
  const { addNotification } = useMessagePreview();
  const { setUser, user } = useAuth();
  const { address } = user.user;
  const [countries, setCountries] = useState<Array<any>>([]);
  const [states, setStates] = useState<Array<any>>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const countryRef = useRef<HTMLSelectElement | null>(null);
  const stateRef = useRef<HTMLSelectElement | null>(null);
  const streetRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (countries.length < 1) getCountries();
  }, [countries]);

  const getCountries = async () => {
    const response: any = await postData({
      baseUrl,
      url: "form/countries",
      get: true,
    });

    if (response.status) {
      setCountries(response?.data);
      if (states.length === 0)
        getState({ name: address?.country, countryList: response?.data });
    } else {
    }
  };

  const getState = async ({
    name,
    countryList = countries,
  }: {
    name: string;
    countryList?: Array<any>;
  }) => {
    console.log(name);
    const data = countryList.find((e) => e.name === name);
    if (data) {
      const response: any = await postData({
        baseUrl,
        url: "form/countries/?id=" + data.id,
        get: true,
      });

      if (response.status) setStates(response.data);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = formHandler(e);

    const response: any = await postData({
      url: "user/updateDetails",
      body: { ...formData, category: "address" },
    });

    if (response.status) {
      setEdit(false);
      addNotification({ message: "Address changes successful", type: 1 });
      setUser({
        ...user,
        user: { ...user.user, address: { ...formData } },
      });
    } else
      addNotification({ message: "Address changes unsuccessful", type: 0 });
    setLoading(false);
  };

  const resetForm = async () => {
    try {
      if (countryRef.current) countryRef.current!.value = address?.country;
      if (stateRef.current) {
        await getState({ name: address?.country }).then(
          (e) => (stateRef.current!.value = address?.state || "")
        );
      }
      if (streetRef.current) streetRef.current!.value = address?.street || "";
      setEdit(false);
    } catch (e) {}
  };

  return (
    <HomeCardWrap
      props={{ width: 49, widthMobile: 100 }}
      style={{ alignItems: "flex-start" }}
    >
      {/* <FormDesc>
            Please edit the fields below to implement changes.
          </FormDesc> */}
      <EditSectionWrap>
        <CardTitle className="profile_section_left">
          <span className="profile_title">
            <FaAddressBook
              size={25}
              color={"#d90368"}
              style={{ marginRight: 5 }}
            />
            Address
          </span>{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() => (edit ? resetForm() : setEdit(true))}
          >
            {loading ? (
              <Loader />
            ) : !edit ? (
              <TbEdit
                color={"#d90368"}
                style={{ cursor: "pointer" }}
                size={18}
                title="Edit"
              />
            ) : (
              <FcCancel size={18} />
            )}
          </span>
        </CardTitle>
        <form
          onSubmit={(e) => !loading && onSubmit(e)}
          style={{
            height: "min-content",
            display: "flex",
            flexDirection: "column",
            // width: "70%",
            flex: 1,
            justifyContent: "flex-start",
          }}
        >
          <FormDesc style={{ margin: "10px 0 5px 0" }}>Country</FormDesc>
          <GlobalInputSelectBottom
            placeholder="Select country"
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            // defaultValue={address?.country}
            onChange={(e) => getState({ name: e.target.value || "" })}
            disabled={!edit}
            ref={countryRef}
            name="country"
          >
            <option value={""} selected disabled>
              select country
            </option>
            {countries.map((data, index) => (
              <option
                key={index}
                value={data?.name}
                selected={address?.country === data?.name}
              >{`${data?.name} ${data?.emoji}`}</option>
            ))}
          </GlobalInputSelectBottom>
          <FormDesc style={{ margin: "10px 0 5px 0" }}>State</FormDesc>
          <GlobalInputSelectBottom
            placeholder="Select country"
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            // defaultValue={address?.country}
            disabled={!edit}
            ref={stateRef}
            name="state"
          >
            <option value={""} selected disabled>
              select state
            </option>
            {states.map((data, index) => (
              <option
                key={index}
                value={data?.name}
                selected={address?.state === data?.name}
              >
                {data?.name}
              </option>
            ))}
          </GlobalInputSelectBottom>
          <FormDesc style={{ margin: "0px 0 5px 0" }}>Street name</FormDesc>
          <GlobalInputTextBottom
            placeholder="Enter your street name"
            type={"text"}
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            defaultValue={address?.street}
            readOnly={!edit}
            ref={streetRef}
            name="street"
          />
          {edit && (
            <GlobalButton
              props={{
                background: colors.accent_light,
                color: colors.secondary,
                full: false,
                hover: "#138B32",
                hoverColor: "white",
                hoverBorder: "#138B32",
                square: true,
              }}
              style={{ marginTop: 10 }}
              title="Sign up"
            >
              Save changes
            </GlobalButton>
          )}
        </form>
      </EditSectionWrap>
    </HomeCardWrap>
  );
};

export default EditAddress;
