import React, { useEffect, useRef, useState } from "react";
import { FaUserTie } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { TbEdit } from "react-icons/tb";
import { useAuth } from "../../../context/AuthProvider";
import useExternalAPI from "../../../hooks/useExternalAPI";
import useMessagePreview from "../../../hooks/useMessagePreview";
import { HomeCardWrap } from "../../../pages/WorkSpace/styles";
import { formHandler } from "../../../services/formHandler.service";
import { CardTitle, FormDesc } from "../../../styles/Global/text";
import { EditSectionWrap } from "../../../styles/WorkSpace/Profile/Profile.style";
import { colors } from "../../../utils/colors";
import Loader from "../../Global/Loader";
import { GlobalInputTextBottom } from "../../../styles/Global/input";
import { GlobalButton } from "../../../styles/Global/button";
import { gray } from "../../../styles";

const EditAbout = () => {
  const { postData } = useExternalAPI();
  const { setUser, user } = useAuth();
  const { addNotification } = useMessagePreview();
  const { firstname, othernames, lastname, dob } = user.user;
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const firstnameRef = useRef<HTMLInputElement | null>(null);
  const lastnameRef = useRef<HTMLInputElement | null>(null);
  const othernamesRef = useRef<HTMLInputElement | null>(null);
  const dobRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = formHandler(e);

    const response: any = await postData({
      url: "user/updateDetails",
      body: { ...formData, category: "about" },
    });

    if (response.status) {
      setEdit(false);
      addNotification({ message: "About changes successful", type: 1 });
      setUser({
        ...user,
        user: { ...user.user, ...response.data },
      });
      // resetForm()
    } else addNotification({ message: "About changes unsuccessful", type: 0 });
    setLoading(false);
  };

  const resetForm = () => {
    try {
      if (firstnameRef.current) firstnameRef.current!.value = firstname || "";
      if (othernamesRef.current)
        othernamesRef.current!.value = othernames || "";
      if (lastnameRef.current) lastnameRef.current!.value = lastname || "";
      if (dobRef.current) dobRef.current!.value = dob || "";
      setEdit(false);
    } catch (e) {}
  };

  return (
    <HomeCardWrap
      props={{ width: 49, widthMobile: 100 }}
      style={{ alignItems: "flex-start" }}
    >
      <EditSectionWrap>
        <CardTitle className="profile_section_left">
          <span className="profile_title">
            <FaUserTie size={18} color={"#003566"} style={{ marginRight: 5 }} />
            About
          </span>{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() => (edit ? resetForm() : setEdit(true))}
          >
            {loading ? (
              <Loader />
            ) : !edit ? (
              <TbEdit color={"#003566"} size={18} />
            ) : (
              <FcCancel size={18} />
            )}
          </span>
        </CardTitle>
        <form
          onSubmit={(e) => !loading && onSubmit(e)}
          style={{
            height: "min-content",
            display: "flex",
            flexDirection: "column",
            // width: "70%",
            flex: 1,
            justifyContent: "flex-start",
          }}
        >
          <FormDesc style={{ margin: "0px 0 5px 0" }}>First name</FormDesc>
          <GlobalInputTextBottom
            placeholder="Enter your first name"
            type={"text"}
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            defaultValue={firstname}
            name="firstname"
            readOnly={!edit}
            ref={firstnameRef}
          />
          <FormDesc style={{ margin: "10px 0 5px 0" }}>Other names</FormDesc>
          <GlobalInputTextBottom
            placeholder="Enter other names"
            type={"text"}
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            defaultValue={othernames}
            name="othernames"
            readOnly={!edit}
            ref={othernamesRef}
          />
          <FormDesc style={{ margin: "10px 0 5px 0" }}>Last name</FormDesc>
          <GlobalInputTextBottom
            placeholder="Enter last name"
            type={"text"}
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            defaultValue={lastname}
            name="lastname"
            readOnly={!edit}
            ref={lastnameRef}
          />
          <FormDesc style={{ margin: "10px 0 5px 0" }}>Date of Birth</FormDesc>
          <GlobalInputTextBottom
            placeholder="Enter last name"
            type={"date"}
            props={{
              background: gray(0.05),
                      color: colors.secondary,
                      full: true,
                      square: true,
                      border: "transparent",
            }}
            defaultValue={dob}
            name="dob"
            readOnly={!edit}
            ref={dobRef}
          />
          {edit && (
            <GlobalButton
              props={{
                background: colors.accent_light,
                color: colors.secondary,
                full: false,
                hover: "#138B32",
                hoverColor: "white",
                hoverBorder: "#138B32",
                square: true,
              }}
              style={{ marginTop: 10 }}
              title="Sign up"
            >
              Save changes
            </GlobalButton>
          )}
        </form>
      </EditSectionWrap>
    </HomeCardWrap>
  );
};

export default EditAbout;
