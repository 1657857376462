import React, { useEffect, useState } from "react";
import { HomeCardWrap } from "../../../../../pages/WorkSpace/styles";
import {
  BusinessAddFormDiv,
  BusinessAddFormWrapper,
} from "../../../../../styles/WorkSpace/Business.style";
import { FormDesc, Header3, Header4 } from "../../../../../styles/Global/text";
import { colors } from "../../../../../utils/colors";
import { gray } from "../../../../../styles";
import { IDeliveryFormData } from "../AddDelivery.component";
import { BsArrowRightShort } from "react-icons/bs";
import DisplayMap from "../../../../Map/DisplayMap.component";
import MapContainer from "../../../../Map/MapContainer.component";
import useExternalAPI from "../../../../../hooks/useExternalAPI";
import styled from "styled-components";

const Step4 = ({
  stage,
  formData,
  setFormData,
}: {
  stage: number;
  formData: IDeliveryFormData;
  setFormData: React.Dispatch<React.SetStateAction<IDeliveryFormData>>;
}) => {
  const { postData } = useExternalAPI();
  const [routeMetrics, setRouteMetrics] = useState<{
    distance: number;
    duration: number;
  }>({ distance: 0, duration: 0 });
  const [distances, setDistances] = useState<number[]>([]);

  useEffect(() => {
    console.log({ formData });
    if (stage === 3) reRoute();
  }, [stage]);

  useEffect(() => {
    if (distances.length > 0) formatForm(formData);
  }, [distances]);

  const reRoute = async () => {
    const routes: any = [];
    if (formData.route?.to)
      formData.route.to.map((data, index) =>
        routes.push({ lat: data.latitude, lng: data.longitude })
      );

    const response: any = await postData({
      url: "delivery/optimiseroutes",
      body: {
        origin: {
          lat: formData.route?.from?.latitude,
          lng: formData.route?.from?.longitude,
        },
        routes,
      },
    });

    console.log({ response });
    if (response.status) {
      if (
        response.status &&
        Array.isArray(response.data["optimizedWaypoints"])
      ) {
        const newRoutes: any = Array.from(new Set());
        const uniqueWaypoints = Array.from(
          new Set(response.data["optimizedWaypoints"])
        );
        uniqueWaypoints.forEach((waypoint) => {
          if (formData.route?.to) {
            const matchedRoute = formData.route.to.find(
              (data) =>
                data.latitude === waypoint.lat &&
                data.longitude === waypoint.lng
            );
            if (matchedRoute) {
              newRoutes.push(matchedRoute);
            }
          }
        });
        // console.log({newRoutes})
        if (newRoutes.length > 0) {
          setFormData((e) => ({ ...e, route: { ...e.route, to: newRoutes } }));
        }
      }
      setDistances(response.data["distances"]);
      setRouteMetrics({
        distance: response.data["distance"] || "N/A",
        duration: response.data["duration"] || "N/A",
      });
    }
  };

  function calculatePrice(
    distanceInKm: number,
    averageRate: number = 2.5,
    discount: number = 0
  ): number {
    const lowestRate = 15; // Minimum rate

    // Calculate the price
    let price = distanceInKm * averageRate;

    // Ensure the price is not lower than the lowest rate
    if (price < lowestRate) {
      price = lowestRate;
    }

    return Math.round(price - price * (discount / 100));
  }

  function calculatePercentage(number: number, numbers: number[]): number {
    const sum = numbers.reduce((acc, curr) => acc + curr, 0);
    const percentage = (number / sum) * 100;
    console.log({ percentage });
    return percentage;
  }

  function convertMinutesToHoursAndMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }

  function formatForm(formData: IDeliveryFormData) {
    const formatted: any = {
      pcikup: {
        location: formData.route?.from,
        ...formData.pickup_route,
      },
      destinations: [],
    };

    formData.route?.to?.map((data, index) => {
      if (formData.delivery_routes && formData.delivery_routes[index])
        formatted.destinations.push({
          location: data,
          ...formData.delivery_routes[index],
          estimated_price: Math.round(
            (calculatePercentage(distances[index], distances) / 100) *
              calculatePrice(routeMetrics.distance || 0)
          ),
        });
    });

    console.log({ formatted });
  }

  return (
    <div>
      <Header3
        style={{
          marginBottom: 20,
          fontSize: 18,
          color: colors.secondary,
        }}
      >
        Routes
      </Header3>
      <div
        style={{
          borderTop: `1px solid ${gray(0.1)}`,
          padding: "20px 1.5% 20px 0",
        }}
      >
        <Header4 style={{ fontSize: 16, marginBottom: 10 }}>
          ID #2345454 - {formData.route?.to?.length} packages
        </Header4>

        <FormDesc>
          {formData.route?.from?.label}{" "}
          <BsArrowRightShort size={15} color={colors.red_dark} />{" "}
          {formData.route?.to &&
            formData.route?.to[formData.route.to?.length - 1].label}{" "}
        </FormDesc>
        <BusinessAddFormWrapper style={{ marginTop: 30 }}>
          <BusinessAddFormDiv
            style={{
              width: "80%",
              height: 250,
              borderRadius: 5,
            }}
          >
            <MapContainer
              mapType={"display"}
              displayProps={{
                from: formData.route?.from,
                to: formData.route?.to,
              }}
            />
          </BusinessAddFormDiv>
          <MetricsContainer>
            <Metrics>
              {convertMinutesToHoursAndMinutes(routeMetrics.duration)}
              <h6
                style={{
                  margin: "10px 0",
                  fontWeight: 600,
                  fontSize: 10,
                  color: colors.red_light,
                }}
              >
                Estimated duration
              </h6>
            </Metrics>
            <Metrics style={{ border: "none" }}>
              {`${routeMetrics.distance.toFixed(2)} Km`}
              <h6
                style={{
                  margin: "10px 0",
                  fontWeight: 600,
                  fontSize: 10,
                  color: colors.blue,
                }}
              >
                Distance
              </h6>
            </Metrics>
            <Metrics style={{ border: "none" }}>
              {`GH₵ ${calculatePrice(routeMetrics.distance || 0)}.00`}
              <h6
                style={{
                  margin: "10px 0",
                  fontWeight: 600,
                  fontSize: 10,
                  color: colors.green,
                }}
              >
                Total Estimated Pricing
              </h6>
            </Metrics>
          </MetricsContainer>
        </BusinessAddFormWrapper>
      </div>
      <Header3
        style={{
          marginBottom: 20,
          fontSize: 18,
          color: colors.secondary,
        }}
      >
        Cost Breakdown
      </Header3>
      <div
        style={{
          borderTop: `1px solid ${gray(0.1)}`,
          padding: "20px 1.5% 20px 0",
        }}
      >
        {formData.route?.to &&
          formData.route.to?.map((data, index) => (
            <div style={{ fontSize: 16, marginBottom: 10 }}>
              📍 Delivery point {index + 1} {" " + data.label + " =>"}
              <span style={{ fontWeight: 600 }}>
                {" GH₵ " +
                  Math.round(
                    (calculatePercentage(distances[index], distances) / 100) *
                      calculatePrice(routeMetrics.distance || 0)
                  ) +
                  ".00"}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

const MetricsContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100% !important;
  }
`;

const Metrics = styled.span`
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export default Step4;
