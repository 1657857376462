import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { Link } from "../styles";
import { colors } from "../utils/colors";
import { AiOutlineReconciliation } from "react-icons/ai";
import {FiEdit3} from "react-icons/fi"
import useImagePreview from "../hooks/useImagePreview";
import Summary from "../pages/WorkSpace/Profile/Summary";
import EditProfile from "../pages/WorkSpace/Profile/EditProfile";
import { GlobalButtonHighlight } from "../styles/Global/button";
import { Cover, ProfileBasicDetails, ProfileEmail, ProfileImg, ProfileImgIcon, ProfileJoined, ProfileName } from "../styles/WorkSpace/Profile/Profile.style";

const ProfileRoutes = () => {
  const { user } = useAuth();
  const { firstname, othernames, lastname, avatar, email, createdAt } =
    user.user;
  const { show } = useImagePreview();
  const [profile, setProfile] = useState(avatar || null);
  const { pathname } = useLocation();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    try {
      const edit = pathname.split("/")[3];
      setEdit(edit ? true : false);
    } catch (e) {
      setEdit(false);
    }
  }, [pathname]);

  return (
    <>
      <Cover src={require("../assets/images/cover.jpeg")} />
      <div style={{ display: "flex", marginBottom: 60 }}>
        {profile ? (
          <ProfileImg
            src={avatar}
            onError={() => setProfile(null)}
            onClick={() => show([profile])}
          />
        ) : (
          <ProfileImgIcon />
        )}
        <ProfileBasicDetails>
          <span>
            <ProfileName>
              {`${lastname} ${othernames || ""} ${firstname}`}{" "}
            </ProfileName>
            <ProfileEmail>{email}</ProfileEmail>
            <ProfileJoined>
              Joined{" "}
              <span style={{ fontWeight: "bold", color: colors.primary }}>
                {" " + new Date(createdAt).toDateString()}
              </span>
            </ProfileJoined>
          </span>
          <Link to={`/workspace/profile/${!edit ? "edit" : ""}`} style={{marginTop: 10}}>
            <GlobalButtonHighlight
              props={{
                background: "white",
                hover: !edit ? colors.red_dark : colors.blue,
                hoverColor: "white",
              }}
            >
              {!edit ? (
                <>
                  <FiEdit3
                    // color={colors.red_dark}
                    style={{ cursor: "pointer" }}
                    size={18}
                    title="Edit"
                  />
                  Edit Profile
                </>
              ) : (
                <>
                  <AiOutlineReconciliation
                    // color={colors.blue}
                    style={{ cursor: "pointer" }}
                    size={18}
                    title="Edit"
                  />
                  ProfileAnalytics
                </>
              )}
            </GlobalButtonHighlight>
          </Link>
        </ProfileBasicDetails>
      </div>
      <Routes>
        <Route path="/" element={<Summary />} />
        <Route path="/edit" element={<EditProfile />} />
        {/* <Route path="*" element={<Navigate to={"workspace/profile/"}/>} /> */}
      </Routes>
    </>
  );
};

export default ProfileRoutes;
