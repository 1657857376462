import React from "react";
import { Chart } from "react-google-charts";
import { colors } from "../../../utils/colors";



const SliceGraph = () => {
  const data = [
    ["Language", "Speakers (in millions)"],
    ["BraveTek", 1345.50],
    ["Jul's Cleaning Services", 145.50],
    ["Lia Styles", 56.00],
  ];
  
 const options = {
    title: "Performace Index",
    pieSliceText: "label",
    slices: {
      1: { offset: 0.1 },
      2: { offset: 0.2 },
      3: { offset: 0.4 },
    },
    colors: ["#012350", "#FCA311" ,"purple"],
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="200px"
      data={data}
      options={options}
    />
  );
};

export default SliceGraph;
