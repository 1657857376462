/* eslint-disable jsx-a11y/alt-text */
import { HomeCardWrap } from "../../../../pages/WorkSpace/styles";
import { BusinessTitleWrapper } from "../../../../styles/WorkSpace/Business.style";
import { FormDesc, Header2, Header3 } from "../../../../styles/Global/text";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { gray, transition } from "../../../../styles";
import { GlobalButton } from "../../../../styles/Global/button";
import { colors } from "../../../../utils/colors";
import "./styles.css";
import ProgressBar from "../../../Global/ProgressBar.component";
import { BsSave } from "react-icons/bs";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../context/AuthProvider";
import useMessagePreview from "../../../../hooks/useMessagePreview";
import Step1 from "./Steps/Step1.component";
import Step2 from "./Steps/Step2.component";
import {
  formatDate,
  isDateValidSchedule,
} from "../../../../services/date.service";
import Step3 from "./Steps/Step3.component";
import Step4 from "./Steps/Step4.component";

export interface ILocationData {
  label: string;
  latitude: number;
  longitude: number;
}

export interface IItemData {
  item: {
    name: string;
    description?: string;
  };
  contact: {
    name?: string;
    code?: number;
    number?: number;
  };
}
export interface IDeliveryFormData {
  type?: "instant" | "scheduled";
  schedule?: string | null;
  route?: {
    from?: ILocationData;
    to?: ILocationData[];
  };
  pickup_route?: {
    description?: string;
    contact: {
      name?: string;
      code?: number;
      number?: number;
    };
  };
  delivery_routes?: IItemData[];
}

const AddDelivery = () => {
  const { navigate } = useAuth();
  const { addNotification } = useMessagePreview();
  const swiperRef = useRef<any>();
  const [stage, setStage] = useState(0);
  const [formData, setFormData] = useState<IDeliveryFormData>({});
  const [submitLabel, setSubmitLabel] = useState("Route");

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  useEffect(() => {
    const label =
      stage === 0
        ? "Route"
        : stage === 1
        ? "Details"
        : stage === 2
        ? "Shipping"
        : "Submit";
    setSubmitLabel(label);
  }, [stage]);

  const swiper = (next: boolean) => {
    if (next) {
      if (stage === 0) {
        if (!formData.type) {
          addNotification({
            type: 0,
            message: "Please select request type to proceed",
          });
          return;
        }
        if (!formData.schedule) {
          addNotification({
            type: 0,
            message: "Please select schedule to proceed",
          });
          return;
        }
        const valid = isDateValidSchedule(formData.schedule);
        if (!valid.status) {
          addNotification({
            message: `Wrong date selected, ${
              valid?.message
            } for date: ${formatDate(new Date(formData.schedule))}`,
            timer: 10,
            type: 0,
          });
          return;
        }
      } else if (stage === 1) {
        if (!formData.route?.from || !formData.route.to) {
          addNotification({
            type: 0,
            message: "Please choose both pickup and delivery location.",
          });
          return;
        }
      }
    }
    const toStage = next
      ? stage + 1 > 3
        ? stage
        : stage + 1
      : stage - 1 < 1
      ? 0
      : stage - 1;
    setStage(toStage);
    swiperRef.current.slideTo(toStage);
  };

  return (
    <>
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 20 }}
      >
        <BusinessTitleWrapper>
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <img
              src={require("../../../../assets/images/cmDelivery.png")}
              style={{ height: 50, width: 50, borderRadius: 10 }}
            />
            <Header2 style={{ fontSize: 23, margin: 0 }}>
              <p
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.5",
                  margin: "0 0 5px 0",
                }}
              >
                Shipment #2345454
              </p>
              Delivery Request{" "}
              <AiOutlineInfoCircle
                size={17}
                color={gray(0.5)}
                style={{ marginLeft: 10 }}
              />
            </Header2>
          </div>
          {/* <GlobalButton
            props={{
              background: "#FED196",
              full: false,
              color: colors.secondary,
              square: true,
              border: "#FED196",
              hover: colors.yellow,
              hoverColor: colors.secondary,
              hoverBorder: colors.yellow,
            }}
            style={{ fontSize: 13, fontWeight: 600 }}
          >
            <BsSave />
            Save for later
          </GlobalButton> */}
        </BusinessTitleWrapper>
        <ProgressBar
          steps={["Type", "Route", "Details", "Shipment"]}
          active={stage}
        />
      </HomeCardWrap>
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 20 }}
      >
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          allowTouchMove={false}
          noSwiping={true}
          autoHeight={true}
          className="scrollY"
          style={{ width: "100%", paddingBottom: 20 }}
        >
          <SwiperSlide>
            <Step1 formData={formData} setFormData={setFormData} />
          </SwiperSlide>
          <SwiperSlide>
            <Step2 formData={formData} setFormData={setFormData} />
          </SwiperSlide>
          <SwiperSlide>
            <Step3 formData={formData} setFormData={setFormData} swiper={swiper}/>
          </SwiperSlide>
          <SwiperSlide>
            <Step4 formData={formData} setFormData={setFormData} stage={stage}/>
          </SwiperSlide>
        </Swiper>
      </HomeCardWrap>
      <HomeCardWrap
        props={{ width: 100, widthMobile: 100 }}
        style={{ marginBottom: 20 }}
      >
        <BusinessTitleWrapper>
          <GlobalButton
            props={{
              background: "transparent",
              full: false,
              color: colors.secondary,
              square: true,
              border: "transparent",
              hover: "#F1F3F4",
              hoverColor: colors.secondary,
              hoverBorder: "#F1F3F4",
            }}
            style={{ fontSize: 13, fontWeight: 600 }}
            onClick={() => (stage > 0 ? swiper(false) : navigate(-1))}
          >
            <MdNavigateBefore />
            {stage > 0 ? "Back to previous step" : "Previous page"}
          </GlobalButton>
          {stage !== 2 && (
            <GlobalButton
              props={{
                background: colors.secondary,
                full: false,
                color: "white",
                square: true,
                border: colors.secondary,
                hover: colors.yellow,
                hoverColor: colors.secondary,
                hoverBorder: colors.yellow,
              }}
              style={{ fontSize: 13, fontWeight: 600, marginLeft: "auto" }}
              onClick={() => swiper(true)}
            >
              Continue {submitLabel}
              <MdNavigateNext />
            </GlobalButton>
          )}
        </BusinessTitleWrapper>
      </HomeCardWrap>
    </>
  );
};

export default AddDelivery;
