function getBase64FileSize(base64String: string) {
  try {
    const decodedData = atob(base64String.replace(/\s/g, ""));
    const byteLength = decodedData.length;
    const result = (byteLength / 1024) * 1000; // Convert to KB
    return result.toFixed(2);
  } catch (e) {
    throw e;
  }
}

const getbase64 = (file: any): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64String = reader.result;
        if (typeof base64String === "string") resolve(base64String);
        else resolve(null);
      };

      reader.onerror = () => {
        reject(reader.error);
      };
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export { getBase64FileSize, getbase64 };
