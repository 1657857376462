import { useState } from "react";
import useImagePreview from "../../../hooks/useImagePreview";
import { encrypt } from "../../../services/encryption.service";
import { Link } from "../../../styles";

const BusinessNameLogoWrapper = ({
  data,
  open,
}: {
  data: {
    [key: string]: any;
  };
  open: boolean;
}) => {
  const { name, avatar } = data;
  const [profile, setProfile] = useState(avatar);
  const { show } = useImagePreview();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        gap: 10,
      }}
    >
      <img
        src={profile}
        onError={() =>
          setProfile(require("../../../assets/images/business_default.png"))
        }
        onClick={() => show([profile])}
        style={{ height: 35, width: 35, borderRadius: 17.5, cursor: "pointer" }}
      />
      {open ? (
        <Link to={`page?id=${encrypt(data.id)}`} state={{ props: data }}>
          {" " + name}
        </Link>
      ) : (
        `${name}`
      )}
    </div>
  );
};

export default BusinessNameLogoWrapper;
