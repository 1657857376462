import styled from "styled-components";

export interface IAccessDiv {
    props?: {
      hide: Boolean;
    };
  }

export const AccessDiv = styled.div<IAccessDiv>`
  flex: 50%;
  transition: 0.5s all ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  justify-content: center;
  padding: ${({ props }) => (!props?.hide ? "0 12%" : 0)};

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: ${({ props }) => (!props?.hide ? "10%" : 0)};
    flex: ${({ props }) => (props?.hide ? 0 : "100%")};
    display: ${({ props }) => (props?.hide ? "none" : "flex")};
  }
`;

export const ResetAccessDiv = styled.div<IAccessDiv>`
  flex: 50%;
  transition: 0.5s all ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @media (max-width: 768px) {
    flex: ${({ props }) => (props?.hide ? 0 : "100%")};
    display: ${({ props }) => (props?.hide ? "none" : "flex")};
  }
`;

export const AccessDesc = styled.p`
  color: grey;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AccessLabel = styled.p`
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;