import { HomeCardContainerWrapper } from "../styles";

import "../../../styles/WorkSpace/Profile/Profile.style.css";
import EditAbout from "../../../components/WorkSpace/EditProfile/EditAbout";
import EditAddress from "../../../components/WorkSpace/EditProfile/EditAddress";
import EditContacts from "../../../components/WorkSpace/EditProfile/EditContacts";
import EditIdentification from "../../../components/WorkSpace/EditProfile/EditIdentification";

const EditProfile = () => {
  return (
    <>
      <HomeCardContainerWrapper>
        <EditAbout />
        <EditAddress />
        <EditIdentification />
        <EditContacts />
      </HomeCardContainerWrapper>
    </>
  );
};

export default EditProfile;
