import { createContext, ReactNode, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import AuthContext from "./AuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setUser, user, removeValue } = useLocalStorage("cms-session", {});
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: object) => {
    const set = setUser(data)
    navigate("/workspace");
  };

  // call this function to sign out logged in user
  const logout = async() => {
    await removeValue()
    navigate("/signin", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      navigate,
      children,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
