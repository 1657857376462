const state = {
  business_categories: [
    "Agriculture",
    "Automotive",
    "Beauty and Personal Care",
    "Business Services",
    "Construction",
    "Consumer Goods",
    "Education and Training",
    "Energy and Utilities",
    "Environmental Services",
    "Finance",
    "Food and Beverage",
    "Healthcare",
    "Hospitality and Tourism",
    "Information Technology",
    "Legal Services",
    "Manufacturing",
    "Marketing and Advertising",
    "Media and Entertainment",
    "Nonprofit",
    "Real Estate",
    "Retail",
    "Sports and Recreation",
    "Telecommunications",
    "Transportation",
    "Software",
    "Other (please specify)",
  ],
  fileTypes : ["JPEG", "PNG", "JPG"]
};

export default state;
