import React from "react";
import { BusinessServiceRowContainer } from "../../../../styles/WorkSpace/Business.style";
import { BodyText, FormLabel, Header4 } from "../../../../styles/Global/text";
import { Link, gray } from "../../../../styles";
import useImagePreview from "../../../../hooks/useImagePreview";
import { colors } from "../../../../utils/colors";
import { GlobalButton } from "../../../../styles/Global/button";
import { FiChevronRight } from "react-icons/fi";

const Services = () => {
  const { show } = useImagePreview();
  const services_images: Array<any> = [
    require("../../../../assets/images/cmDelivery.png"),
    require("../../../../assets/images/cmAnalytics.png"),
    require("../../../../assets/images/cmPayment.png"),
  ];

  return (
    <div style={{ padding: 5 }}>
      <BusinessServiceRowContainer style={{ marginBottom: 20 }}>
        <img
          src={services_images[0]}
          style={{ height: 55, width: 55, borderRadius: 10 }}
          onClick={() => show(services_images, 0)}
        />
        <div>
          <FormLabel style={{ margin: "0 0 2px 0" }}>
            Courier Servicing
          </FormLabel>
          <BodyText style={{ color: gray(0.4), fontSize: 13, margin: 0 }}>
            Delivery Servicing, Book a sale delivery, Payment on delivery etc
          </BodyText>
          <Link
            to=""
            style={{
              fontSize: 12,
              color: colors.red_dark,
              margin: "2px 0 0 0",
            }}
          >
            Learn more
          </Link>
        </div>
        <GlobalButton
          props={{
            full: false,
            square: false,
            background: colors.secondary,
            color: "white",
            hover: colors.yellow,
            hoverBorder: colors.yellow,
          }}
          style={{
            marginLeft: "auto",
            fontWeight: "600",
            fontSize: 13,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
          className="request"
        >
          Request <FiChevronRight />
        </GlobalButton>
      </BusinessServiceRowContainer>
      <BusinessServiceRowContainer style={{ marginBottom: 20 }}>
        <img
          src={services_images[1]}
          style={{ height: 55, width: 55, borderRadius: 10 }}
          onClick={() => show(services_images, 1)}
        />
        <div>
          <FormLabel style={{ margin: "0 0 2px 0" }}>
            AI Analytics Servicing
          </FormLabel>
          <BodyText style={{ color: gray(0.4), fontSize: 13, margin: 0 }}>
            AI sales analytics: maximizing revenue through data-driven insights.
          </BodyText>
          <Link
            to=""
            style={{
              fontSize: 12,
              color: colors.red_dark,
              margin: "2px 0 0 0",
            }}
          >
            Learn more
          </Link>
        </div>
        <GlobalButton
          props={{
            full: false,
            square: false,
            background: colors.secondary,
            color: "white",
            hover: colors.red_dark,
            hoverBorder: colors.red_dark,
            hoverColor: "white",
          }}
          style={{
            marginLeft: "auto",
            fontWeight: "600",
            fontSize: 13,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
          className="request"
        >
          Request <FiChevronRight />
        </GlobalButton>
      </BusinessServiceRowContainer>
      <BusinessServiceRowContainer>
        <img
          src={services_images[2]}
          style={{ height: 55, width: 55, borderRadius: 10 }}
          onClick={() => show(services_images, 2)}
        />
        <div>
          <FormLabel style={{ margin: "0 0 2px 0" }}>
            E-Payment Servicing
          </FormLabel>
          <BodyText style={{ color: gray(0.4), fontSize: 13, margin: 0 }}>
            Efficient online payment solutions for seamless transactions.
          </BodyText>
          <Link
            to=""
            style={{
              fontSize: 12,
              color: colors.red_dark,
              margin: "2px 0 0 0",
            }}
          >
            Learn more
          </Link>
        </div>
        <GlobalButton
          props={{
            full: false,
            square: false,
            background: colors.secondary,
            color: "white",
            hover: colors.blue_dark,
            hoverBorder: colors.blue_dark,
            hoverColor: "white",
          }}
          style={{
            marginLeft: "auto",
            fontWeight: "600",
            fontSize: 13,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
          className="request"
        >
          Request <FiChevronRight />
        </GlobalButton>
      </BusinessServiceRowContainer>
    </div>
  );
};

export default Services;
