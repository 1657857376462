import { useState, useRef, useEffect, Dispatch, ReactNode } from "react";
import "./styles.css";
import { IconType } from "react-icons/lib";
import { PopupContainer } from "./styles";
import { options } from "../../WorkSpace/PieGraph";
import { transition } from "../../../styles";

const GlobalPopup = ({
  id,
  icon,
  options,
}: {
  id: number;
  icon: ReactNode;
  options: ReactNode[] | string[];
}) => {
  const [position, setPosition] = useState<{
    top: number;
    left?: number;
    right?: number;
  } | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Attach event listener to document
    document.addEventListener("click", handleClickOutside);

    // Detach event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (position) handleOverflowCheck();
  }, [position]);

  const handleClickOutside = (event: MouseEvent) => {
    // Check if clicked element is a descendant of the divRef element
    const target = event.target as HTMLButtonElement;
    const className = target.classList;
    removePopup();

    if (className.contains("btn_3")) return;

    if (
      popupContainerRef.current &&
      !popupContainerRef.current.contains(event.target as Node)
    ) {
      hideAllPopups();
    }
  };

  const removePopup = () => {
    setPosition(null);
  };

  const hideAllPopups = () => {
    try {
      const list = document.querySelectorAll(
        ".table_popup"
      ) as NodeListOf<HTMLDivElement>;

      list.forEach((element) => {
        element.style.display = "none";
      });
    } catch (e) {}
  };

  const handleMenuBtnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();

      hideAllPopups();

      // if (!isPopupVisible) {
      //   const buttonRect = buttonRef.current?.getBoundingClientRect();
      //   const top = buttonRect?.top! + buttonRect?.height!;
      //   const left = buttonRect?.left! + buttonRect?.width! / 2;

      //   setPosition({ top, left });
      // }

      const popup = document.getElementById(
        `table_popup-${id}`
      ) as HTMLElement | null;

      const buttonRect = buttonRef.current?.getBoundingClientRect();
      const top = buttonRect?.top! + buttonRect?.height!;
      const left = buttonRect?.left! + buttonRect?.width! / 2;

      setPosition({ top, left });
      // console.log({ left, top });

      if (popup) {
        popup.style.display = "block";
      }
      // setIsPopupVisible(true);
    } catch (e) {
      console.error({ buttonClickError: e });
    }
  };

  const handleOverflowCheck = () => {
    const content = popupContainerRef.current;
    if (!content) return;

    const rect = content.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    if (rect.left < 0) {
      console.log("Element is overflowing the left side");
      setPosition((e) => ({ top: e?.top || 0, left: 5 }));
    }
    if (rect.right > viewportWidth) {
      setPosition((e) => ({ top: e?.top || 0, right: 5 }));
      console.log("Element is overflowing the right side");
    }
  };

  // const handleOverflowCheck = () => {
  //   const content = popupContainerRef.current;
  //   if (!content) return;

  //   const rect = content.getBoundingClientRect();
  //   const viewportWidth = window.innerWidth;
  //   const viewportHeight = window.innerHeight;

  //   let currentPosition: any = { ...position };
  //   if (rect.left < 0) {
  //     currentPosition = { ...currentPosition, left: 5 };
  //   }
  //   if (rect.right > viewportWidth) {
  //     currentPosition = { ...currentPosition, right: 5 };
  //   }
  //   // if (rect.bottom > viewportHeight) {
  //   //   currentPosition = { ...currentPosition, bottom: 5 };
  //   // }

  //   setReposition(currentPosition);
  //   setPosition((e)=> ({...e, currentPosition}))
  // };

  return (
    <>
      <span ref={buttonRef}>
        <button
          style={{
            cursor: "pointer",
            margin: 0,
            padding: 0,
            background: "transparent",
            border: "none",
          }}
          onClick={(e) => handleMenuBtnClick(e)}
          className="btn_3"
        >
          {icon}
        </button>
      </span>
      <PopupContainer
        className="table_popup"
        left={position?.left || 0}
        top={position?.top || 0}
        ref={popupContainerRef}
        style={{ display: "none", ...position, maxWidth: 150 }}
        id={`table_popup-${id}`}
      >
        {options.map((data: ReactNode, index) => (
          <div
            className="popup-content"
            style={{ margin: "0 0 2px 0" }}
            key={index}
          >
            {data}
          </div>
        ))}
      </PopupContainer>
    </>
  );
};

export default GlobalPopup;
