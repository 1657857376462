export const colors: {
  primary: string;
  secondary: string;
  accent: string;
  accent_light: string;
  red_dark: string;
  red_light: string;
  secondary_light: string;
  green: string;
  yellow: string;
  blue: string;
  blue_dark: string;
  workspace_background: string;
} = {
  primary: "#787070",
  secondary: "#232020",
  accent: "#a99d9b",
  accent_light: "#e7e7e7",
  red_dark: "#780000",
  red_light: "#c1121f",
  secondary_light: "#669bbc",
  green: "#5D930F",
  yellow: "#FCA311",
  blue: "#012350",
  blue_dark: "#022350",
  workspace_background: "#f1f3f4",
};
