import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Autocomplete,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { GlobalInputText } from "../../styles/Global/input";
import mapStyles from "../../assets/json/customMapView.json";
import { colors } from "../../utils/colors";
import { GlobalButton } from "../../styles/Global/button";
import useExternalAPI from "../../hooks/useExternalAPI";
import { BiCurrentLocation } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ILocationData } from "../WorkSpace/Services/Delivery/AddDelivery.component";

const MapWithAutocomplete = ({
  isLoaded,
  loadError,
  edit,
  setEdit,
  from,
  to,
  current_location,
  submitLocation,
}: {
  isLoaded: any;
  loadError: any;
  edit: {
    type: 0 | 1;
    status: Boolean;
    label: string;
    index: number | null
  };
  setEdit: React.Dispatch<
    React.SetStateAction<{
      type: 0 | 1;
      status: Boolean;
      label: string;
      index: number | null
    }>
  >;
  current_location?: { lat: number; lng: number };
  to?: ILocationData[] | undefined;
  from?: ILocationData | undefined;
  submitLocation: (loc: {
    label: string;
    latitude: number;
    longitude: number;
  }) => void;
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const default_position = {
    lat: 5.6037,
    lng: -0.187,
  };
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const [placeValue, setPlaceValue] = useState("");
  const [zoom, setZoom] = useState(10);
  const mapRef = useRef<google.maps.Map>();
  const { postData } = useExternalAPI();
  const [direction, setDirection] =
    useState<google.maps.DirectionsResult | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (
        map &&
        place.geometry?.location?.lat() &&
        place.geometry?.location?.lng()
      ) {
        setPlaceValue((e) => place?.formatted_address || e);
        map.setZoom(10);
        const lat = place.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        setTimeout(() => {
          if (lat && lng) {
            setPosition({
              lat,
              lng,
            });
            map.setZoom(15);
          }
        }, 2000);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    if (edit.status) {
      if (edit.type === 0) {
        setPlaceValue(from?.label || "");
      } else {
        setPlaceValue(edit.label);
      }
    }
  }, [edit]);

  useEffect(() => {
    if (isLoaded) {
      // Check if Google Maps API is loaded
      console.log("Map loaded: ", isLoaded);
      if (from && to && to.length > 0) {
        console.log("Map values change: ", { to, from });
        const waypoints = to.map((stop) => ({
          location: { lat: stop.latitude, lng: stop.longitude },
          stopover: true,
        }));

        const directionsServiceOptions: google.maps.DirectionsRequest = {
          destination: {
            lat: to[to.length - 1].latitude,
            lng: to[to.length - 1].longitude,
          },
          origin: { lat: from.latitude, lng: from.longitude },
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: waypoints,
        };

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          directionsServiceOptions,
          (
            result: google.maps.DirectionsResult | null,
            status: google.maps.DirectionsStatus
          ) => {
            if (status === "OK" && result) {
              setDirection(result);
            }
          }
        );
      } else {
        console.error("Error: 'from' and 'to' values are missing", {
          from,
          to,
        });
      }
    } else if (loadError) {
      console.log("Error loading Google Maps:", loadError);
    }
  }, [isLoaded, loadError, from, to]);

  if (loadError) {
    return (
      <div
        style={{
          width: "100",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        Error loading Google Maps
      </div>
    );
  }

  const handleZoomChange = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom || 10);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    try {
      const clickedPosition = event.latLng;
      const lat = clickedPosition?.lat();
      const lng = clickedPosition?.lng();
      if (lat && lng) {
        if (lat && lng) {
          setPosition({
            lat,
            lng,
          });
          getPlaceName(lat, lng);
          setPlaceValue(`${clickedPosition?.lat()}, ${clickedPosition?.lng()}`);
        }
      }
    } catch (e) {
      console.error("Error handling click", e);
    }
  };

  const getPlaceName = async (lat: number, lng: number) => {
    try {
      const request = await postData({
        baseUrl: "http://localhost:5050/setup",
        url: `map/getPlaceName?lat=${lat}&lng=${lng}`,
        get: true,
      });

      console.log({ request });
      if (request?.status) {
        setPlaceValue(request.data);
      } else {
        // console.log("Error gettin.");
        // return null;
        setPlaceValue(`${lat}, ${lng}`);
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
      return null;
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      center={position || default_position}
      zoom={zoom}
      onLoad={(map) => {
        mapRef.current = map;
        setMap(map);
        handleZoomChange(); // Set initial zoom value
      }}
      onZoomChanged={handleZoomChange}
      options={{
        // disableDefaultUI: true,
        styles: mapStyles,
      }}
      onClick={edit.status && handleMapClick}
    >
      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#FFD897",
              strokeWeight: 2,
            },
          }}
        />
      )}
      {current_location && (
        <Marker
          position={current_location}
          icon={{
            url: require("../../assets/images/markers/current_pin.png"),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          title="Current location"
        />
      )}
      {from && (
        <Marker
          position={{ lat: from.latitude, lng: from.longitude }}
          icon={{
            url: require("../../assets/images/markers/start_pin.png"),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          title="Pickup location"
        />
      )}
      {to &&
        to.length > 1 &&
        to?.map((data, index) => (
          <Marker
            position={{ lat: data.latitude, lng: data.longitude }}
            icon={{
              url: index + 1 === to.length
              ? require("../../assets/images/markers/end_pin.png")
              : require("../../assets/images/markers/mid_pin.png"),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
            // label={`${index + 1}`}
          />
        ))}
      {position && (
        <Marker
          position={position}
          icon={{
            url: require("../../assets/images/markers/clicked_pin.png"),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          title="Pickup location"
        />
      )}
      {edit.status && (
        <>
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            options={{
              types: ["establishment", "geocode"],
              componentRestrictions: { country: "gh" }, // Restrict search to Ghana
            }}
          >
            <GlobalInputText
              type="text"
              placeholder="Enter a location"
              props={{
                square: false,
                background: "white",
                border: colors.secondary,
                color: colors.secondary,
                full: false,
              }}
              onChange={(e) => setPlaceValue(e.target.value)}
              value={placeValue}
              style={{
                position: "absolute",
                top: 3,
                left: 10,
                width: "70%",
                right: 10,
              }}
            />
          </Autocomplete>
          {position && (
            <GlobalButton
              props={{
                background: colors.yellow,
                color: colors.secondary,
                full: false,
                border: colors.yellow,
                hover: colors.red_dark,
                hoverColor: "white",
                hoverBorder: colors.red_dark,
                square: false,
              }}
              title="Okay"
              onClick={() => {
                submitLocation({
                  label: placeValue,
                  longitude: position.lng,
                  latitude: position.lat,
                });
              }}
              style={{
                position: "absolute",
                bottom: 20,
                right: 10,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              <BiCurrentLocation />
              Selelct
            </GlobalButton>
          )}
          <span
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 15,
              right: 15,
              fontSize: 26,
              borderRadius: "50%",
              backgroundColor: "white",
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            onClick={() => setEdit((e) => ({ ...e, status: false }))}
          >
            <AiOutlineCloseCircle color={colors.red_dark} />
          </span>
        </>
      )}
    </GoogleMap>
  ) : (
    <div
      style={{
        width: "100",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 600,
      }}
    >
      Loading Google Maps...
    </div>
  );
};

export default MapWithAutocomplete;
